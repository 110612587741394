import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import autoBind from "react-autobind";

import DropdownSearch from "../../common/DropdownSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import groupBy from "lodash/groupBy";

class ContractClientForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      rows: [""],
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  add(model) {
    let clients = [];
    Object.keys(model).map((client) => {
      clients.push({ [client]: model[client] });
    });

    // groupBy index
    clients = groupBy(clients, (client) =>
      Object.keys(client).map((client, i) => client.split(" ")[1])
    );

    // merge
    clients = Object.values(clients).map((client, i) =>
      Object.assign({}, client[0], client[1], client[2])
    );

    // rename keys
    clients = clients.map((client, i) => ({
      name: client[`name ${i}`],
      weekly_hours: client[`weekly_hours ${i}`],
      annual_hours: client[`annual_hours ${i}`],
    }));

    this.props.onAdd(clients);
  }

  cancel() {
    this.props.onCancel();
  }

  submit(model) {
    model.id = this.props.client.id;
    model.isActive = model.isActive === "true";
    model.weekly_hours = model.weekly_hours
      ? parseFloat(model.weekly_hours)
      : null;
    model.annual_hours = model.annual_hours
      ? parseFloat(model.annual_hours)
      : null;
    this.props.onSave(model);
  }

  onChange(field, selected) {
    selected.value = selected.value.toString();
    console.log("selected.value", selected.value);
    this.setState({
      [field]: selected.value,
    });
  }

  addRow() {
    let rows = this.state.rows;
    rows.push("");
    this.setState({ rows });
  }

  deleteRow(idx) {
    let rows = this.state.rows;
    rows.splice(idx, 1);
    this.setState({ rows });
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    let { client, adding } = this.props;

    return (
      <Col md={12}>
        <Formsy
          ref="form"
          className="vertical form"
          onValidSubmit={adding ? this.add : this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          {adding &&
            this.state.rows.map((r, idx) => (
              <Row key={idx}>
                <Col md={7}>
                  <Input
                    formNoValidate
                    name={`name ${idx}`}
                    label="Name/Initials"
                    value={""}
                    disabled={!this.props.editing}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    formNoValidate
                    type="number"
                    name={`weekly_hours ${idx}`}
                    label="Weekly Hours"
                    placeholder="Weekly Hours"
                    disabled={!this.props.editing}
                    value={""}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    formNoValidate
                    type="number"
                    name={`annual_hours ${idx}`}
                    label="Annual Hours"
                    placeholder="Annual Hours"
                    disabled={!this.props.editing}
                    value={""}
                  />
                </Col>
                <Col md={1}>
                  <button
                    className="btn btn-delete"
                    type="button"
                    onClick={() => this.deleteRow(idx)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </Col>
              </Row>
            ))}

          {!adding && (
            <Row>
              <Col md={6}>
                <Input
                  formNoValidate
                  name="name"
                  label="Name/Initials"
                  disabled={!this.props.editing}
                  value={client.name}
                />
              </Col>
              <Col md={2}>
                <Input
                  formNoValidate
                  type="number"
                  name="weekly_hours"
                  label="Weekly Hours"
                  placeholder="Weekly Hours"
                  disabled={!this.props.editing}
                  value={client.weekly_hours || ""}
                />
              </Col>
              <Col md={2}>
                <Input
                  formNoValidate
                  type="number"
                  name="annual_hours"
                  label="Annual Hours"
                  placeholder="Annual Hours"
                  disabled={!this.props.editing}
                  value={client.annual_hours || ""}
                />
              </Col>
              <Col md={2}>
                <DropdownSearch
                  required
                  name="isActive"
                  label="Status"
                  onChange={this.onChange}
                  disabled={!this.props.editing}
                  value={this.state.isActive || client.isActive.toString()}
                  data={[
                    { value: "true", label: "Active" },
                    { value: "false", label: "Inactive" },
                  ]}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              {adding && (
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={this.addRow}
                >
                  Add Row
                </button>
              )}
              <button
                className="btn btn-danger"
                onClick={this.cancel}
                type="button"
              >
                Cancel
              </button>
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            </Col>
          </Row>
        </Formsy>
      </Col>
    );
  }
}

ContractClientForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  adding: PropTypes.bool,
  saving: PropTypes.bool,
  client: PropTypes.object,
  contract: PropTypes.object.isRequired,
};

export default ContractClientForm;
