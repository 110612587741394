import { push } from 'react-router-redux';
import * as types from './actionTypes';
import { showModalSuccess } from './modalActions';
import { showAlertSuccess, hideAlertSuccess } from './alertActions';
import companyService from '../services/companyService';

export function loadCompanySuccess(companies) {
  return {
    type: types.LOAD_COMPANY_SUCCESS,
    companies
  };
}

export function getCompanySuccess(company) {
  return {
    type: types.GET_COMPANY_SUCCESS,
    company
  };
}

export function savingCompany(status = true) {
  return {
    type: types.SAVING_COMPANY,
    savingCompany: status
  };
}

export function createCompanySuccess(company) {
  return {
    type: types.CREATE_COMPANY_SUCCESS,
    company
  };
}

export function updateCompanySuccess(company) {
  return {
    type: types.UPDATE_COMPANY_SUCCESS,
    company
  };
}

export function requestCompanyId(companyId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_COMPANY_ID,
      companyToDelete: companyId
    });
    dispatch(showModalSuccess('companyDeleteModal'));
  };
}

export function deleteCompanySuccess(companyId) {
  return {
    type: types.DELETE_COMPANY_SUCCESS,
    companyId
  };
}

export function loadCompanies() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return companyService
      .loadCompanies()
      .then(data => dispatch(loadCompanySuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function getCompany(id, showCOMPANYDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return companyService
      .getCompany(id)
      .then(company => {
        dispatch(getCompanySuccess(company));
        if (showCOMPANYDetails) {
          dispatch(showModalSuccess('companyDetailsModal'));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function createCompany(company) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCompany());
    return companyService
      .createCompany(company)
      .then(createdCompany => {
        dispatch(createCompanySuccess(createdCompany));
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess('Company created successfully', 'success'));
        dispatch(push('/app/settings/companies'));
      })
      .catch(error => {
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateCompany(company) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCompany());
    return companyService
      .updateCompany(company)
      .then(updatedCompany => {
        dispatch(updateCompanySuccess(updatedCompany));
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess('Company updated successfully', 'success'));
        dispatch(push('/app/settings/companies'));
      })
      .catch(error => {
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function createLocation(company, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCompany());
    return companyService
      .createLocation(company, location)
      .then(createdCompany => {
        dispatch(updateCompanySuccess(createdCompany));
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess('Location created successfully', 'success'));
        dispatch(push('/app/settings/company'));
      })
      .catch(error => {
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateLocation(company, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCompany());
    return companyService
      .updateLocation(company, location)
      .then(updatedCompany => {
        dispatch(updateCompanySuccess(updatedCompany));
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess('Location updated successfully', 'success'));
        dispatch(push('/app/settings/companies'));
      })
      .catch(error => {
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteLocation(company, location) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingCompany());
    return companyService
      .deleteLocation(company, location)
      .then(updatedCompany => {
        dispatch(updateCompanySuccess(updatedCompany));
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess('Location deleted successfully', 'success'));
        dispatch(push('/app/settings/companies'));
      })
      .catch(error => {
        dispatch(savingCompany(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteCompany(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return companyService
      .deleteCompany(id)
      .then(() => {
        dispatch(deleteCompanySuccess(id));
        dispatch(showAlertSuccess('Company removed', 'success'));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}
