import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import Loading from "../../common/Loading";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, MenuItem } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class InvoiceCallList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      selected: {}
    };
  }

  componentDidMount = () => {
    this.setState({
      selected: {},
      selectAll: false
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.saving !== this.props.saving) {
      this.setState({ selected: {}, selectAll: false });
    }
  };

  toggleRow = id => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
    this.props.onToggle(id);
  };

  toggleSelectAll = () => {
    let newSelected = {};
    let calls = this.props.calls;

    if (this.state.selectAll == 0) {
      this.props.onToggleAll(calls.map(call => call._id));
      calls.map(call => {
        newSelected[call._id] = true;
      });
    } else if (this.state.selectAll == 1) {
      this.props.onToggleAll(calls.map(call => call._id));
      calls.map(call => {
        newSelected[call._id] = false;
      });
    } else if (this.state.selectAll == 2) {
      Object.entries(this.state.selected).map(call => {
        if (call[1]) {
          newSelected[call[0]] = false;
          this.props.onToggle(call[0]);
        }
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll == 0 ? 1 : 0
    });
  };

  render() {
    const { isLoading, calls } = this.props;
    const { selectAll } = this.state;

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onClick={() => this.toggleSelectAll()}
            checked={selectAll == 1}
          />
        ),
        Cell: row =>
          (
            <input
              type="checkbox"
              onClick={() => this.toggleRow(row.value)}
              checked={this.state.selected[row.original._id] === true}
            />
          ) || null,
        sortable: false,
        maxWidth: 60,
        style: {
          textAlign: "center"
        }
      },
      {
        Header: "Contract",
        accessor: "contract.name"
      },
      {
        Header: "Staff",
        accessor: "assigned_to.full_name",
        Cell: row => row.value || null
      },
      {
        Header: "Date of Service",
        accessor: "start_time",
        Cell: row =>
          <span>{moment(row.value).format("MM/DD/YYYY")}</span> || null
      },
      {
        Header: "Product Category",
        accessor: "product_category",
        Cell: row => <span>{row.value ? row.value.label : "--"}</span> || null
      },
      {
        Header: "Billing Quantity",
        accessor: "billing_quantity",
        id: "quantity",
        Cell: row => row.value.toFixed(2) || null
      },
      {
        Header: "Total",
        accessor: "billing_total",
        id: "total",
        Cell: row =>
          typeof row.value === "number" ? `$${row.value.toFixed(2)}` : null
      },
      {
        Header: "",
        accessor: "_id",
        sortable: false,
        Cell: ({ value }) =>
          (
            <div className="text-center actions">
              <DropdownButton title="Actions" id="dropdown">
                <LinkContainer to={`/app/calls/${value}`}>
                  <MenuItem>
                    <FontAwesomeIcon icon={faFolder} />
                    View
                  </MenuItem>
                </LinkContainer>
              </DropdownButton>
            </div>
          ) || null
      }
    ];

    const props = {
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const selected = this.state.selected[row.original._id] === true;
          return {
            style: {
              color: selected ? "#cf4a03" : "inherit",
              fontWeight: selected ? 500 : 400
            }
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (state, rowInfo, column) => {
        return {
          onClick: () => {
            if (column.id !== "_id") {
              this.toggleRow(rowInfo.original._id);
            }
          }
        };
      }
    };

    return (
      <ReactTable
        className="-highlight"
        data={calls}
        LoadingComponent={() => <Loading active={isLoading} />}
        columns={columns}
        defaultPageSize={10}
        {...props}
        defaultSorted={[
          {
            id: "contract.name",
            asc: true
          },
          {
            id: "start_time",
            asc: true
          }
        ]}
      />
    );
  }
}

InvoiceCallList.propTypes = {
  calls: PropTypes.array.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  accounts: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array.isRequired,
  value: PropTypes.object
};

export default InvoiceCallList;
