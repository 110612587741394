import React from "react";
import PropTypes from "prop-types";
import localizer from "react-big-calendar/lib/localizer";
import message from "react-big-calendar/lib/utils/messages";
import dates from "react-big-calendar/lib/utils/dates";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { accessor as get } from "react-big-calendar/lib/utils/accessors";
import {
  accessor,
  dateFormat,
  dateRangeFormat
} from "react-big-calendar/lib/utils/propTypes";
import { inRange } from "react-big-calendar/lib/utils/eventLevels";
import { isSelected } from "react-big-calendar/lib/utils/selection";

import { Link } from "react-router-dom";

class CustomAgenda extends React.Component {
  renderDay(day, events, dayKey) {
    let {
      culture,
      components,
      titleAccessor,
      agendaDateFormat,
      eventPropGetter,
      startAccessor,
      endAccessor,
      selected
    } = this.props;

    let EventComponent = components.event;
    let DateComponent = components.date;

    events = events.filter(e => {
      let eventsInRange = inRange(e, day, day, this.props);
      return eventsInRange;
    });

    return events.map((event, idx) => {
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      let eventDate = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate()
      );

      const { className, style } = eventPropGetter
        ? eventPropGetter(
            event,
            get(event, startAccessor),
            get(event, endAccessor),
            isSelected(event, selected)
          )
        : {};
      let dateLabel =
        idx === 0 && localizer.format(day, agendaDateFormat, culture);
      let first =
        idx === 0 ? (
          <td
            key={idx}
            colSpan={2}
            style={
              eventDate.getTime() == today.getTime() ? { color: "#f73d41" } : {}
            }
          >
            {DateComponent ? (
              <DateComponent day={day} label={dateLabel} />
            ) : (
              dateLabel
            )}
          </td>
        ) : (
          false
        );

      let title = get(event, titleAccessor);

      return (
        <tbody ref="tbody" key={event.id}>
          <tr className="date-cell-label">{first}</tr>
          <tr className="event-cell-row">
            <td className="rbc-agenda-time-cell">
              {this.timeRangeLabel(day, event)}
            </td>
            <td className="rbc-agenda-event-cell">
              <Link className="brand-color" to={`/app/calls/${event.id}/edit`}>
                {EventComponent ? (
                  <EventComponent
                    event={event}
                    title={title}
                    desc={event.desc}
                  />
                ) : (
                  <div>
                    <h4>{title}</h4>
                    <p>
                      {event.desc}
                      <em>
                        {event.subdesc
                          .split("\n")
                          .map((name, i) =>
                            name && i == 0 ? ` - ${name}` : ` ${name}`
                          )}
                      </em>
                    </p>
                  </div>
                )}
              </Link>
            </td>
          </tr>
        </tbody>
      );
    }, []);
  }

  timeRangeLabel(day, event) {
    let {
      endAccessor,
      startAccessor,
      allDayAccessor,
      culture,
      messages,
      components
    } = this.props;

    let labelClass = "",
      TimeComponent = components.time,
      label = message(messages).allDay;

    let start = get(event, startAccessor);
    let end = get(event, endAccessor);

    if (!get(event, allDayAccessor)) {
      if (dates.eq(start, end, "day")) {
        label = localizer.format(
          { start, end },
          this.props.agendaTimeRangeFormat,
          culture
        );
      } else if (dates.eq(day, start, "day")) {
        label = localizer.format(start, this.props.agendaTimeFormat, culture);
      } else if (dates.eq(day, end, "day")) {
        label = localizer.format(end, this.props.agendaTimeFormat, culture);
      }
    }

    if (dates.gt(day, start, "day")) labelClass = "rbc-continues-prior";
    if (dates.lt(day, end, "day")) labelClass += " rbc-continues-after";

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? (
          <TimeComponent event={event} day={day} label={label} />
        ) : (
          label
        )}
      </span>
    );
  }

  //   _adjustHeader = () => {
  //     let header = this.refs.header;
  //     let firstRow = this.refs.tbody.firstChild;

  //     if (!firstRow) return;

  //     let isOverflowing =
  //       this.refs.content.scrollHeight > this.refs.content.clientHeight;
  //     let widths = this._widths || [];

  //     this._widths = [
  //       getWidth(firstRow.children[0]),
  //       getWidth(firstRow.children[1])
  //     ];

  //     if (widths[0] !== this._widths[0] || widths[1] !== this._widths[1]) {
  //       this.refs.dateCol.style.width = this._widths[0] + 'px';
  //       this.refs.timeCol.style.width = this._widths[1] + 'px';
  //     }

  //     if (isOverflowing) {
  //       classes.addClass(header, 'rbc-header-overflowing');
  //       header.style.marginRight = scrollbarSize() + 'px';
  //     } else {
  //       classes.removeClass(header, 'rbc-header-overflowing');
  //     }
  //   };
  render() {
    let { length, date, events, startAccessor } = this.props;

    let defaultDate = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    defaultDate = new Date(y, m, 1);

    let messages = message(this.props.messages);
    let end = dates.add(defaultDate, length, "day");
    let range = dates.range(defaultDate, end, "day");

    events = events.filter(event =>
      inRange(event, defaultDate, end, this.props)
    );

    events.sort((a, b) => +get(a, startAccessor) - +get(b, startAccessor));

    return (
      <div className="rbc-agenda-view">
        <div className="rbc-agenda-content">
          <table className="rbc-agenda-table" ref="content">
            {range.map((day, idx) => this.renderDay(day, events, idx))}
          </table>
        </div>
      </div>
    );
  }
}

CustomAgenda.range = (start, { length = CustomAgenda.defaultProps.length }) => {
  let end = dates.add(start, length, "day");
  return { start, end };
};

CustomAgenda.navigate = (
  date,
  action,
  { length = CustomAgenda.defaultProps.length }
) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -length, "day");

    case navigate.NEXT:
      return dates.add(date, length, "day");

    default:
      return date;
  }
};

CustomAgenda.title = (
  start,
  { length = CustomAgenda.defaultProps.length, formats, culture }
) => {
  let end = dates.add(start, length, "day");
  return localizer.format({ start, end }, formats.agendaHeaderFormat, culture);
};

CustomAgenda.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number.isRequired,
  titleAccessor: accessor.isRequired,
  allDayAccessor: accessor.isRequired,
  startAccessor: accessor.isRequired,
  endAccessor: accessor.isRequired,
  eventPropGetter: PropTypes.func,
  selected: PropTypes.object,

  agendaDateFormat: dateFormat,
  agendaTimeFormat: dateFormat,
  agendaTimeRangeFormat: dateRangeFormat,
  culture: PropTypes.string,

  components: PropTypes.object.isRequired,
  messages: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string
  })
};

CustomAgenda.defaultProps = {
  length: 30
};

export default CustomAgenda;
