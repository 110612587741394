import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const codes = (state = initialState.codes, action) => {
  switch (action.type) {
    case types.LOAD_CODE_SUCCESS:
      return action.codes;

    case types.CREATE_CODE_SUCCESS:
      return [...state, Object.assign({}, action.codes)];

    case types.UPDATE_CODE_SUCCESS:
      return [
        ...state.filter(codes => codes._id !== action.code._id),
        Object.assign({}, action.codes)
      ];

    case types.DELETE_CODE_SUCCESS:
      return [...state.filter(code => code._id !== action.codeId)];

    default:
      return state;
  }
};

export const code = (state = initialState.code, action) => {
  switch (action.type) {
    case types.GET_CODE_SUCCESS:
      return action.code;

    default:
      return state;
  }
};

export const savingCode = (state = initialState.savingCode, action) => {
  switch (action.type) {
    case types.SAVING_CODE:
      return action.savingCode;

    default:
      return state;
  }
};

export const codeToDelete = (state = initialState.codeToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_CODE_ID:
      console.log("action", action);
      return action.codeToDelete;

    default:
      return state;
  }
};
