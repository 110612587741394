import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Formsy from "formsy-react";
import { Input, Textarea } from "formsy-react-components";
import autoBind from "react-autobind";

class CompanyForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false
    });
  }

  submit(model) {
    this.props.onSave(model);
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    let form;
    if (this.props.company) {
      form = (
        <Formsy
          ref="form"
          className="horizontal"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Input
            formNoValidate
            required
            name="name"
            label="Name"
            placeholder="Name"
            value={this.props.company.name || ""}
          />
          <Input
            formNoValidate
            required
            name="website"
            label="Website"
            placeholder="Website"
            value={this.props.company.website || ""}
          />
          <Input
            formNoValidate
            required
            name="logo"
            label="Logo"
            placeholder="Logo"
            value={this.props.company.logo || ""}
          />
          <div>
            <Link className="btn btn-danger" to="/app/settings/company">
              Cancel
            </Link>
            <button
              className="btn btn-warning"
              type="button"
              onClick={this.resetForm}
            >
              Reset
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </div>
        </Formsy>
      );
    }
    return <div>{form}</div>;
  }
}

CompanyForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired
};

export default CompanyForm;
