import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const divisions = (state = initialState.divisions, action) => {
  switch (action.type) {
    case types.LOAD_DIVISION_SUCCESS:
      return action.divisions;

    case types.CREATE_DIVISION_SUCCESS:
      return [...state, Object.assign({}, action.divisions)];

    case types.UPDATE_DIVISION_SUCCESS:
      return [
        ...state.filter(division => division._id !== action.divisionId),
        Object.assign({}, action.division)
      ];
    case types.DELETE_DIVISION_SUCCESS:
      return [
        ...state.filter(division => division._id !== action.divisionId),
        Object.assign({}, action.division)
      ];

    default:
      return state;
  }
};

export const division = (state = initialState.division, action) => {
  switch (action.type) {
    case types.GET_DIVISION_SUCCESS:
      return action.division;

    default:
      return state;
  }
};

export const savingDivision = (state = initialState.savingDivision, action) => {
  switch (action.type) {
    case types.SAVING_DIVISION:
      return action.savingDivision;

    default:
      return state;
  }
};

export const divisionToDelete = (
  state = initialState.divisionToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_DIVISION_ID:
      return action.divisionToDelete;

    default:
      return state;
  }
};

export const departmentToDelete = (
  state = initialState.departmentToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_DEPARTMENT_ID:
      return action.departmentToDelete;

    default:
      return state;
  }
};
