import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as chargetypeActions from "../../actions/chargetypeActions";

import ChargetypeForm from "./ChargetypeForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import { Row, Col } from "react-bootstrap";

class ChargetypeEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { editing: false };

    autoBind(this);

    props.actions.getChargetype(props.match.params.id);
  }

  handleSave(chargetype) {
    let data = {
      id: this.props.chargetype.id,
      name: chargetype.name
    };

    this.props.actions.updateChargetype(data);
  }

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  render() {
    let { chargetype } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Chargetypes" }, { label: chargetype.type }]}
          />
          <EditButton onClickEdit={this.handleEdit} />
        </Row>
        <Row>
          <Col md={12}>
            <ChargetypeForm
              onSave={this.handleSave}
              editing={this.state.editing}
              chargetype={chargetype}
              saving={this.props.savingChargetype}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ChargetypeEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingChargetype: PropTypes.bool,
  chargetype: PropTypes.object,
  params: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    savingChargetype: state.reducers.savingChargetype,
    chargetype: state.reducers.chargetype
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...chargetypeActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ChargetypeEditPage);
