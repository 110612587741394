import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as callActions from "../../actions/callActions";
import * as contractActions from "../../actions/contractActions";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as itemActions from "../../actions/itemActions";
import * as divisionActions from "../../actions/divisionActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as accountActions from "../../actions/accountActions";

import CallApprovalList from "./CallApprovalList";
import CallProcedureForm from "./CallProcedureForm";
import FilterComponent from "../common/FilterComponent";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class CallApprovalPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      call: false,
      calls: [],
      open: true,
      overflow: false,
      filters: localStorage.getItem("approvedFilters")
        ? JSON.parse(localStorage.getItem("approvedFilters"))
        : {
            parent_account: [],
            contract: [],
            assigned_to: [],
            division: [],
            "assigned_to.job_title": [],
            start_time: moment(new Date()).startOf("week"),
            end_time: moment(new Date()).endOf("week"),
            active: "A"
          }
    };
  }

  componentDidMount = () => {
    let {
      accounts,
      contracts,
      users,
      divisions,
      items,
      chargetypes,
      actions
    } = this.props;

    this.fetchData(this.props);

    actions.loadUsers(true);

    // if (isEmpty(users)) actions.loadUsers();
    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.codes !== this.props.codes) {
      this.fetchData(nextProps);
    }
  };

  fetchData = props => {
    let code = find(props.codes, { entity: "Call", value: "Completed" });
    if (code) {
      this.setState({ stage: code });
      this.props.actions.loadCallsByQuery({
        auth: this.props.auth,
        stage: code._id,
        forApproval: true
      });
    }
  };

  componentWillUnmount = () => {
    this.props.actions.clearApprovedCalls();
  };

  onClickDetail = (e, id) => {
    e.stopPropagation();
    this.props.actions.getCall(id, true);
  };

  onClickDelete = callId => {
    this.props.actions.requestCallId(callId);
  };

  handleChangeFilters = (selectedOptions, field) => {
    this.setState(
      {
        filters: { ...this.state.filters, [field]: selectedOptions }
      },
      () => {
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  handleChangeDate = (startDate, endDate) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...(startDate && { start_time: moment(startDate).startOf("day") }),
          ...(endDate && { end_time: moment(endDate).endOf("day") })
        }
      },
      () => {
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  resetFilters = () => {
    this.setState({
      filters: {
        contract: [],
        parent_account: [],
        assigned_to: [],
        division: [],
        "assigned_to.job_title": [],
        start_time: null,
        end_time: null,
        active: "A"
      }
    });
    localStorage.removeItem("approvedFilters");
  };

  handleDelete = () => {
    this.props.actions.deleteCall(this.props.callToDelete);
  };

  handleToggle = selected => {
    this.props.actions.toggleCall(selected);
  };

  handleToggleAll = calls => {
    this.props.actions.toggleAll(calls);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  bulkApprove = async () => {
    const calls = this.props.callsToApprove;
    const user = this.props.auth.user;
    const stage = find(
      this.props.codes,
      code => code.entity == "Call" && code.label == "Approved"
    );

    // await this.resetFilters();
    await this.props.actions.bulkApprove(calls, user._id, stage._id);
    await this.props.actions.clearApprovedCalls();
    await this.props.actions.loadCallsByQuery({
      auth: this.props.auth,
      stage: this.state.stage._id,
      forApproval: true
    });
  };

  saveEntries = async entries => {
    // calculate total
    let total = 0;
    entries.map(entry => (total += parseFloat(entry.quantity)));

    let data = {
      ...this.props.call,
      quantity: total,
      entries
    };

    await this.props.actions.updateCall(data);
    await this.props.actions.loadCallsByQuery({
      auth: this.props.auth,
      stage: this.state.stage._id,
      forApproval: true
    });
    await this.resetFilters();
    await this.props.actions.hideModal();
  };

  cancelEntries = () => {
    this.props.actions.hideModal();
  };

  setOverflow = () => {
    this.setState({ overflow: true });
  };

  genButtonText = () => {
    let { savingCall } = this.props;
    return (!savingCall ? "APPROVE" : "APPROVING").concat(
      this.props.callsToApprove.length > 0
        ? this.props.callsToApprove.length == 1
          ? ` ${this.props.callsToApprove.length} Call`
          : ` ${this.props.callsToApprove.length} Calls`
        : ""
    );
  };

  toggleActiveUsers = (field, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          assigned_to: [],
          active: value
        }
      },
      () => {
        localStorage.setItem(
          "approvedFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  render() {
    const {
      auth,
      items,
      contracts,
      chargetypes,
      codes,
      users,
      calls,
      callsToApprove
    } = this.props;

    let assigned_to = this.state.assigned_to
      ? this.state.assigned_to
      : this.state.call.assigned_to
      ? this.state.call.assigned_to._id || this.state.call.assigned_to
      : this.props.auth.user._id;

    let timeSheet = (
      <CallProcedureForm
        clearApprovedCalls={this.props.actions.clearApprovedCalls}
        call={this.props.call}
        entries={this.props.call.entries}
        items={items}
        codes={codes}
        assignedTo={assigned_to}
        startTime={this.props.call.start_time}
        users={users}
        auth={auth}
        contract={this.props.call.contract}
        product_category={this.props.call.product_category}
        onOverflow={this.setOverflow}
        chargetypes={chargetypes}
        onSave={this.saveEntries}
        onCancel={this.cancelEntries}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Time Approval" }]} />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success btn-sm filter"
              type="button"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success btn-sm btn-add"
              type="button"
              disabled={callsToApprove.length <= 0}
              onClick={this.bulkApprove}
            >
              {this.genButtonText()}
            </button>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          users={users}
          open={this.state.open}
          filters={this.state.filters}
          forApproval={true}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          onChangeDateMobile={this.handleChangeDateMobile}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          toggleActive={this.toggleActiveUsers}
        />
        <Row>
          <Col md={12}>
            <CallApprovalList
              calls={calls}
              codes={codes}
              contracts={contracts}
              items={items}
              users={users}
              auth={auth}
              onClickDetail={this.onClickDetail}
              onClickDelete={this.onClickDelete}
              onClearCalls={this.props.actions.clearApprovedCalls}
              onToggle={this.handleToggle}
              onToggleAll={this.handleToggleAll}
              filters={this.state.filters}
            />
            <Modal
              id="callDetailsModal"
              title="Time Entries"
              body={timeSheet}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="callDeleteModal"
              title="Delete Call"
              body="Are you sure you want to delete this call?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CallApprovalPage.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object,
  modal: PropTypes.object,
  callToDelete: PropTypes.string,
  calls: PropTypes.array.isRequired,
  call: PropTypes.object.isRequired,
  callsToApprove: PropTypes.array,
  items: PropTypes.array,
  contracts: PropTypes.array,
  codes: PropTypes.array,
  divisions: PropTypes.array,
  chargetypes: PropTypes.array,
  accounts: PropTypes.array,
  users: PropTypes.array
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    modal: state.reducers.modal,
    callToDelete: state.reducers.callToDelete,
    calls: state.reducers.calls,
    call: state.reducers.call,
    callsToApprove: state.reducers.callsToApprove,
    savingCall: state.reducers.savingCall,
    contracts: state.reducers.contracts,
    users: state.reducers.users,
    items: state.reducers.items,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    chargetypes: state.reducers.chargetypes,
    accounts: state.reducers.accounts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...contractActions,
        ...modalActions,
        ...itemActions,
        ...userActions,
        ...divisionActions,
        ...chargetypeActions,
        ...accountActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CallApprovalPage);
