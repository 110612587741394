import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import UserList from "./UserList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class UsersPage extends Component {
  componentDidMount = () => {
    let { actions } = this.props;

    // Load all users
    actions.loadUsers(true);
  };

  onClickDelete = (userId) => {
    this.props.actions.requestUserId(userId);
  };

  handleDelete = () => {
    this.props.actions.deleteUser(this.props.userToDelete);
  };

  render() {
    let { users, auth, loading } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Employees" }]} />
          <AddButton entity="users" auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <UserList
              users={users}
              auth={auth}
              loading={loading}
              onClickView={(id) => this.props.history.push(`/app/users/${id}`)}
              onClickDelete={this.onClickDelete}
            />
          </Col>
        </Row>
        <ConfirmModal
          id="userDeleteModal"
          title="Delete User"
          body="Are you sure you want to delete this user?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

UsersPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  users: PropTypes.array.isRequired,
  userToDelete: PropTypes.string,
};

function mapStatesToProps(state) {
  return {
    modal: state.reducers.modal,
    users: state.reducers.users,
    userToDelete: state.reducers.userToDelete,
    loading: state.reducers.loadingUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...userActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(UsersPage);
