import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as callActions from "../../actions/callActions";
import * as contractActions from "../../actions/contractActions";
import * as userActions from "../../actions/userActions";
import * as accountActions from "../../actions/accountActions";
import * as itemActions from "../../actions/itemActions";
import * as codeActions from "../../actions/codeActions";
import * as modalActions from "../../actions/modalActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as divisionActions from "../../actions/divisionActions";

import CallForm from "./CallForm";
import BreadCrumbs from "../common/BreadCrumbs";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class CallAddPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount = () => {
    let {
      accounts,
      contracts,
      items,
      divisions,
      chargetypes,
      actions
    } = this.props;

    actions.loadUsers();

    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
  };

  handleCancel = () => {
    let { router } = this.props;

    this.props.history.push(
      router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : router.previousLocation == "/app/invoices/add"
        ? "/app/invoices/add"
        : `/app/calls`
    );
  };

  handleSave = async call => {
    call = call || this.state.call;
    let data = {
      ...call
    };

    if (call.completed_by) {
      data["completed_date"] = new Date();
    }

    await this.props.actions.createCall(data);

    let callCreated = await this.props.calls[this.props.calls.length - 1];
    await this.props.history.push(`/app/calls/${callCreated._id}`);
  };

  onClickSave = call => {
    let stage = find(this.props.codes, { _id: call.stage });
    if (stage.label == "Completed") {
      this.props.actions.showModalSuccess("callDeleteModal");
      this.setState({ call });
    } else {
      this.handleSave(call);
    }
  };

  onConfirmChange = () => {
    this.props.actions.showModalSuccess("callConfirmModal");
  };

  handleEnterTime = () => {
    this.props.actions.showModal("callDetailsModal");
  };

  render() {
    let {
      auth,
      contracts,
      chargetypes,
      users,
      accounts,
      codes,
      divisions,
      items
    } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[
              { label: "Time Entries" },
              { label: "Add Time Entry" }
            ]}
          />
        </Row>
        <Row>
          <Col md={12}>
            <CallForm
              call={{}}
              contracts={contracts}
              users={users}
              auth={auth}
              accounts={accounts}
              items={items}
              divisions={divisions}
              chargetypes={chargetypes}
              codes={codes}
              saving={this.props.savingCall}
              onAdd={this.onClickSave}
              onCancel={this.handleCancel}
              onEnterTime={this.handleEnterTime}
              onConfirmChange={this.onConfirmChange}
              editing
              adding
            />
            <ConfirmModal
              id="callDeleteModal"
              title="Save Call"
              body="Are you sure you want to save this call? Once it is completed you will not be able to make edits."
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleSave}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CallAddPage.propTypes = {
  auth: PropTypes.object,
  actions: PropTypes.object.isRequired,
  savingCall: PropTypes.bool,
  call: PropTypes.object,
  divisions: PropTypes.array,
  accounts: PropTypes.array,
  items: PropTypes.array,
  users: PropTypes.array,
  contracts: PropTypes.array,
  contract: PropTypes.object,
  calls: PropTypes.array,
  chargetypes: PropTypes.array,
  codes: PropTypes.array
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    router: state.reducers.router,
    auth: state.reducers.auth,
    savingCall: state.reducers.savingCall,
    calls: state.reducers.calls,
    accounts: state.reducers.accounts,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    users: state.reducers.users,
    items: state.reducers.items,
    contracts: state.reducers.contracts,
    chargetypes: state.reducers.chargetypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...modalActions,
        ...contractActions,
        ...userActions,
        ...accountActions,
        ...itemActions,
        ...divisionActions,
        ...chargetypeActions,
        ...codeActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CallAddPage);
