import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactDOM from "react-dom";
import * as codeActions from "../../actions/codeActions";
import Formsy from "formsy-react";
import orderBy from "lodash/orderBy";
import Loadable from "react-loading-overlay";
import Field from "./Field";

import {
  Checkbox,
  CheckboxGroup,
  Input,
  RadioGroup,
  Row,
  Select,
  File,
  Textarea,
  Form
} from "formsy-react-components";

let formFields = [];

class DynamicForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  async submit(model) {
    await this.props.onSave(model);
    await this.refs.form.reset();
  }

  cancel() {
    this.refs.form.reset();
    this.props.onCancel();
  }

  render() {
    return (
      <span>
        {this.props.fieldData && (
          <Formsy
            ref="form"
            className="vertical form"
            onValidSubmit={this.submit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            {orderBy(this.props.fieldData.fields, "order").map((field, i) => {
              if (!field.subfields || field.subfields.length == 0) {
                return (
                  <Field
                    key={`${this.props.entity}_${field.key}`}
                    entity={this.props.entity}
                    adding={this.props.adding}
                    editing={this.props.editing}
                    sysCodes={this.props.codes}
                    field={field}
                  />
                );
              } else {
                return (
                  <fieldset key={i}>
                    <legend>{field.label}</legend>
                    {field.instructions && <p>{field.instructions}</p>}
                    {orderBy(field.subfields, "order").map((sub, j) => {
                      return (
                        <Field
                          key={j}
                          entity={this.props.entity}
                          adding={this.props.adding}
                          editing={this.props.editing}
                          sysCodes={this.props.codes}
                          field={sub}
                          parent={field.key}
                        />
                      );
                    })}
                  </fieldset>
                );
              }
            })}
            <div className="col-md-12">
              <button
                className="btn btn-danger"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            </div>
          </Formsy>
        )}
        {!this.props.fieldData && <h1>Nothing loaded</h1>}
      </span>
    );
  }
}

DynamicForm.propTypes = {
  actions: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  saving: PropTypes.bool,
  fieldData: PropTypes.object,
  entity: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  chargetypes: PropTypes.array.isRequired,
  codes: PropTypes.array.isRequired
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingContact: state.reducers.savingContact,
    contact: state.reducers.contact,
    contract: state.reducers.contract,
    account: state.reducers.account,
    division: state.reducers.division,
    glaccount: state.reducers.glaccount,
    zip: state.reducers.zip,
    role: state.reducers.role,
    item: state.reducers.item,
    accounts: state.reducers.accounts,
    chargetypes: state.reducers.chargetypes,
    user: state.reducers.user,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...codeActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(DynamicForm);
