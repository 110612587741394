import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";
import * as modalActions from "../../actions/modalActions";

import AccountLocationForm from "./AccountLocationForm";
import AccountLocationList from "./AccountLocationList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ConfirmModal from "../common/ConfirmModal";
import Modal from "../common/Modal";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class AccountLocations extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      location: {},
      adding: false
    };
  }

  componentDidMount = () => {
    let { account, actions } = this.props;
    if (isEmpty(account)) actions.getAccount(this.props.match.params.id);
  };

  onClickAdd = () => {
    this.setState({ location: {}, adding: true });
    this.props.actions.getAccount(this.props.account._id, true);
  };

  onClickEdit = accountLocationId => {
    let location = find(this.props.account.locations, {
      _id: accountLocationId
    });
    this.setState({ location, adding: false });
    this.props.actions.getAccount(this.props.account._id, true);
  };

  onClickDelete = location => {
    this.props.actions.requestLocationId(location);
  };

  handleAdd = async (account, location) => {
    await this.props.actions.createLocation(account, location);
    await this.props.actions.getAccount(this.props.match.params.id);
  };

  handleSave = async (account, location) => {
    let data = {
      ...account
    };

    if (location.is_primary) {
      data.address = location.address;
    }

    await this.props.actions.updateLocation(data, location);
    await this.props.actions.getAccount(this.props.match.params.id);
  };

  handleDelete = async () => {
    await this.props.actions.deleteLocation(
      this.props.account,
      this.props.acctLocationToDelete
    );
    await this.props.actions.getAccount(this.props.match.params.id);
  };

  render() {
    let { account, savingAccount, codes, auth } = this.props;
    let locationForm = (
      <AccountLocationForm
        account={account}
        saving={savingAccount}
        codes={codes}
        location={this.state.location}
        onAdd={this.handleAdd}
        onSave={this.handleSave}
        onCancel={this.props.actions.hideModal}
        editing={auth.maxRole == 9000}
        adding={this.state.adding}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${account.name}` }, { label: "Locations" }]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
        </Row>
        <Row>
          <Col md={12}>
            <AccountLocationList
              account={this.props.account}
              onClickDelete={this.onClickDelete}
              onClickEdit={this.onClickEdit}
            />
          </Col>
          <Modal
            id="accountDetailsModal"
            title={this.state.location.name || "Add Account Location"}
            body={locationForm}
            modal={this.props.modal}
            close={this.props.actions.hideModal}
          />
          <ConfirmModal
            id="locationDeleteModal"
            title="Delete Location"
            body="Are you sure you want to delete this location?"
            modal={this.props.modal}
            close={this.props.actions.hideModal}
            confirm={this.handleDelete}
          />
        </Row>
      </div>
    );
  }
}

AccountLocations.propTypes = {
  actions: PropTypes.object.isRequired,
  account: PropTypes.object,
  codes: PropTypes.array,
  location: PropTypes.object,
  acctLocationToDelete: PropTypes.string,
  modal: PropTypes.object,
  params: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    codes: state.reducers.codes,
    account: state.reducers.account,
    acctLocationToDelete: state.reducers.acctLocationToDelete,
    savingAccount: state.reducers.savingAccount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...accountActions, ...contactActions, ...modalActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AccountLocations);
