import axios from 'axios';
import * as endpoints from './apiEndpoints';

class LabelService {
  static loadLabels() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_LABELS}`;

    return axios.get(url).then(response => {
      return response.data;
    });
  }

  static getLabel(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_LABEL}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static getDisplayName(entity) {
    console.log('entity', entity);
    const url = `${endpoints.BASE_URL}${endpoints.DISPLAY_NAME}/${entity}`;

    return axios.get(url).then(response => response.data);
  }

  static createLabel(label) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_LABEL}`;

    const body = {
      label: label
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateLabel(label) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_LABEL}/${label.id}`;

    const body = {
      label: label
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteLabel(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_LABEL}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default LabelService;
