import * as endpoints from './apiEndpoints';
import axios from 'axios';


class ZipService {
  static loadZips() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ZIPS}`;
    
    return axios.get(url).then(response => response.data);
  }

  static getZip(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ZIP}/${id}`;
    
    return axios.get(url).then(response => response.data);
  }

  static createZip(zip) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ZIP}`;
    const body = {
      zip: zip
    };
    
    return axios.post(url, body).then(response => response.data);
  }

  static updateZip(zip) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ZIP}/${zip.id}`;
    const body = {
      zip: zip
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteZip(id) {

    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ZIP}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default ZipService;
