import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const items = (state = initialState.items, action) => {
  switch (action.type) {
    case types.LOAD_ITEM_SUCCESS:
      return action.items;

    case types.CREATE_ITEM_SUCCESS:
      return [...state, Object.assign({}, action.items)];

    case types.UPDATE_ITEM_SUCCESS:
      return [
        ...state.filter(items => items._id !== action.item._id),
        Object.assign({}, action.item)
      ];

    case types.DELETE_ITEM_SUCCESS:
      return [...state.filter(items => items._id !== action.itemId)];

    default:
      return state;
  }
};

export const item = (state = initialState.item, action) => {
  switch (action.type) {
    case types.GET_ITEM_SUCCESS:
      return action.item;

    default:
      return state;
  }
};

export const savingItem = (state = initialState.savingItem, action) => {
  switch (action.type) {
    case types.SAVING_ITEM:
      return action.savingItem;

    default:
      return state;
  }
};

export const itemToDelete = (state = initialState.itemToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_ITEM_ID:
      return action.itemToDelete;

    default:
      return state;
  }
};
