import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as zipActions from "../../actions/zipActions";

import { Row, Col } from "react-bootstrap";

import DynamicForm from "../common/DynamicForm";
import DynamicHeader from "../common/DynamicHeader";
import find from "lodash/find";

class ZipEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false
    };

    autoBind(this);

    props.actions.getZip(props.match.params.id);
  }

  handleCancel() {
    this.props.history.goBack();
  }

  handleSave(zip) {
    let data = {
      id: this.props.zip.id,
      ...zip
    };

    this.props.actions.updateZip(data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing
    });
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>{this.props.zip.zip}</h1>
          </Col>
          <Col md={9}>
            <h3>
              <DynamicHeader entity="Zip" /> Info
            </h3>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-warning btn-sm right"
              onClick={this.handleEdit}
            >
              EDIT
            </button>
          </Col>
        </Row>
        <Row className="mt">
          <Col md={12}>
            <DynamicForm
              fieldData={find(this.props.labels, { entity: "Zip" })}
              onCancel={this.handleCancel}
              onSave={this.handleSave}
              saving={this.props.savingZip}
              entity={"Zip"}
              editing={this.state.editing}
              adding={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ZipEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingZip: PropTypes.bool,
  zip: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array
};

ZipEditPage.contextTypes = {
  router: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingZip: state.reducers.savingZip,
    zip: state.reducers.zip,
    labels: state.reducers.labels.labels
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...zipActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ZipEditPage);
