import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Select } from "formsy-react-components";
import autoBind from "react-autobind";

import groupBy from "lodash/groupBy";

class CodeForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      entities: {},
      fields: [],
      canSubmit: false,
      disabled: true
    };

    autoBind(this);
  }

  componentWillReceiveProps = nextProps => {
    let entities = groupBy(this.props.codes, "entity");
    this.setState({ entities });
  };

  handleSelect = model => {
    let fields = [];
    this.props.codes.map(code => {
      if (code.entity == this.refs.form.getModel().entity) {
        if (fields.indexOf(code.field) === -1) {
          fields.push(code.field);
        }
      }
    });
    this.setState({ fields, disabled: false });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    });
  };

  submit = model => {
    this.props.onSave(model);
    this.refs.form.reset();
  };

  resetForm = () => {
    this.refs.form.reset();
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        onChange={this.handleSelect}
      >
        <Col md={3}>
          <Select
            formNoValidate
            required
            name="entity"
            label="Entity"
            onChange={this.handleSelect}
            options={[
              { value: "", label: "Please select..." },
              ...Object.keys(this.state.entities)
                .sort()
                .map((entity, i) => {
                  return { value: entity, label: entity };
                })
            ]}
            value={this.props.code.entity || ""}
          />
        </Col>
        <Col md={3}>
          <Select
            formNoValidate
            required
            name="field"
            label="Field"
            disabled={this.state.disabled}
            options={[
              { value: "", label: "Please select..." },
              ...this.state.fields.map(field => {
                let formattedField = field.replace(/[_-]/g, " ");
                return { value: field, label: formattedField };
              })
            ]}
            value={this.props.code.field || ""}
          />
        </Col>
        <Col md={3}>
          <Input
            formNoValidate
            required
            name="value"
            label="Value"
            placeholder="Value"
            value={this.props.code.value || ""}
          />
        </Col>
        <Col md={3}>
          <Input
            formNoValidate
            required
            name="label"
            label="Label"
            placeholder="Label"
            value={this.props.code.label || ""}
          />
        </Col>
        <Col md={12}>
          <Link
            style={
              this.props.code._id != 0
                ? { display: "inline" }
                : { display: "none" }
            }
            className="btn btn-danger"
            to="/app/settings/codes"
          >
            Cancel
          </Link>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={this.props.saving ? "Saving... " : "Save"}
          />
        </Col>
      </Formsy>
    );
  }
}

CodeForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  code: PropTypes.object.isRequired,
  codes: PropTypes.array.isRequired
};

export default CodeForm;
