import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Row, Col, Well } from "react-bootstrap";

import find from "lodash/find";

const CallProcedureList = ({ entries, contract, items, editing }) => {
  const columns = [
    {
      Header: "Procedure",
      accessor: "procedure",
      sortable: false,
      Cell: row => {
        if (contract) {
          let procedure = find(contract.lines, { _id: row.value });
          if (procedure) {
            return procedure.item.description;
          }
        }
        return row.value;
      }
    },
    {
      Header: "Duration",
      accessor: "quantity",
      sortable: false
    },
    {
      Header: "UOM",
      accessor: "uom",
      sortable: false
    }
  ];

  let showBillingQuantity = entries.find(
    entry => entry.uom == "MIN" || entry.uom == "MILE"
  );

  let showClients = entries.find(entry => entry.client);

  if (contract) {
    if (showClients) {
      columns.push({
        Header: "Client",
        accessor: "client",
        Cell: row => {
          let client = find(contract.clients, { _id: row.value });
          if (client) {
            return client.name;
          }
          return row.value;
        }
      });
    }
    if (showBillingQuantity) {
      columns.push({
        Header: "Billing Quantity",
        accessor: "billing_quantity"
      });
    }
  }

  let styles = {
    background: editing ? "#fefefe" : "#f2f2f2",
    margin: "10px 0"
  };

  return (
    <Col md={12} xs={12}>
      <Well style={styles}>
        <ReactTable
          resizable={false}
          style={{ color: editing ? "inherit" : "#aaa" }}
          data={entries}
          columns={columns}
          pageSize={entries ? entries.length : 0}
          showPagination={false}
        />
      </Well>
    </Col>
  );
};

export default CallProcedureList;
