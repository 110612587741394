import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as roleActions from "../../actions/roleActions";

import { Row, Col } from "react-bootstrap";
import {} from "../../helpers";
import DynamicForm from "../common/DynamicForm";
import DynamicHeader from "../common/DynamicHeader";
import find from "lodash/find";
import { goBack } from "react-router-redux";

class RoleEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false
    };

    autoBind(this);

    props.actions.getRole(props.match.params.id);
  }

  handleSave(role) {
    let data = {
      id: this.props.role.id,
      ...role
    };

    this.props.actions.updateRole(data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing
    });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>{this.props.role.name}</h1>
          </Col>
          <Col md={9}>
            <h3>
              <DynamicHeader entity="Role" /> Info
            </h3>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-warning btn-sm right"
              onClick={this.handleEdit}
            >
              EDIT
            </button>
          </Col>
        </Row>
        <Row className="mt">
          <Col md={12}>
            <DynamicForm
              fieldData={find(this.props.labels, { entity: "Role" })}
              onCancel={this.handleCancel}
              onSave={this.handleSave}
              saving={this.props.savingRole}
              entity={"Role"}
              editing={this.state.editing}
              adding={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

RoleEditPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingRole: PropTypes.bool,
  role: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array
};

RoleEditPage.contextTypes = {
  router: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    savingRole: state.reducers.savingRole,
    role: state.reducers.role,
    labels: state.reducers.labels.labels
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...roleActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(RoleEditPage);
