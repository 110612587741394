import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const contacts = (state = initialState.contacts, action) => {
  switch (action.type) {
    case types.LOAD_CONTACT_SUCCESS:
      return action.contacts;

    case types.CREATE_CONTACT_SUCCESS:
      return [...state, Object.assign({}, action.contacts)];

    case types.UPDATE_CONTACT_SUCCESS:
      return [
        ...state.filter(contacts => contacts.id !== action.contact.id),
        Object.assign({}, action.contact)
      ];

    case types.DELETE_CONTACT_SUCCESS:
      return [...state.filter(contact => contact.id !== action.contactId)];

    default:
      return state;
  }
};

export const contact = (state = initialState.contact, action) => {
  switch (action.type) {
    case types.GET_CONTACT_SUCCESS:
      return action.contact;

    default:
      return state;
  }
};

export const savingContact = (state = initialState.savingContact, action) => {
  switch (action.type) {
    case types.SAVING_CONTACT:
      return action.savingContact;

    default:
      return state;
  }
};

export const contactToDelete = (
  state = initialState.contactToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_CONTACT_ID:
      return action.contactToDelete;

    default:
      return state;
  }
};
