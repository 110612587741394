import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as invoiceActions from "../../actions/invoiceActions";
import * as userActions from "../../actions/userActions";
import * as divisionActions from "../../actions/divisionActions";
import * as callActions from "../../actions/callActions";
import * as itemActions from "../../actions/itemActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as glaccountActions from "../../actions/glaccountActions";
import * as modalActions from "../../actions/modalActions";
import * as contractActions from "../../actions/contractActions";
import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";
import CallService from "../../services/callService";

import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import InvoiceCallList from "./invoiceLines/InvoiceCallList";
import InvoicePreview from "./invoiceLines/invoicePreview/InvoicePreview";
import FilterComponent from "../common/FilterComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import find from "lodash/find";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import debounce from "lodash/debounce";

const DEFAULT_FILTERS = {
  contract: [],
  parent_account: [],
  division: [],
  product_category: [],
  start_time: moment().startOf("month").subtract(1, "months"),
  end_time: moment(),
};

class InvoiceAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      invoices: [],
      calls: [],
      invoice: {},
      displayDate: Date.now(),
      open: true,
      filters: localStorage.getItem("invoiceAddFilters")
        ? JSON.parse(localStorage.getItem("invoiceAddFilters"))
        : { ...DEFAULT_FILTERS },
      loading: false,
    };
  }

  componentDidMount = () => {
    let {
      accounts,
      contracts,
      items,
      divisions,
      chargetypes,
      glaccounts,
      codes,
      auth,
      actions,
      contacts,
    } = this.props;

    actions.loadUsers(true);

    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(glaccounts)) actions.loadGlaccounts();
    if (isEmpty(contacts)) actions.loadContacts();

    let code = find(codes, { entity: "Call", value: "Approved" });
    let esContract = find(contracts, { name: "My Choice" });

    if (code && esContract) {
      this.fetchCalls(this.state.filters);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.contracts !== this.props.contracts) {
      let code = find(this.props.codes, { entity: "Call", value: "Approved" });
      let esContract = find(nextProps.contracts, { name: "My Choice" });

      if (code) {
        this.fetchCalls(this.state.filters);
      }
    }

    // if (nextProps.calls !== this.props.calls) {
    //   this.setState({ calls: nextProps.calls });
    // }
  };

  componentWillUnmount = () => {
    this.props.actions.clearApprovedCalls();
  };

  fetchCalls = debounce(async (filters) => {
    this.setState({ loading: true });
    const inputs = filters
      ? Object.keys(filters).reduce((acc, curr) => {
          if (Array.isArray(filters[curr])) {
            acc[curr] = filters[curr].map((x) => x.value);
          } else if (curr === "start_time") {
            const d = moment(filters.start_time).format("YYYY-MM-DD", true);
            if (moment(d).isValid()) {
              acc.start_date = d;
            }
          } else if (curr === "end_time") {
            const d = moment(filters.end_time)
              .add(1, "days")
              .format("YYYY-MM-DD", true);
            if (moment(d).isValid()) {
              acc.end_date = d;
            }
          } else {
            acc[curr] = filters[curr];
          }
          return acc;
        }, {})
      : {};
    if (
      !!(
        Array.isArray(inputs.parent_account) && inputs.parent_account.length
      ) ||
      !!(Array.isArray(inputs.contract) && inputs.contract.length) ||
      !!(inputs.start_date && inputs.end_date)
    ) {
      const data = await CallService.findCalls({
        ...inputs,
        auth: this.props.auth.user._id,
      });

      this.setState({
        calls: data,
      });
    } else {
      this.setState({ calls: [] });
    }
    this.setState({ loading: false });
  }, 500);

  handleChangeFilters = (selectedOptions, field) => {
    const filters = { ...this.state.filters };
    filters[field] = selectedOptions;
    this.setState({ filters }, () => {
      localStorage.setItem(
        "invoiceAddFilters",
        JSON.stringify(this.state.filters)
      );
    });
    this.fetchCalls(filters);
  };

  handleChangeDate = (startDate, endDate) => {
    let { filters } = this.state;
    if (startDate) {
      filters.start_time = moment(startDate).endOf("day");
    }
    if (endDate) {
      filters.end_time = moment(endDate).endOf("day");
    }
    this.setState({ filters: { ...filters } }, () => {
      localStorage.setItem(
        "invoiceAddFilters",
        JSON.stringify(this.state.filters)
      );
    });
    this.fetchCalls(filters);
  };

  resetFilters = () => {
    this.setState({
      filters: {
        ...DEFAULT_FILTERS,
      },
    });
    localStorage.removeItem("invoiceAddFilters");
    this.props.actions.clearApprovedCalls();
    this.fetchCalls({ ...DEFAULT_FILTERS });
  };

  selectDisplayDate = (date, value) => {
    this.setState({ displayDate: value });
  };

  handleToggle = (selected) => {
    this.props.actions.toggleCallToInvoice(selected);
  };

  handleToggleAll = (calls) => {
    this.props.actions.toggleAllCallsToInvoice(calls);
  };

  onClickSave = () => {
    let { callsToInvoice, chargetypes } = this.props;
    let { displayDate, calls } = this.state;

    let invoicesArr = [];
    let entries = [];

    // For each call to invoice
    callsToInvoice.map((_id) => {
      // Convert array of ids to call objects
      let call = find(calls, { _id });
      call.entries.map((entry) => {
        let chargetype = find(chargetypes, { _id: entry.charge_type });
        if (
          chargetype.type.includes("Billable") &&
          chargetype.type !== "Non-Billable" &&
          entry.rate !== 0
        ) {
          entries.push({ callId: call._id, ...call, ...entry });
        }
      });
    });

    // Group calls by Contract - Division - (Location)
    let groupedCalls = groupBy(entries, (entry) => {
      let procedure = find(entry.contract.lines, { _id: entry.procedure });
      if (procedure) {
        let division = find(this.props.divisions, { _id: procedure.division });
        let location = find(entry.parent_account.locations, {
          _id: procedure.location,
        });
        let invoiceType = find(this.props.codes, {
          _id: entry.contract.invoice_format,
        });

        // 2 - Separate Invoice per Location
        if (invoiceType.value == "2") {
          return (
            entry.contract.name + "-" + division.code + "-" + location.name
          );
        } else {
          return entry.contract.name + "-" + division.code;
        }
      }
    });

    let invoiceStage = find(this.props.codes, {
      entity: "Invoice",
      label: "Created",
    });
    let callStage = find(this.props.codes, {
      entity: "Call",
      label: "Invoiced",
    });

    /* For each call grouped by [Contract, Division, Location], create a new invoice,
       and update call */
    for (let entries in groupedCalls) {
      let invoice = {
        stage: invoiceStage._id,
        invoiceLines: [],
        id: Object.keys(groupedCalls).indexOf(entries),
      };

      groupedCalls[entries].map((entry) => {
        // Invoice Line Info
        let procedure = find(entry.contract.lines, { _id: entry.procedure });

        if (procedure) {
          let item = find(this.props.items, { _id: procedure.item });
          let division = find(this.props.divisions, {
            _id: procedure.division,
          });
          let department = find(division.departments, {
            _id: procedure.department,
          });

          let client = find(entry.contract.clients, { _id: entry.client });
          let staff = find(this.props.users, { _id: entry.assigned_to._id });
          let glaccount = find(this.props.glaccounts, {
            _id: procedure.gl_rev_code,
          });

          /* -- Account Info -- */
          let account = find(this.props.accounts, {
            _id: entry.parent_account._id,
          });

          let accountCountry = find(this.props.codes, {
            _id: account.locations[0].address.country,
          });

          let accountAddress = {
            ...account.locations[0].address,
            state: account.locations[0].address.state.value,
            country: accountCountry.value,
          };

          let accountSnapshot = {
            name: account.name,
            account_number: account.account_number,
            location: accountAddress,
          };
          /* End Account Info */

          /* -- Contract Info -- */
          let invoice_format = find(this.props.codes, {
            _id: entry.contract.invoice_format,
          });

          // If separate invoice per location, location is location of procedure
          // else use account primary address
          let bill_to_location = find(account.locations, {
            _id: entry.contract.bill_to_location,
          });

          let billState = find(this.props.codes, {
            _id: bill_to_location.address.state,
          });

          let billCountry = find(this.props.codes, {
            _id: bill_to_location.address.country,
          });

          //find billing admin contact from the contract
          let billingContactObject = find(this.props.contacts, {
            _id: entry.contract.billing_admin,
          });

          bill_to_location = {
            ...bill_to_location.address,
            state: billState.value,
            country: billCountry.value,
          };

          let contractSnapshot = {
            number: entry.contract.number,
            name: entry.contract.name,
            invoice_format: invoice_format,
            billing_contact:
              billingContactObject &&
              `${billingContactObject.first_name} ${billingContactObject.last_name}`,
            bill_to_location,
          };

          let location = find(account.locations, { _id: procedure.location });
          let serviceAddress = location ? location.address : {};
          let serviceState = find(this.props.codes, {
            _id: serviceAddress.state,
          });
          let serviceCountry = find(this.props.codes, {
            _id: serviceAddress.country,
          });
          serviceAddress = {
            ...serviceAddress,
            state: serviceState ? serviceState.value : "",
            country: serviceCountry ? serviceCountry.value : "",
          };

          let chosenEntry = find(entry.entries, { _id: entry._id });
          let billQuant;
          if (chosenEntry.uom == "FIXED") {
            billQuant = 1;
          } else {
            billQuant = chosenEntry.billing_quantity;
          }

          let invoiceLineSnapshot = {
            service_date: moment(entry.start_time)
              .startOf("day")
              .format("YYYY-MM-DD"),
            procedure_id: procedure._id,
            procedure_description: item.description,
            billing_quantity:
              billQuant ||
              find(entry.entries, { _id: entry._id }).non_payroll_quantity,
            price: entry.rate,
            product_category: entry.product_category._id,
            cost: entry.uom !== "MILE" ? staff && staff.reg_pay_rate : 0,
            client_name: client ? client.name : "",
            staff: staff ? { name: staff.full_name, _id: staff._id } : "",
            location: location ? location.name : "",
            address: serviceAddress,
            call_id: entry.callId,
            callStage,
            financial_account: {
              segment1: glaccount.code,
              segment2: department.dept_id,
            },
          };

          invoice.document_date = moment(displayDate)
            .startOf("day")
            .format("YYYY-MM-DD");
          invoice.due_date = moment(displayDate)
            .add(30, "days")
            .format("YYYY-MM-DD");
          invoice.format = invoice_format;
          invoice.invoiced_by = this.props.auth.user.id;
          invoice.division = division._id;
          invoice.contract = contractSnapshot;
          invoice.parent_account = accountSnapshot;
          invoice.invoiceLines.push(invoiceLineSnapshot);

          invoice.callsOnInvoice = uniq(
            groupedCalls[entries].map((entry) => entry.callId)
          );

          if (invoice_format.label == "Senior Service") {
            let procedure_type = find(this.props.codes, {
              _id: procedure.procedure_type,
            });
            invoiceLineSnapshot.procedure_type = procedure_type.label;
          }
        }
      });

      console.log("INVOICE", invoice);
      invoicesArr.push(invoice);
    }

    this.setState({ invoices: invoicesArr });
    invoicesArr.map((invoice) => {
      this.setState({ invoice });
      this.props.actions.showModalSuccess("invoiceDetailsModal");
    });
  };

  handleSave = async (save) => {
    let { callsToInvoice } = this.props;
    let { invoices, invoice, calls } = this.state;

    //check for error of "FIXED" oum and billing_quantity other than 1
    let correctedEntries = [];
    let updateBillQuant = false;
    let callList = invoice.callsOnInvoice.map((_id) => {
      console.log({ _id });
      let call = find(calls, { _id });
      console.log("call", call);
      // if (call) {
      call.entries = call.entries.map((entry) => {
        if (entry.uom == "FIXED" && entry.billing_quantity !== 1) {
          let correctedEntry = {
            ...entry,
            billing_quantity: 1,
          };
          updateBillQuant = true;
          return correctedEntry;
        } else {
          return entry;
        }
      });

      call.billing_quantity = call.entries.reduce(
        (tot, call) => tot + call.billing_quantity,
        0
      );

      console.log("updatedCall", call);
      return call || null;
      // }
    });

    if (save) {
      if (updateBillQuant == true) {
        await callList.map((call) => this.props.actions.updateCall(call));
      }

      await this.props.actions.createInvoice(invoice);
      // await this.props.actions.hideModalSuccess();
    }

    let idx = findIndex(invoices, { _id: this.state.invoice._id });
    invoices.splice(idx, 1);

    if (invoices.length) {
      invoices.map((invoice) => {
        this.setState({ invoice });
        invoice.callsOnInvoice.map((id) => this.handleToggle(id));
        this.props.actions.showModalSuccess("invoiceDetailsModal");
      });
    } else {
      // this.resetFilters();
      this.props.actions.hideModalSuccess();
      this.props.actions.clearApprovedCalls();
      this.setState({ selectAll: false });
    }

    this.fetchCalls(this.state.filters);
  };

  onClickNonInvoice = () => {
    this.props.actions.showModalSuccess("callConfirmModal");
  };

  handleConfirmNonInvoice = async () => {
    let nonInvoiceCode = find(this.props.codes, {
      entity: "Call",
      value: "Non-Invoiced",
    });

    if (nonInvoiceCode) {
      await this.props.actions.bulkUpdate(this.props.callsToInvoice, {
        stage: nonInvoiceCode._id,
      });
    }

    await this.resetFilters();
    await this.props.actions.clearApprovedCalls();
  };

  genButtonText = () => {
    let { callsToInvoice } = this.props;
    return callsToInvoice.length > 0
      ? callsToInvoice.length == 1
        ? ` ${callsToInvoice.length} Call`
        : ` ${callsToInvoice.length} Calls`
      : "";
  };

  render() {
    const {
      accounts,
      contracts,
      users,
      items,
      callsToInvoice,
      savingInvoice,
      chargetypes,
      codes,
    } = this.props;

    const { calls } = this.state;

    let invoiceDetails = (
      <InvoicePreview
        invoices={this.state.invoices}
        invoice={this.state.invoice}
        accounts={accounts}
        contracts={contracts}
        users={users}
        codes={codes}
        items={items}
        adding
        saving={savingInvoice}
        onClickConfirm={this.handleSave}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <Col md={6}>
            <h1>Create Invoice</h1>
          </Col>
          <Col md={6} className="text-right">
            <button
              className="btn btn-success filter"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success"
              onClick={this.onClickNonInvoice}
              disabled={callsToInvoice.length <= 0}
            >
              Mark Non-Invoice
            </button>
            <button
              className="btn btn-success"
              onClick={this.onClickSave}
              disabled={callsToInvoice.length <= 0}
            >
              {`INVOICE${this.genButtonText()}`}
            </button>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          entity="Invoice"
          open={this.state.open}
          filters={this.state.filters}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        />
        <Row>
          <Col md={7}>
            <h3>Approved Calls</h3>
          </Col>
          <Col md={5}>
            <Formsy className="invoice-add">
              <Input
                type="date"
                name="date_display"
                label="Display Date"
                value={moment(this.state.displayDate).format("YYYY-MM-DD")}
                onChange={this.selectDisplayDate}
              />
            </Formsy>
          </Col>
          <Col md={12}>
            <InvoiceCallList
              calls={calls}
              accounts={accounts}
              contracts={contracts}
              codes={codes}
              saving={savingInvoice}
              isLoading={this.state.loading}
              chargetypes={chargetypes}
              filters={this.state.filters}
              onToggle={this.handleToggle}
              onToggleAll={this.handleToggleAll}
              onClearCalls={this.props.actions.clearApprovedCalls}
            />
          </Col>
        </Row>
        <Modal
          id="invoiceDetailsModal"
          title="Invoice Preview"
          body={invoiceDetails}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="callConfirmModal"
          title="Non-Invoice Call(s)"
          body={`You are about to mark ${callsToInvoice.length} ${
            callsToInvoice.length == 1 ? "call" : "calls"
          } "Non-Invoice". Are you sure you want to continue?`}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleConfirmNonInvoice}
        />
      </div>
    );
  }
}

InvoiceAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  modal: PropTypes.object,
  savingInvoice: PropTypes.bool,
  saving: PropTypes.bool,
  callsToInvoice: PropTypes.array,
  handleSave: PropTypes.func,
  auth: PropTypes.object,
  invoice: PropTypes.object,
  invoices: PropTypes.array,
  calls: PropTypes.array,
  call: PropTypes.object,
  divisions: PropTypes.array,
  chargetypes: PropTypes.array,
  accounts: PropTypes.array,
  glaccounts: PropTypes.array,
  users: PropTypes.array,
  items: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  adding: PropTypes.bool,
  contacts: PropTypes.array,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    savingInvoice: state.reducers.savingInvoice,
    callsToInvoice: state.reducers.callsToInvoice,
    loadingCall: state.reducers.loadingCall,
    auth: state.reducers.auth,
    users: state.reducers.users,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    chargetypes: state.reducers.chargetypes,
    glaccounts: state.reducers.glaccounts,
    calls: state.reducers.calls,
    call: state.reducers.call,
    contracts: state.reducers.contracts,
    accounts: state.reducers.accounts,
    invoices: state.reducers.invoices,
    items: state.reducers.items,
    contacts: state.reducers.contacts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions,
        ...modalActions,
        ...userActions,
        ...divisionActions,
        ...itemActions,
        ...callActions,
        ...glaccountActions,
        ...chargetypeActions,
        ...contractActions,
        ...accountActions,
        ...contactActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(InvoiceAddPage);
