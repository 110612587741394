import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import maxBy from "lodash/maxBy";
import { isBrowser, isMobile } from "react-device-detect";

export const users = (state = initialState.users, action) => {
  switch (action.type) {
    case types.LOAD_USER_SUCCESS:
      return action.users;

    case types.CREATE_USER_SUCCESS:
      return [...state, Object.assign({}, action.users)];

    case types.UPDATE_USER_SUCCESS:
      return [
        ...state.filter(users => users.id !== action.user.id),
        Object.assign({}, action.user)
      ];

    case types.DELETE_USER_SUCCESS:
      return [...state.filter(user => user.id !== action.userId)];

    default:
      return state;
  }
};

export const user = (state = initialState.user, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return action.user;

    default:
      return state;
  }
};

export const savingUser = (state = initialState.savingUser, action) => {
  switch (action.type) {
    case types.SAVING_USER:
      return action.savingUser;

    default:
      return state;
  }
};

export const loadingUser = (state = initialState.loadingUser, action) => {
  switch (action.type) {
    case types.LOADING_USER:
      return action.loadingUser;

    default:
      return state;
  }
};

export const userToDelete = (state = initialState.userToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_USER_ID:
      return action.userToDelete;

    default:
      return state;
  }
};

export const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        username: action.user.user.email,
        isAuth: true,
        maxRole: maxBy(action.user.user.roles, "number").number,
        roles: action.user.user.roles,
        isAdmin: false,
        user: action.user.user,
        isMobile: isMobile,
        isBrowser: isBrowser
      });

    case types.LOGOUT_USER_SUCCESS:
      return Object.assign({}, state, {
        username: "",
        isAuth: false,
        maxRole: false,
        isAdmin: false,
        roles: false,
        user: {}
      });

    case types.CHECK_AUTH_SUCCESS:
      return Object.assign({}, state, {
        username: action.user.email,
        isAuth: true,
        roles: action.user.roles,
        maxRole: maxBy(action.user.roles, "number").number,
        isAdmin: false,
        isMobile: isMobile,
        isBrowser: isBrowser,
        user: action.user
      });

    case types.RESET_PASS_SENT_SUCCESS:
      return Object.assign({}, state, { resetSent: true });

    case types.RESET_PASS_SUCCESS:
      return Object.assign({}, state, { resetSent: false });

    default:
      return state;
  }
};
