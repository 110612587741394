import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as contractActions from "../../actions/contractActions";
import * as userActions from "../../actions/userActions";
import * as invoiceActions from "../../actions/invoiceActions";
import * as modalActions from "../../actions/modalActions";
import * as accountActions from "../../actions/accountActions";
import * as divisionActions from "../../actions/divisionActions";
import * as itemActions from "../../actions/itemActions";

import InvoiceList from "./InvoiceList";
import InvoicePreview from "./invoiceLines/invoicePreview/InvoicePreview";
import FilterComponent from "../common/FilterComponent";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class InvoicePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      invoice: {},
      open: false,
      editing: true,
      filters: localStorage.getItem("invoiceFilters")
        ? JSON.parse(localStorage.getItem("invoiceFilters"))
        : {
            parent_account: [],
            contract: [],
            number: [],
            stage: [],
            division: [],
            format: []
          }
    };
  }

  componentDidMount = () => {
    let { accounts, contracts, items, divisions, actions } = this.props;

    actions.loadUsers();
    actions.loadInvoices();

    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
  };

  componentWillUnmount = () => {
    this.props.actions.clearInvoices();
  };

  handleChangeFilters = (selectedOptions, field) => {
    this.setState(
      {
        filters: { ...this.state.filters, [field]: selectedOptions }
      },
      () => {
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  };

  handleChangeDate = (startDate, endDate) => {
    let { filters } = this.state;
    if (startDate) {
      filters.start_time = moment.utc(startDate).endOf("day");
      this.setState({ filters: { ...filters } }, () => {
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
    if (endDate) {
      filters.end_time = moment.utc(endDate).endOf("day");
      this.setState({ filters: { ...filters } }, () => {
        localStorage.setItem(
          "invoiceFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
  };

  handleToggle = selected => {
    this.props.actions.toggleInvoicePrint(selected);
  };

  handleToggleAll = invoices => {
    this.props.actions.toggleInvoices(invoices);
  };

  resetFilters = () => {
    this.setState({
      filters: {
        parent_account: [],
        contract: [],
        number: [],
        stage: [],
        division: [],
        format: [],
        start_time: null,
        end_time: null
      }
    });
    localStorage.removeItem("invoiceFilters");
  };

  onClickExport = invoiceNum => {
    if (process.env.NODE_ENV == "development") {
      this.props.actions.getInvoice(invoiceNum, true);
    } else {
      let url = `${REPORT_PATH}&InvoiceNo=${invoiceNum}`;
      window.open(url, "_blank");
    }
  };

  onClickVoid = invoiceId => {
    this.props.actions.requestInvoiceId(invoiceId);
  };

  handleVoid = () => {
    let voidStage = find(this.props.codes, {
      entity: "Invoice",
      value: "Void"
    });

    let invoice = find(this.props.invoices, { _id: this.props.invoiceToVoid });
    invoice["stage"] = voidStage._id;

    this.props.actions.updateInvoice(invoice);
  };

  bulkPrint = () => {
    let params = this.props.invoicesToPrint
      .map(number => `&InvoiceNo=${number}`)
      .join("");
    let url = `${REPORT_PATH}${params}`;
    window.open(url, "_blank");
  };

  genButtonText = () => {
    return this.props.invoicesToPrint.length > 0
      ? this.props.invoicesToPrint.length == 1
        ? `Print ${this.props.invoicesToPrint.length} Invoice`
        : `Print ${this.props.invoicesToPrint.length} Invoices`
      : "Print";
  };

  render() {
    const { invoices, invoice, invoicesToPrint, contracts } = this.props;
    const { filters, open } = this.state;

    let invoiceDetails = (
      <InvoicePreview invoice={invoice} invoices={invoices} {...this.props} />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Invoices" }]} />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success filter"
              type="button"
              onClick={() => this.setState({ open: !open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success"
              type="button"
              disabled={invoicesToPrint.length <= 0}
              onClick={this.bulkPrint}
            >
              {this.genButtonText()}
            </button>
            <Link className="btn btn-success btn-add" to="/app/invoices/add">
              <FontAwesomeIcon icon={faPlus} />
              ADD
            </Link>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          entity="Invoice"
          open={open}
          filters={filters}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          onChangeDateMobile={this.handleChangeDateMobile}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
        />
        <Row>
          <Col md={12}>
            <InvoiceList
              invoices={invoices}
              contracts={contracts}
              filters={filters}
              onClearInvoices={this.props.actions.clearInvoices}
              onClickExport={this.onClickExport}
              onClickVoid={this.onClickVoid}
              onToggle={this.handleToggle}
              onToggleAll={this.handleToggleAll}
            />
          </Col>
        </Row>
        <Modal
          id="invoiceDetailsModal"
          title="Invoice Preview"
          body={invoiceDetails}
          invoice={invoice}
          modal={this.props.modal}
          onClickExport={this.handleInvoiceExport}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="invoiceDeleteModal"
          title="Void Invoice"
          body="Are you sure you want to void this invoice?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleVoid}
        />
      </div>
    );
  }
}

InvoicePage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  accounts: PropTypes.array,
  modal: PropTypes.object,
  invoiceToVoid: PropTypes.string,
  invoiceToPrint: PropTypes.string,
  invoices: PropTypes.array.isRequired,
  invoice: PropTypes.object.isRequired,
  divisions: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  users: PropTypes.array,
  items: PropTypes.items
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    invoiceToVoid: state.reducers.invoiceToVoid,
    invoicesToPrint: state.reducers.invoicesToPrint,
    invoices: state.reducers.invoices,
    invoice: state.reducers.invoice,
    contracts: state.reducers.contracts,
    accounts: state.reducers.accounts,
    users: state.reducers.users,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions,
    items: state.reducers.items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions,
        ...modalActions,
        ...accountActions,
        ...divisionActions,
        ...contractActions,
        ...userActions,
        ...itemActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(InvoicePage);
