import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Input, Select } from "formsy-react-components";
import autoBind from "react-autobind";

class GlaccountForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false
    });
  }

  submit(model) {
    this.props.onSave(model);
    this.refs.form.reset();
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Col md={4}>
          <Input
            formNoValidate
            required
            name="description"
            label="Description"
            placeholder="Description"
            value={this.props.glaccount.description || ""}
          />
        </Col>
        <Col md={4}>
          <Input
            formNoValidate
            required
            name="code"
            label="Code"
            placeholder="Revenue Code"
            value={this.props.glaccount.code || ""}
          />
        </Col>
        <Col md={4}>
          <Select
            required
            name="type"
            label="Type"
            options={[
              { value: "", label: "Please select..." },
              { value: "Revenue", label: "Revenue" },
              { value: "Expense", label: "Expense" }
            ]}
            value={this.props.glaccount.type || ""}
          />
        </Col>
        <Col md={12}>
          <Link
            style={
              this.props.glaccount.id != 0
                ? { display: "inline" }
                : { display: "none" }
            }
            className="btn btn-danger"
            to="/app/settings/glaccounts"
          >
            Cancel
          </Link>
          <button
            className="btn btn-warning"
            type="button"
            onClick={this.resetForm}
          >
            Reset
          </button>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={this.props.saving ? "Saving... " : "Save"}
          />
        </Col>
      </Formsy>
    );
  }
}

GlaccountForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  glaccount: PropTypes.object.isRequired
};

export default GlaccountForm;
