import React from 'react';
import PropTypes from 'prop-types';
import Company from './Company';
import CompanyForm from './CompanyForm';
import Location from './Location';
import { Row, Col, Image } from 'react-bootstrap';
import * as companyActions from '../../actions/companyActions';


const CompanyLocationList = ({ companies, handleAdd, adding }) => {
  let locationsView = (
    <p>Sorry, there are no locations to show. You can try to add one.</p>
  );

  if (companies.length > 0) {
    if (companies[0].locations.length > 0) {
      locationsView = companies[0].locations.map((location, i) => {
        return (
          <Col key={i} md={4} className="location">
            <Location location={location} />
          </Col>
        );
      });
    }
  }

  return <div>{locationsView}</div>;
};

CompanyLocationList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  handleSave: PropTypes.func,
  handleAdd: PropTypes.func,
  adding: PropTypes.bool
};

export default CompanyLocationList;
