import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";

import AccountForm from "./AccountForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import { Row, Col } from "react-bootstrap";
import Loading from "../common/Loading";

import isEmpty from "lodash/isEmpty";

class AccountEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { editing: false };
  }

  componentDidMount = () => {
    let { actions } = this.props;
    actions.getAccount(this.props.match.params.id);
  };

  handleSave = account => {
    account.id = this.props.account._id;
    this.props.actions.updateAccount(account);
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleCancel = () => {
    this.props.history.push("/app/accounts");
  };

  render() {
    let { account, codes, auth, saving } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Accounts" }, { label: `${account.name}` }]}
          />
          <EditButton onClickEdit={this.handleEdit} auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {!isEmpty(account) ? (
              <AccountForm
                account={account}
                codes={codes}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
                saving={saving}
                editing={this.state.editing}
              />
            ) : (
              <Loading active />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

AccountEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingAccount: PropTypes.bool,
  account: PropTypes.object,
  codes: PropTypes.array
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    saving: state.reducers.savingAccount,
    account: state.reducers.account,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...accountActions, ...contactActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AccountEditPage);
