import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const zips = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ZIP_SUCCESS:
      return Object.assign({}, state, {
        zips: action.zips
      });

    case types.CREATE_ZIP_SUCCESS:
      return Object.assign({}, state, {
        zips: [
          ...state.zips,
          Object.assign({}, action.zip)
        ].sort((a, b) => {
          return a.id - b.id; // Sort by id alphabetically.
        })
      });

    case types.UPDATE_ZIP_SUCCESS:
      return Object.assign({}, state, {
        zips: [
          ...state.zips.filter(zip => zip.id !== action.zip.id),
          Object.assign({}, action.zip)
        ].sort((a, b) => {
          return a.id - b.id; // Sort by id alphabetically.
        })
      });

    case types.DELETE_ZIP_SUCCESS:
      return Object.assign({}, state, {
        zips: [
          ...state.zips.filter(zip => zip.id !== action.zipId)
        ]
      });

    default:
      return state;
  }
};

export const zip = (state = initialState.zip, action) => {
  switch (action.type) {
    case types.GET_ZIP_SUCCESS:
      return action.zip;

    default:
      return state;
  }
};

export const savingZip = (state = initialState.savingZip, action) => {
  switch (action.type) {
    case types.SAVING_ZIP:
      return action.savingZip;

    default:
      return state;
  }
};

export const zipToDelete = (state = initialState.zipToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_ZIP_ID:
      return action.zipToDelete;

    default:
      return state;
  }
};

