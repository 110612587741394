import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { Textarea } from "formsy-react-components";
import autoBind from "react-autobind";

class AccountNoteForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  submit = model => {
    this.props.onSave(this.props.account, model);
  };

  render() {
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={12}>
            <Textarea
              formNoValidate
              required
              name="note"
              label="Note"
              value={""}
            />
          </Col>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

AccountNoteForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  saving: PropTypes.bool.isRequired,
  location: PropTypes.object
};

AccountNoteForm.defaultProps = {
  note: {
    note: ""
  }
};

export default AccountNoteForm;
