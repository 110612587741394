import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const labels = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_LABEL_SUCCESS:
      return Object.assign({}, state, {
        labels: action.labels
      });

    case types.CREATE_LABEL_SUCCESS:
      return Object.assign({}, state, {
        labels: [...state.labels, Object.assign({}, action.label)].sort(
          (a, b) => {
            return a.id - b.id; // Sort by id alphabetically.
          }
        )
      });

    case types.UPDATE_LABEL_SUCCESS:
      return Object.assign({}, state, {
        labels: [
          ...state.labels.filter(label => label.id !== action.label.id),
          Object.assign({}, action.label)
        ].sort((a, b) => {
          return a.id - b.id; // Sort by id alphabetically.
        })
      });

    case types.DELETE_LABEL_SUCCESS:
      return Object.assign({}, state, {
        labels: [...state.labels.filter(label => label.id !== action.labelId)]
      });

    default:
      return state;
  }
};

export const label = (state = initialState.label, action) => {
  switch (action.type) {
    case types.GET_LABEL_SUCCESS:
      return action.label;

    default:
      return state;
  }
};

export const savingLabel = (state = initialState.savingLabel, action) => {
  switch (action.type) {
    case types.SAVING_LABEL:
      return action.savingLabel;

    default:
      return state;
  }
};

export const labelToDelete = (state = initialState.labelToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_LABEL_ID:
      return action.labelToDelete;

    default:
      return state;
  }
};
