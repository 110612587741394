import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as zipActions from "../../actions/zipActions";

import ZipForm from "./ZipForm";

class ZipAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(zip) {
    this.props.actions.createZip(zip);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Zip</h1>
        <ZipForm
          onSave={this.handleSave}
          saving={this.props.savingZip}
          zip={this.props.zip}
        />
      </div>
    );
  }
}

ZipAddPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingZip: PropTypes.bool,
  zip: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  let zip = {
    id: 0,
    name: ""
  };

  return {
    state: state.reducers,

    savingZip: state.reducers.savingZip,
    zip: zip
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...zipActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ZipAddPage);
