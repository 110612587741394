import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as callActions from "../../actions/callActions";
import * as modalActions from "../../actions/modalActions";
import * as contractActions from "../../actions/contractActions";
import * as userActions from "../../actions/userActions";
import * as accountActions from "../../actions/accountActions";
import * as divisionActions from "../../actions/divisionActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as itemActions from "../../actions/itemActions";

import CallForm from "./CallForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Loading from "../common/Loading";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class CallEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false,
      deleting: false,
      readOnly: false,
      call: {}
    };
  }

  componentDidMount = () => {
    let {
      accounts,
      contracts,
      items,
      divisions,
      chargetypes,
      actions
    } = this.props;

    actions.getCall(this.props.match.params.id);
    actions.loadUsers(true);

    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
  };

  componentWillReceiveProps = nextProps => {
    let { auth } = this.props;
    if (nextProps.call !== this.props.call) {
      this.setState({
        readOnly:
          (auth.maxRole == 4000 && nextProps.call.completed_by) ||
          (auth.maxRole > 4000 &&
            auth.maxRole <= 8000 &&
            nextProps.call.approved_by) ||
          nextProps.call.invoiced_by
            ? true
            : false
      });
    }
  };

  handleCancel = () => {
    let { router } = this.props;

    this.props.history.push(
      router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : router.previousLocation == "/app/invoices/add"
        ? "/app/invoices/add"
        : `/app/calls`
    );
  };

  onClickDelete = () => {
    this.props.actions.requestCallId(this.props.call._id);
    this.setState({ deleting: true });
  };

  handleDelete = async () => {
    await this.props.actions.deleteCall(this.props.call._id);
    await this.props.history.push(
      this.props.router.previousLocation == "/app/call-approval"
        ? "/app/call-approval"
        : `/app/calls`
    );
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  onConfirmChange = () => {
    this.props.actions.showModalSuccess("callConfirmModal");
  };

  handleEnterTime = () => {
    this.props.actions.showModalSuccess("callDetailsModal");
  };

  handleSave = call => {
    let { auth } = this.props;
    call = call || this.state.model;

    let data = {
      _id: this.props.call._id,
      ...call
    };

    if (data.completed_by) {
      data["completed_date"] = new Date();
    }

    this.setState({
      editing: false,
      readOnly:
        (auth.maxRole == 4000 && call.completed_by) ||
        (auth.maxRole > 4000 && auth.maxRole <= 8000 && call.approved_by) ||
        call.invoiced_by
          ? true
          : false
    });
    this.props.actions.updateCall(data);
  };

  onClickSave = call => {
    let stage = find(this.props.codes, {
      _id: call.stage._id ? call.stage._id : call.stage
    });
    if (stage.label == "Completed") {
      this.setState({ model: call, deleting: false });
      this.props.actions.requestCallId(this.props.call._id);
    } else {
      this.handleSave(call);
    }
  };

  render() {
    let { deleting, readOnly } = this.state;
    let {
      call,
      loadingCall,
      contracts,
      users,
      accounts,
      divisions,
      chargetypes,
      codes,
      items,
      auth,
      isMobile
    } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[
              { label: "Time Entries" },
              { label: "Assignment Info" }
            ]}
          />
          <EditButton onClickEdit={this.handleEdit} disabled={readOnly} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {!loadingCall ? (
              <CallForm
                call={call}
                contracts={contracts}
                users={users}
                auth={auth}
                accounts={accounts}
                items={items}
                divisions={divisions}
                chargetypes={chargetypes}
                codes={codes}
                isMobile={isMobile}
                onSave={this.onClickSave}
                onDelete={this.onClickDelete}
                onCancel={this.handleCancel}
                saving={this.props.savingCall}
                editing={this.state.editing}
                onEnterTime={this.handleEnterTime}
                onConfirmChange={this.onConfirmChange}
                readOnly={readOnly}
              />
            ) : (
              <Loading active />
            )}
            <ConfirmModal
              id="callDeleteModal"
              title={!deleting ? "Save Call" : "Delete Call"}
              body={
                !deleting
                  ? "Are you sure you want to save this call? Once it is completed you will not be able to make edits."
                  : "Are you sure you want to delete this call?"
              }
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={!deleting ? this.handleSave : this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CallEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingCall: PropTypes.bool,
  call: PropTypes.object,
  calls: PropTypes.array,
  accounts: PropTypes.array,
  users: PropTypes.array,
  items: PropTypes.array,
  auth: PropTypes.object,
  codes: PropTypes.array,
  divisions: PropTypes.array,
  contracts: PropTypes.array,
  chargetypes: PropTypes.array,
  params: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    savingCall: state.reducers.savingCall,
    loadingCall: state.reducers.loadingCall,
    call: state.reducers.call,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    accounts: state.reducers.accounts,
    divisions: state.reducers.divisions,
    users: state.reducers.users,
    items: state.reducers.items,
    auth: state.reducers.auth,
    router: state.reducers.router,
    contracts: state.reducers.contracts,
    chargetypes: state.reducers.chargetypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...modalActions,
        ...contractActions,
        ...userActions,
        ...itemActions,
        ...accountActions,
        ...divisionActions,
        ...chargetypeActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CallEditPage);
