import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";

class MaintenancePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { showLogo: true };
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ showLogo: !this.state.showLogo }), 2000);
  };

  render = () => {
    let { showLogo } = this.state;
    return (
      <Row className="auth-page maintenance">
        <Col md={6} xs={12} className="center">
          {showLogo && (
            <img
              src={LOGO_PATH}
              className={`animated ${showLogo == false ? "fadeOut" : "fadeIn"}`}
            />
          )}
          {!showLogo && (
            <div className="animated fadeIn slow">
              <h1>Sorry we're down for maintenance</h1>
              <p> We'll be back up shortly</p>
            </div>
          )}
        </Col>
      </Row>
    );
  };
}

export default MaintenancePage;
