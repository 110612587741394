import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DropdownButton, MenuItem } from "react-bootstrap";

import find from "lodash/find";
import matchSorter from "match-sorter";
import moment from "moment";

const ContractLineList = ({
  lines,
  calls,
  onClickEdit,
  onClickCopy,
  onClickDelete,
}) => {
  const columns = [
    {
      Header: "Division",
      accessor: "division",
      maxWidth: 100,
      Cell: (row) => (row.value ? row.value.code : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: [(row) => row.division.code] }),
      filterAll: true,
    },
    {
      Header: "Department",
      accessor: "department",
      maxWidth: 300,
      Cell: (row) => {
        let department = find(row.original.division.departments, {
          _id: row.value,
        });
        return <span>{department.description}</span>;
      },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            (row) => {
              let department = find(row.division.departments, {
                _id: row.department,
              });
              return department.description;
            },
          ],
        }),
      filterAll: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("DD MMM YYYY")],
        }),
      filterAll: true,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.end_date).format("DD MMM YYYY")],
        }),
      filterAll: true,
    },
    {
      Header: "Item",
      accessor: "item",
      Cell: (row) => (row.value ? row.value.description : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.item.description],
        }),
      filterAll: true,
    },
    {
      Header: "Product Category",
      accessor: "product_category",
      Cell: (row) => (row.value ? row.value.label : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row.product_category.label],
        }),
      filterAll: true,
    },
    {
      Header: "Rate",
      accessor: "rate",
      Cell: (row) => (
        <span>{`$${row.value ? row.value.toFixed(2) : (0).toFixed(2)}`}</span>
      ),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["rate"] }),
      filterAll: true,
    },
    {
      Header: "UOM",
      accessor: "uom",
      Cell: (row) => (row.value ? row.value.label : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["uom.label"] }),
      filterAll: true,
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let hasCalls = false;

        calls.map((call) => {
          if (find(call.entries, { procedure: row.value })) {
            hasCalls = true;
          }
        });

        return (
          <div className="text-center actions">
            <DropdownButton title="Actions" id={"dropdown"}>
              <MenuItem onClick={() => onClickEdit(row.value)}>
                <FontAwesomeIcon icon={faFolder} />
                View
              </MenuItem>
              <MenuItem onClick={() => onClickCopy(row.value)}>
                <FontAwesomeIcon icon={faCopy} />
                Duplicate
              </MenuItem>
              <MenuItem
                data-tooltip-content="There are calls associated with this procedure"
                data-tooltip-id="hasCalls"
                onClick={() => (!hasCalls ? onClickDelete(row.value) : "")}
                disabled={hasCalls}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                Delete
              </MenuItem>
              {hasCalls && (
                <ReactTooltip
                  id="hasCalls"
                  place="left"
                  type="dark"
                  effect="solid"
                />
              )}
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (rowInfo && column.id !== "_id") {
            onClickEdit(rowInfo.original._id);
          }
        },
      };
    },
  };

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={lines}
      columns={columns}
      defaultPageSize={10}
      {...props}
      defaultSorted={[
        {
          id: "division",
          asc: true,
        },
      ]}
    />
  );
};

ContractLineList.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
    }).isRequired
  ),
  contractId: PropTypes.string,
  onClickCopy: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default ContractLineList;
