import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const companies = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        companies: action.companies
      });

    case types.CREATE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        companies: [
          ...state.companies,
          Object.assign({}, action.company)
        ].sort((a, b) => {
          return a.id - b.id; // Sort by id alphabetically.
        })
      });

    case types.UPDATE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        companies: [
          ...state.companies.filter(company => company.id !== action.company.id),
          Object.assign({}, action.company)
        ].sort((a, b) => {
          return a.id - b.id; // Sort by id alphabetically.
        })
      });

    case types.DELETE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        companies: [
          ...state.companies.filter(company => company.id !== action.companyId)
        ]
      });

    default:
      return state;
  }
};

export const company = (state = initialState.company, action) => {
  switch (action.type) {
    case types.GET_COMPANY_SUCCESS:
      return action.company;

    default:
      return state;
  }
};

export const savingCompany = (state = initialState.savingCompany, action) => {
  switch (action.type) {
    case types.SAVING_COMPANY:
      return action.savingCompany;

    default:
      return state;
  }
};

export const companyToDelete = (state = initialState.companyToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_COMPANY_ID:
      return action.companyToDelete;

    default:
      return state;
  }
};

