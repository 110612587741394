import React from "react";
import PropTypes from "prop-types";
import Company from "./Company";
import CompanyForm from "./CompanyForm";
import Location from "./Location";
import { Row, Col, Image } from "react-bootstrap";
import * as companyActions from "../../actions/companyActions";

const CompanyList = ({ companies }) => {
  let companiesView = (
    <p>Sorry, there are no companies to show. You can try to add one.</p>
  );

  if (companies.length > 0) {
    companiesView = (
      <div className="company-info">
        <Col md={12} className="info">
          <Col md={5}>
            <p>Name:</p>
            {companies[0].name}
          </Col>
          <Col md={5}>
            <p>Website:</p>
            {companies[0].website}
          </Col>
        </Col>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col md={12}>{companiesView}</Col>
      </Row>
    </div>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  handleSave: PropTypes.func
};

export default CompanyList;
