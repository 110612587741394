import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import GpList from "./GpList";
import BreadCrumbs from "../common/BreadCrumbs";
import isEmpty from "lodash/isEmpty";
import * as codeActions from "../../actions/codeActions";
import * as invoiceActions from "../../actions/invoiceActions";

class GpPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);

    this.state = {
      invoice: false,
      selectAll: false,
      gpInvoices: []
    };

    props.actions.loadInvoices();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invoicesToComplete !== nextProps.invoicesToComplete) {
      let gpInvoices = nextProps.invoicesToComplete;
      this.setState({ gpInvoices });
    }
  }

  genButtonText() {
    return this.state.gpInvoices.length > 0
      ? this.state.gpInvoices.length == 1
        ? ` ${this.state.gpInvoices.length} Invoice`
        : ` ${this.state.gpInvoices.length} Invoices`
      : "";
  }

  handleToggle(selected) {
    this.props.actions.toggleGpInvoice(selected);
  }

  handleToggleAll(invoices) {
    this.props.actions.toggleAllGpInvoices(invoices);
    this.setState(state => ({ selectAll: !state.selectAll }));
  }

  async sendInvoices() {
    let { auth, actions, codes } = this.props;
    if (!isEmpty(this.state.gpInvoices)) {
      const stage = codes.filter(
        code => code.entity == "Invoice" && code.value === "SFI"
      );
      const gpInvoices = this.state.gpInvoices;
      await actions.bulkUpdateInvoices(gpInvoices, auth.user.id, stage[0].id);
      await actions.clearInvoices(gpInvoices);
      await actions.loadInvoices();
    } else {
      console.log("Invoices is Empty");
    }
  }

  render() {
    const { invoices, codes, auth, invoicesToComplete, selectAll } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Great Plains" }]}
          />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success btn-sm btn-add"
              type="button"
              disabled={this.props.invoicesToComplete.length <= 0}
              onClick={this.sendInvoices}
            >
              {`Send to GP ${this.genButtonText()}`}
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <GpList
              invoices={invoices}
              codes={codes}
              auth={auth}
              invoicesToComplete={invoicesToComplete}
              onToggle={this.handleToggle}
              toggleAll={this.handleToggleAll}
              selectAll={selectAll}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

GpPage.propTypes = {
  actions: PropTypes.object,
  codes: PropTypes.array,
  invoices: PropTypes.array,
  invoice: PropTypes.object,
  invoicesToComplete: PropTypes.array,
  auth: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    codes: state.reducers.codes,
    invoices: state.reducers.invoices,
    invoice: state.reducers.invoice,
    invoicesToComplete: state.reducers.invoicesToComplete
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions
      },
      dispatch
    )
  };
}

GpPage.defaultProps = {
  invoice: {
    start_time: "",
    end_time: ""
  }
};

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(GpPage);
