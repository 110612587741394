import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Role from "./Role";

import Loadable from "react-loading-overlay";
import ReactTable from "react-table";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const RoleList = ({ roles, onClickEdit, onClickDelete }) => {
  let rolesView = (
    <Loadable
      active={true}
      background="none"
      className="vertical-center"
      spinner
      
      color="#cf4b04"
    />
  );

  const columns = [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Number",
      accessor: "number"
    },
    {
      Header: "",
      accessor: "_id",
      Cell: row => (
        <div className="text-center actions">
          <DropdownButton title="Actions" id={"dropdown"}>
            <LinkContainer to={`/app/settings/roles/${row.value}`}>
              <MenuItem>
                <FontAwesomeIcon icon={faFolder} />View
              </MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => onClickDelete(row.value)}>
              <FontAwesomeIcon icon={faTrashAlt} />Delete
            </MenuItem>
          </DropdownButton>
        </div>
      )
    }
  ];

  if (roles.length > 0) {
    rolesView = (
      <ReactTable
        className="-highlight"
        data={roles}
        loading={false}
        columns={columns}
        defaultPageSize={roles.length}
        showPagination={false}
        defaultSorted={[
          {
            id: "name",
            asc: true
          }
        ]}
      />
    );
  }
  return <div>{rolesView}</div>;
};

RoleList.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default RoleList;
