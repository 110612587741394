import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../actions/modalActions";

import CallProcedureForm from "./CallProcedureForm";
import CallProcedureList from "./CallProcedureList";
import DateTimePicker from "../common/DateTimePicker";
import DropdownSearch from "../common/DropdownSearch";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import Formsy from "formsy-react";
import { Input, Textarea } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

import moment from "moment";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

class CallForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      overflow: false,
      entries: [],
      disableParentSelect: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.adding) {
      this.checkValidDate(this.props.call.start_time, this.props.call.end_time);
      //set contractStart and contractEnd for validation
      if (this.props.call.contract) {
        this.setState({
          contractStart: moment(this.props.call.contract.start_date).add(
            4,
            "hours"
          ),
          contractEnd: moment(this.props.call.contract.end_date).add(
            28,
            "hours"
          ),
        });
      }

      this.setState({
        billing_quantity: this.props.call.billing_quantity,
        quantity: this.props.call.quantity,
        entries: this.props.call.entries,
        division: this.props.call.division,
        product_category: find(this.props.codes, {
          _id: this.props.call.product_category,
        }),
      });
    } else {
      let stage = find(this.props.codes, { label: "Scheduled" });
      this.setState({ stage: stage, division: this.props.auth.user.division });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    // If EDITING, populate default values
    if (
      !nextProps.adding &&
      (nextProps.call !== this.props.call ||
        nextProps.codes !== this.props.codes)
    ) {
      this.checkValidDate(nextProps.start_time, nextProps.end_time);
      this.setState({
        stage: find(this.props.codes, { _id: nextProps.call.stage._id }),
        billing_quantity: nextProps.call.billing_quantity,
        quantity: nextProps.call.quantity,
        division: nextProps.call.division,
        entries: nextProps.call.entries,
        product_category: find(this.props.codes, {
          _id: nextProps.call.product_category,
        }),
      });
    }

    // If ADDING, default stage to scheduled and division to the user's division
    if (nextProps.adding && nextProps.codes !== this.props.codes) {
      let stage = find(nextProps.codes, { label: "Scheduled" });
      this.setState({ stage: stage, division: this.props.auth.user.division });
    }
  };

  checkValidDate = (start, end) => {
    if (start && end) {
      let isValidDate = moment(start).isBefore(end) ? true : false;
      this.setState({ isValidDate });
    }
  };

  // checkValidStartDate = call => {
  //   let startTime;
  //   let contractStart;
  //   let contractEnd;
  //   if (call.contract) {
  //     startTime = this.state.start_time || call.start_time;
  //     contractStart =
  //       this.state.contractStart ||
  //       moment(call.contract.start_date).add(4, "hours");
  //     contractEnd =
  //       this.state.contractEnd ||
  //       moment(call.contract.end_date).add(28, "hours");
  //     let isValidStartDate =
  //       startTime &&
  //       moment(startTime).isSameOrAfter(contractStart) &&
  //       moment(startTime).isBefore(contractEnd)
  //         ? true
  //         : false;
  //     this.setState({ isValidStartDate });
  //   }
  // };

  add = (model) => {
    let assignedTo = find(this.props.users, { id: model.assigned_to });

    model.entries = this.state.entries || this.props.call.entries;
    model.cost = assignedTo.reg_pay_rate;
    model.start_time = this.state.start_time;
    model.end_time = this.state.end_time;
    model.tif_in = this.state.tif_in || this.props.call.tif_in;
    model.tif_out = this.state.tif_out || this.props.call.tif_out;
    model.billing_quantity = this.state.billing_quantity;

    this.props.onAdd(model);
  };

  submit = (model) => {
    let assignedTo = find(this.props.users, { id: model.assigned_to });

    model.entries = this.state.entries.length
      ? this.state.entries
      : this.props.call.entries;
    model.cost = assignedTo.reg_pay_rate;
    model.start_time = this.state.start_time || this.props.call.start_time;
    model.end_time = this.state.end_time || this.props.call.end_time;
    model.tif_in = this.state.tif_in || this.props.call.tif_in;
    model.tif_out = this.state.tif_out || this.props.call.tif_out;
    model.billing_quantity = this.state.billing_quantity;

    this.props.onSave(model);
  };

  cancel = () => {
    this.props.onCancel();
  };

  delete = () => {
    this.props.onDelete();
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (field, selected) => {
    this.setState({ [field]: selected.value });

    if (
      field == "parent_account" &&
      (this.state.contract || this.props.call.contract)
    ) {
      this.setState(
        {
          contract: null,
          entries: [],
          division: null,
          product_category: null,
          quantity: "",
        },
        () => console.log(this.state)
      );
    }

    //find and set contract, then use it's start and end dates in state to validate entered times
    if (field == "contract") {
      let contract = find(this.props.contracts, { _id: selected.value });
      let invoiceFormat = find(this.props.codes, {
        _id: contract.invoice_format._id,
      });
      this.setState({
        contract,
        invoiceFormat: invoiceFormat.value,
        disableParentSelect: true,
        contractStart: moment(contract.start_date).add(4, "hours"),
        contractEnd: moment(contract.end_date).add(28, "hours"),
      });
    }

    if (field == "stage") {
      let stage = find(this.props.codes, { _id: selected.value });
      this.setState({ stage });
    }

    if (field == "assigned_to") {
      let employee = find(this.props.users, { id: selected.value });
      let cost = employee.reg_pay_rate;
      this.setState({ cost });
    }

    if (field == "product_category") {
      let product_category = find(this.props.codes, { _id: selected.value });
      this.setState({ product_category });
    }
  };

  selectDate = (date, name) => {
    let { call } = this.props;
    let quantity = this.state.quantity || call.quantity;

    // Don't allow user to change end date if quantity is already defined
    if (quantity && name == "end_time") {
      return;
    }

    this.setState({ [name]: date }, () => {
      let start = this.state.start_time || call.start_time;
      let end = this.state.end_time || call.end_time;
      let quantity = this.state.quantity || call.quantity;
      let entries = call.entries || this.state.entries;
      let contract = this.state.contract || call.contract;

      if (start && quantity) {
        this.setState({ end_time: moment(start).add(quantity, "hours") });
      }

      if (start && name == "start_time" && contract && contract.is_tif) {
        this.setState({ tif_in: start });
      }

      if (end && name == "end_time" && contract && contract.is_tif) {
        this.setState({ tif_out: end });
      }

      // Handle invalid procedures based on end date
      if (contract) {
        let isBetween = true;
        let format = (date) => moment(date).startOf("day").format("YYYY-MM-DD");

        entries.map((entry) => {
          let procedure = find(contract.lines, { _id: entry.procedure });
          let callStart = format(start);
          let lineStart = format(procedure.start_date);
          let lineEnd = moment(procedure.end_date)
            .endOf("day")
            .format("YYYY-MM-DD");

          // [] indicates inclusion of value
          if (!moment(callStart).isBetween(lineStart, lineEnd, null, [])) {
            isBetween = false;
          }
        });

        if (!isBetween) {
          this.props.onConfirmChange();
        }
      }

      // Validate that end date is within 24hrs of start date
      if (start && end) {
        let duration = moment.duration(moment(end).diff(start));
        let hours = duration && duration.asHours();
        let isValidDate =
          moment(end).isAfter(start) &&
          moment(start).isBefore(end) &&
          hours < 24
            ? true
            : false;

        this.setState({ isValidDate });
      }
    });
  };

  clearEntries = () => {
    let { call } = this.props;
    let start = this.state.start_time || call.start_time;
    let entries = call.entries || this.state.entries;
    let contract = this.state.contract || call.contract;

    let filteredEntries = entries.filter((entry) => {
      let procedure = find(contract.lines, { _id: entry.procedure });
      let format = (date) => moment(date).startOf("day").format("YYYY-MM-DD");
      let callStart = format(start);
      let lineStart = format(procedure.start_date);
      let lineEnd = format(procedure.end_date);

      // [] indicates inclusion of value
      return moment(callStart).isBetween(lineStart, lineEnd, null, []);
    });

    this.setState({
      entries: filteredEntries,
      quantity:
        filteredEntries.reduce((total, e) => total + e.quantity, 0) || "",
    });
  };

  cancelClearEntries = () => {
    let { call } = this.props;
    this.setState({ start_time: call.start_time, end_time: call.end_time });
    this.props.actions.hideModal();
  };

  getProdCatOptions = () => {
    let prodCatOptions = [];
    if (!isEmpty(this.props.contracts)) {
      let contract = this.state.contract || this.props.call.contract;

      if (!isEmpty(contract)) {
        let parentContract = find(this.props.contracts, {
          _id: contract._id,
        });

        if (parentContract) {
          let contractLines = parentContract.lines;

          if (this.state.division || this.props.call.division) {
            contractLines = contractLines.filter(
              (line) =>
                line.division._id ==
                (this.state.division || this.props.call.division)
            );
          }

          prodCatOptions = contractLines
            .filter((line) => line.product_category)
            .map((line) => ({
              value: line.product_category._id,
              label: line.product_category.label,
            }));
        }
      }
    }
    return uniqBy(prodCatOptions, "label");
  };

  genChargetypesOptions = () => {
    let { chargetypes, codes, auth } = this.props;
    let classification = find(codes, { _id: auth.user.class });

    let chargetypeOptions = [];
    chargetypeOptions = chargetypes.map((chargetype) => ({
      value: chargetype._id,
      label: chargetype.type,
    }));

    // If employee is classed 'Per Diem', only show billable and non-billable chargetypes
    if (classification && classification.label == "Per Diem") {
      chargetypeOptions = chargetypeOptions.filter(
        (chargetype) =>
          chargetype.label == "Billable" || chargetype.label == "Non-Billable"
      );
    }

    return chargetypeOptions;
  };

  genStageOptions = () => {
    let { codes, call, auth, editing } = this.props;
    let stageOptions = codes
      .filter((code) => code.entity == "Call" && code.field == "stage")
      .map((code) => ({
        value: code._id,
        label: code.label,
        order: code.order,
      }));
    let filteredOptions = [];

    // Only allow Scheduled, Completed, Cancelled for employee roles
    if (auth.maxRole == 4000) {
      filteredOptions = stageOptions.filter(
        (stage) =>
          stage.label == "Scheduled" ||
          stage.label == "Completed" ||
          stage.label == "Cancelled" ||
          (!editing && stage.label == "Approved")
      );
    } else if (!call.invoiced_by) {
      filteredOptions = stageOptions.filter(
        (stage) =>
          stage.label == "Scheduled" ||
          stage.label == "Completed" ||
          stage.label == "Cancelled" ||
          (!editing && stage.label == "Approved")
      );
    }

    let contract = this.state.contract || call.contract;
    if (contract && contract.stage && contract.stage.label == "Draft") {
      filteredOptions = stageOptions.filter(
        (stage) => stage.label == "Scheduled" || stage.label == "Cancelled"
      );
    }

    return orderBy(filteredOptions, "order");
  };

  genAssignedToOptions = () => {
    const findChildren = (parent, children) => {
      let reportsTo = users.filter(
        (user) => user.reports_to == parent.payroll_id
      );

      if (!reportsTo.length) {
        return children;
      } else {
        reportsTo.map((user) => {
          children.push(user);
          findChildren(user, children);
        });
      }

      return children;
    };

    let { users, auth } = this.props;
    let assignedToOptions = [];
    if (auth.maxRole <= 4000) {
      /* Employee - show only logged in users calls */
      assignedToOptions = users.filter(
        (user) => user.payroll_id === auth.user.payroll_id
      );
    } else if (auth.maxRole <= 8000) {
      /* Supervisor, Manager, Director, VP - show children, grandchildren employees */
      let children = findChildren(auth.user, []);
      assignedToOptions = [...children, auth.user];
    } else if (auth.maxRole <= 9000) {
      assignedToOptions = users;
    }

    // console.log({ assignedToOptions });

    return uniqBy(
      sortBy(
        assignedToOptions.map((user) => ({
          value: user._id,
          label: user.full_name || `${user.last_name}, ${user.first_name}`,
        })),
        "label"
      ),
      "label"
    );
  };

  saveEntries = (entries) => {
    let duration = 0;
    let billing_quantity = 0;

    entries.map((entry) => {
      if (entry.uom !== "MILE") {
        duration += parseFloat(entry.quantity);
        let chargetype = find(this.props.chargetypes, {
          id: entry.charge_type,
        });

        if (entry.uom == "FIXED") {
          entry.billing_quantity = 1;
        }

        if (chargetype.type.includes("Billable")) {
          billing_quantity += parseFloat(entry.billing_quantity);
        }
      } else {
        billing_quantity += parseFloat(entry.billing_quantity);
      }
    });

    this.setState(
      {
        quantity: duration,
        billing_quantity,
        entries,
        isValidDate: duration > 24 ? false : true,
      },
      () => {
        console.warn("Saving entries...", this.state);
        if (this.state.start_time || this.props.call.start_time) {
          this.setState({
            end_time: moment(
              this.state.start_time || this.props.call.start_time
            ).add(duration, "hours"),
            // tif_out: moment(
            //   this.state.start_time || this.props.call.start_time
            // ).add(duration, "hours")
          });
        }
      }
    );
    this.props.actions.hideModal();
  };

  cancelEntries = () => {
    this.props.actions.hideModal();
  };

  onEnterTime = () => {
    this.props.onEnterTime();
    this.setState({ overflow: false, canSubmit: this.state.canSubmit }, () =>
      console.log(this.state.canSubmit)
    );
  };

  setOverflow = () => {
    this.setState({ overflow: true });
  };

  render() {
    let { call, accounts, items, codes, chargetypes, users, auth, editing } =
      this.props;
    let { quantity, billing_quantity, contractStart, contractEnd } = this.state;

    let startTime = this.state.start_time || call.start_time;
    let endTime = this.state.end_time || call.end_time;

    let assigned_to =
      this.state.assigned_to || call.assigned_to || this.props.auth.user._id;
    let stage = this.state.stage || call.stage;

    // console.log("stage", stage);

    let entries = this.state.entries;
    let contract = this.state.contract || call.contract;
    const product_category =
      this.state.product_category || call.product_category;

    let showBillingQuantity = entries.find(
      (entry) => entry.uom == "MIN" || entry.uom == "MILE"
    );

    let timeSheet = (
      <CallProcedureForm
        call={call}
        contract={contract || call.contract}
        assignedTo={assigned_to}
        items={items}
        codes={codes}
        entries={entries}
        users={users}
        auth={auth}
        startTime={startTime}
        product_category={product_category}
        chargetypes={chargetypes}
        onOverflow={this.setOverflow}
        onSave={this.saveEntries}
        onCancel={this.cancelEntries}
        isMobile={this.props.isMobile}
      />
    );

    let contractOptions;
    if (this.props.adding) {
      contractOptions = this.props.contracts.filter((contract) => {
        var duration = moment
          .duration(moment().diff(contract.end_date))
          .asDays();
        return (
          contract.parent_account?._id ==
            (this.state.parent_account || call.parent_account) &&
          moment().isSameOrAfter(contract.start_date) &&
          duration < 15
        );
      });
    } else {
      contractOptions = this.props.contracts.filter(
        (contract) =>
          contract.parent_account?._id ==
          (this.state.parent_account || call.parent_account)
      );
    }

    let totalQuantities = 0;
    for (let i = 0; i < entries.length; i++) {
      totalQuantities += entries[i].quantity;
    }

    return (
      <Fragment>
        <Formsy
          ref="form"
          className="vertical form"
          onValidSubmit={this.props.adding ? this.add : this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Row>
            <Col md={3} xs={12}>
              <DropdownSearch
                required
                name="parent_account"
                label="Parent Account"
                onChange={this.onChange}
                disabled={!editing}
                value={this.state.parent_account || call.parent_account}
                data={orderBy(
                  [
                    ...accounts.map((account) => ({
                      value: account._id,
                      label: account.name,
                    })),
                  ],
                  "label"
                )}
              />
            </Col>
            <Col md={3} xs={12}>
              <DropdownSearch
                required
                name="contract"
                label="Contract"
                onChange={this.onChange}
                disabled={!editing}
                value={contract ? contract._id : ""}
                data={contractOptions.map((contract) => ({
                  value: contract._id,
                  label: contract.name,
                }))}
              />
            </Col>
            <Col md={3} xs={12}>
              <DropdownSearch
                required
                name="division"
                label="Division"
                value={this.state.division}
                onChange={this.onChange}
                disabled={!editing}
                data={[
                  ...this.props.divisions.map((division) => ({
                    value: division.id,
                    label: division.description,
                  })),
                ]}
              />
            </Col>
            <Col md={3} xs={12}>
              <DropdownSearch
                required
                name="product_category"
                label="Discipline"
                disabled={!editing}
                data={[...this.getProdCatOptions()]}
                onChange={this.onChange}
                value={
                  this.state.product_category
                    ? this.state.product_category._id
                    : ""
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                required
                name="assigned_to"
                label="Assigned To"
                onChange={this.onChange}
                disabled={!editing}
                data={[...this.genAssignedToOptions()]}
                value={assigned_to}
              />
            </Col>
            <Col md={6} xs={12}>
              <DropdownSearch
                required
                name="stage"
                label="Stage"
                disabled={!editing}
                onChange={this.onChange}
                data={[...this.genStageOptions()]}
                value={stage ? stage._id : ""}
              />
            </Col>
            <Col md={contract && contract.is_tif ? 3 : 6} xs={12}>
              <DateTimePicker
                required
                name="start_time"
                label="Scheduled Start Time"
                disabled={!editing}
                value={startTime}
                onChange={this.selectDate}
                validations={{
                  invalidContractStartDate: (values, value) =>
                    value &&
                    moment(startTime).isSameOrAfter(contractStart) &&
                    moment(startTime).isBefore(contractEnd)
                      ? true
                      : false,
                }}
                validationErrors={{
                  invalidContractStartDate:
                    "Date should be valid for the selected contract",
                }}
              />
            </Col>
            <Col md={contract && contract.is_tif ? 3 : 6} xs={12}>
              <DateTimePicker
                required
                name="end_time"
                label="Scheduled End Time"
                disabled={!editing}
                value={endTime}
                onChange={this.selectDate}
                minDate={new Date(startTime)}
                valid={this.state.isValidDate}
                validations={{
                  invalidDate: (values, value) =>
                    value &&
                    values["start_time"] &&
                    moment(value).isBefore(values["start_time"])
                      ? false
                      : true,
                  invalidDuration: (values, value) => {
                    if (value && values["start_time"]) {
                      let duration = moment.duration(
                        moment(value).diff(moment(values["start_time"]))
                      );
                      let hours = duration.asHours();
                      return hours < 24;
                    }
                  },
                }}
                validationErrors={{
                  invalidDate: "End time should be after start time",
                  invalidDuration: "Call cannot extend over 24 hours",
                }}
              />
            </Col>
            {/* If contract requires TIF, show TIF */}
            {contract && contract.is_tif && (
              <Col md={3} xs={12}>
                <DateTimePicker
                  name="tif_in"
                  label="Time in Facility Start Time"
                  disabled={!editing}
                  interval={1}
                  onChange={this.selectDate}
                  value={this.state.tif_in ? this.state.tif_in : call.tif_in}
                />
              </Col>
            )}
            {contract && contract.is_tif && (
              <Col md={3} xs={12}>
                <DateTimePicker
                  name="tif_out"
                  label="Time in Facility End Time"
                  disabled={!editing}
                  interval={1}
                  onChange={this.selectDate}
                  value={this.state.tif_out ? this.state.tif_out : call.tif_out}
                />
              </Col>
            )}
            <Col md={showBillingQuantity ? 6 : 12} xs={12}>
              <Input
                required
                formNoValidate
                type="number"
                step={0.01}
                name="quantity"
                label={"Duration"}
                value={quantity}
                onFocus={this.onEnterTime}
                disabled={
                  startTime && this.props.adding
                    ? this.state.product_category == undefined
                    : editing
                    ? this.state.product_category == undefined
                    : true
                }
                validations={{
                  isValidQuant: (values, value) =>
                    parseFloat(value) ==
                    Math.ceil(parseFloat(value) / 0.25) * 0.25,
                  isSumQuant: (values, value) =>
                    parseFloat(value) == totalQuantities,
                }}
                validationErrors={{
                  isValidQuant: "Call duration must be in increments of 0.25",
                  isSumQuant:
                    "Call duration must equal total of procedure durations.",
                }}
              />
            </Col>
            {showBillingQuantity && (
              <Col md={6} xs={12}>
                <Input
                  required
                  formNoValidate
                  type="number"
                  step={0.1}
                  name="billing_quantity"
                  label={`Billing Quantity`}
                  value={billing_quantity}
                  onFocus={this.props.onEnterTime}
                  disabled={
                    this.props.adding
                      ? this.state.product_category == undefined
                      : editing
                      ? call.product_category == undefined
                      : true
                  }
                />
              </Col>
            )}
            {!isEmpty(entries) && (
              <CallProcedureList
                entries={entries}
                contract={contract || call.contract}
                codes={codes}
                items={items}
                editing={editing}
              />
            )}
            {/* Show Fields for Selected Stage */}
            {stage && (
              <div>
                {stage.order >= 2 && stage.order <= 4 && (
                  <div>
                    <Col md={12} xs={12}>
                      <DropdownSearch
                        name={"completed_by"}
                        label={"Completed By"}
                        value={
                          this.state.completed_by
                            ? this.state.completed_by
                            : call.completed_by
                            ? call.completed_by._id || call.completed_by
                            : this.props.auth.user._id
                        }
                        onChange={this.onChange}
                        disabled={!editing}
                        data={[
                          ...sortBy(
                            users.map((user) => {
                              let isDisabled = false;
                              let reasons = [];
                              if (
                                user.cori_exp_date &&
                                new Date(user.cori_exp_date) < new Date()
                              ) {
                                isDisabled = true;
                                reasons.push("CORI");
                              }
                              if (
                                user.physical_exp_date &&
                                new Date(user.physical_exp_date) < new Date()
                              ) {
                                isDisabled = true;
                                reasons.push("Physical");
                              }
                              if (
                                user.tbtest_exp_date &&
                                new Date(user.tbtest_exp_date) < new Date()
                              ) {
                                isDisabled = true;
                                reasons.push("TB Test");
                              }
                              return {
                                value: user._id,
                                label: isDisabled
                                  ? `${user.first_name} ${
                                      user.last_name
                                    } (${reasons.join(", ")} Expired)`
                                  : `${user.full_name}`,
                              };
                            }),
                            "label"
                          ),
                        ]}
                      />
                    </Col>
                    <Col md={6} style={{ display: "none" }} xs={12}>
                      <Input
                        type="date"
                        name="completed_date"
                        value={
                          call.completed_date ? call.completed_date : new Date()
                        }
                      />
                    </Col>
                  </div>
                )}
                {stage.order >= 3 && stage.order <= 4 && (
                  <div>
                    <Col md={12} xs={12}>
                      <DropdownSearch
                        name={"approved_by"}
                        label={"Approved By"}
                        value={this.state.approved_by || call.approved_by}
                        disabled={!editing}
                        data={orderBy(
                          [
                            ...this.props.users.map((user) => ({
                              value: user._id,
                              label: `${user.full_name}`,
                            })),
                          ],
                          "label"
                        )}
                      />
                    </Col>
                    <Col md={6} style={{ display: "none" }} xs={12}>
                      <Input
                        formNoValidate
                        type="date"
                        name="approved_date"
                        label="Approved Date"
                        value={call.approved_date || ""}
                        disabled={!editing}
                      />
                    </Col>
                  </div>
                )}
                {stage.order == 4 && (
                  <div>
                    <Col md={4} xs={12}>
                      <DropdownSearch
                        name={"invoiced_by"}
                        label={"Invoiced By"}
                        value={call.invoiced_by}
                        disabled={!editing}
                        data={[
                          ...this.props.users.map((user) => ({
                            value: user._id,
                            label: user.full_name,
                          })),
                        ]}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Input
                        formNoValidate
                        type="date"
                        name="invoiced_date"
                        label="Invoice Date"
                        value={
                          moment.utc(call.invoiced_date).format("YYYY-MM-DD") ||
                          ""
                        }
                        disabled={!editing}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Input
                        formNoValidate
                        type="text"
                        name="invoice_number"
                        label="Invoice Number"
                        value={call.invoice && call.invoice.number}
                        disabled={!editing}
                      />
                    </Col>
                  </div>
                )}
                {stage.order == 5 && (
                  <div>
                    <Col md={4} xs={12}>
                      <DropdownSearch
                        name={"cancel_by"}
                        label={"Cancelled By"}
                        value={this.state.cancel_by || call.cancel_by}
                        onChange={this.onChange}
                        disabled={!editing}
                        data={[
                          ...this.props.users.map((user) => ({
                            value: user._id,
                            label: user.full_name,
                          })),
                        ]}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Input
                        formNoValidate
                        type="date"
                        name="cancel_date"
                        label="Cancel Date"
                        value={
                          moment.utc(call.invoiced_date).format("YYYY-MM-DD") ||
                          ""
                        }
                        disabled={!editing}
                      />
                    </Col>
                    <Col md={4} xs={12}>
                      <Input
                        type="text"
                        name="cancel_reason"
                        label="Cancel Reason"
                        value={call.cancel_reason || ""}
                        disabled={!editing}
                      />
                    </Col>
                  </div>
                )}
              </div>
            )}
            <Col md={12} xs={12}>
              <Textarea
                type="text"
                name="notes"
                label="Notes"
                maxLength="4000"
                value={call.notes}
                disabled={!editing}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} className="action-footer">
              {!this.props.adding &&
                stage &&
                stage.label !== "Invoiced" &&
                (auth.maxRole == 9000 ||
                  (auth.maxRole > 4000 &&
                    auth.maxRole <= 8000 &&
                    stage.label !== "Approved") ||
                  (auth.maxRole == 4000 && stage.label == "Scheduled")) && (
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={this.delete}
                  >
                    Delete
                  </button>
                )}
              <button
                className="btn btn-danger"
                type="button"
                onClick={this.cancel}
              >
                Cancel
              </button>
              <input
                className="btn btn-success"
                type="submit"
                disabled={!this.state.canSubmit || this.props.readOnly}
                value={this.props.saving ? "Saving... " : "Save"}
              />
            </Col>
          </Row>
        </Formsy>
        <Modal
          id="callDetailsModal"
          title="Enter Time"
          body={timeSheet}
          overflow={this.state.overflow}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="callConfirmModal"
          title={"Date Confirmation"}
          body={
            "There are procedures that are not valid for your selected date range. Please review and update the procedures for this call."
          }
          modal={this.props.modal}
          cancel={this.cancelClearEntries}
          close={this.props.actions.hideModal}
          confirm={this.clearEntries}
        />
      </Fragment>
    );
  }
}

CallForm.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  call: PropTypes.object,
  accounts: PropTypes.array,
  divisions: PropTypes.array,
  auth: PropTypes.object,
  users: PropTypes.array,
  contracts: PropTypes.array,
  items: PropTypes.array,
  codes: PropTypes.array,
  chargetypes: PropTypes.array,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onEnterTime: PropTypes.func,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  isModal: PropTypes.bool,
};

function mapStatesToProps(state, ownProps) {
  return {
    modal: state.reducers.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallForm);
