import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as divisionActions from "../../actions/divisionActions";
import * as glaccountActions from "../../actions/glaccountActions";
import * as roleActions from "../../actions/roleActions";

import UserForm from "./UserForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Loading from "../common/Loading";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class UserEditPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editing: false,
    };
  }

  componentDidMount = () => {
    let { divisions, glaccounts, roles, actions } = this.props;

    actions.loadUsers();
    actions.getUser(this.props.match.params.id);

    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(glaccounts)) actions.loadGlaccounts();
    if (isEmpty(roles)) actions.loadRoles();
  };

  handleSave = (user) => {
    user.id = this.props.user.id;
    if (user.newLicense && user.newLicenseExpirationDate) {
      const licenses = user.licenses?.length ? [...user.licenses] : [];
      licenses.push({
        license: user.newLicense,
        expirationDate: user.newLicenseExpirationDate,
      });
      user.licenses = licenses;
    }
    delete user.newLicense;
    delete user.newLicenseExpirationDate;
    this.props.actions.updateUser(user);
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { user, auth, savingUser, loading } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[
              { label: "Employees" },
              {
                label:
                  user.full_name || `${user.last_name}, ${user.first_name}`,
              },
            ]}
          />
          <EditButton onClickEdit={this.handleEdit} auth={auth} />
        </Row>
        <Row>
          {!loading ? (
            <Col md={12} xs={12}>
              <UserForm
                {...this.props}
                saving={savingUser}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
                editing={this.state.editing}
              />
            </Col>
          ) : (
            <Loading active />
          )}
        </Row>
      </div>
    );
  }
}

UserEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object,
  loadingUser: PropTypes.bool,
  savingUser: PropTypes.bool,
  user: PropTypes.object,
  users: PropTypes.array,
  divisions: PropTypes.array,
  glaccounts: PropTypes.array,
  codes: PropTypes.array,
  roles: PropTypes.array,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    loading: state.reducers.loadingUser,
    savingUser: state.reducers.savingUser,
    user: state.reducers.user,
    users: state.reducers.users,
    glaccounts: state.reducers.glaccounts,
    divisions: state.reducers.divisions,
    codes: state.reducers.codes,
    roles: state.reducers.roles.roles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...divisionActions,
        ...glaccountActions,
        ...roleActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(UserEditPage);
