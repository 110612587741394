import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as companyActions from "../../actions/companyActions";
import * as modalActions from "../../actions/modalActions";

import { Row, Col } from "react-bootstrap";
import CompanyList from "./CompanyList";
import CompanyLocationList from "./CompanyLocationList";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import CompanyLocationPage from "./CompanyLocationPage";

class CompanyPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { adding: false };

    autoBind(this);

    props.actions.loadCompanies();
  }

  handleSave(company) {
    let data = {
      id: this.props.company.id,
      name: company.name
    };

    this.props.actions.updateCompany(data);
  }

  handleAdd() {
    this.setState({ adding: !this.state.add });
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>Company Information</h1>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CompanyList companies={this.props.companies} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CompanyLocationPage />
          </Col>
        </Row>
      </div>
    );
  }
}

CompanyPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  companyToDelete: PropTypes.string,
  savingCompany: PropTypes.bool,
  companies: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    companyToDelete: state.reducers.companyToDelete,
    savingCompany: state.reducers.savingCompany,
    companies: state.reducers.companies.companies,
    company: state.reducers.company
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...companyActions, ...modalActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CompanyPage);
