import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as chargetypeActions from "../../actions/chargetypeActions";
import ChargetypeForm from "./ChargetypeForm";

class ChargetypeAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(chargetype) {
    this.props.actions.createChargetype(chargetype);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Chargetype</h1>
        <ChargetypeForm
          onSave={this.handleSave}
          saving={this.props.savingChargetype}
          chargetype={this.props.chargetype}
        />
      </div>
    );
  }
}

ChargetypeAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingChargetype: PropTypes.bool,
  chargetype: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    savingChargetype: state.reducers.savingChargetype
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...chargetypeActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ChargetypeAddPage);
