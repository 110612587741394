import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as modalActions from "../../actions/modalActions";
import * as codeActions from "../../actions/codeActions";

import CodeList from "./CodeList";
import CodeForm from "./CodeForm";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col, Collapse } from "react-bootstrap";

class CodePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  componentDidMount = () => {
    this.props.actions.loadCodes();
  };

  onClickEdit = codeId => {
    this.props.actions.getCode(codeId, true);
  };

  onClickDelete = codeId => {
    this.props.actions.requestCodeId(codeId);
  };

  handleDelete = () => {
    this.props.actions.deleteCode(this.props.codeToDelete);
  };

  handleSave = async code => {
    await this.props.actions.createCode(code);
    await this.props.actions.loadCodes();
    this.setState({ add: false });
  };

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "System Codes" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Code</h4>
                  <CodeForm
                    onSave={this.handleSave}
                    saving={this.props.savingCode}
                    code={this.props.code}
                    codes={this.props.codes}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CodeList
              codes={this.props.codes}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
            <Modal
              id="codeDetailsModal"
              title="Code Info"
              body={this.props.code.name}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="codeDeleteModal"
              title="Delete Code"
              body="Are you sure you want to delete this code?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CodePage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingCode: PropTypes.bool,
  codeToDelete: PropTypes.string,
  codes: PropTypes.array.isRequired,
  code: PropTypes.object.isRequired
};

function mapStatesToProps(state, ownProps) {
  let code = {
    id: 0,
    entity: "",
    field: "",
    value: "",
    label: ""
  };

  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingCode: state.reducers.savingCode,
    codeToDelete: state.reducers.codeToDelete,
    codes: state.reducers.codes,
    code: code
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...codeActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CodePage);
