import React, { Component } from "react";
import PropTypes from "prop-types";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import CustomAgenda from "./CustomAgenda";
import CustomToolbar from "./CustomToolbar";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment";
import find from "lodash/find";
import uniq from "lodash/uniq";
import { isEmpty } from "lodash";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

class CallCalendar extends Component {
  state = { filters: { start_time: null, end_time: null } };

  componentDidMount = () => {
    let toolbar = this.toolbar.props.toolbar;
    this.handleViewChange(toolbar);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.filtered !== this.props.filtered) {
      this.props.onFetchData({
        sorted: [{ start_time: 1 }],
        filtered:
          nextProps.filtered.start_time && nextProps.filtered.end_time
            ? { ...this.state.filters, ...nextProps.filtered }
            : { ...nextProps.filtered, ...this.state.filters },
      });
    }
  };

  handleViewChange = (toolbar) => {
    let { filters } = this.state;

    if (toolbar.view == "agenda") {
      let range = toolbar.label.split(" — ");
      filters.start_time = moment(range[0]).startOf("day");
      filters.end_time = moment(range[1]).endOf("day");
    } else {
      filters.start_time = moment(toolbar.date).startOf(toolbar.view);
      filters.end_time = moment(toolbar.date).endOf(toolbar.view);
    }

    this.setState({ filters });
    this.props.onFetchData({
      sorted: [{ start_time: 1 }],
      filtered: { ...this.props.filtered, ...filters },
    });
  };

  render() {
    let { calls, contracts, users, onSelectEvent, onMoveEvent } = this.props;
    let total = 0;

    const customToolbar = (toolbar) => (
      <CustomToolbar
        ref={(r) => (this.toolbar = r)}
        toolbar={toolbar}
        onViewChange={(toolbar) => this.handleViewChange(toolbar)}
      />
    );

    const WeekEvent = ({ event }) => (
      <>
        <div>
          <strong>{event.title}</strong>
        </div>
        <div className="event-description">{event.desc}</div>
        <div className="event-subdescription">
          <em>
            {event.subdesc.split("\n").map((name, i) => (
              <span key={i}>
                {name}
                <br />
              </span>
            ))}
          </em>
        </div>
      </>
    );

    let components = {
      toolbar: customToolbar,
      week: { event: WeekEvent },
    };

    let events = calls.map((call) => {
      let clients = call.entries.map((entry) => entry.client);
      let contract = find(contracts, { _id: call.contract });
      let clientNames = [];
      clients.map((client) => {
        if (contract) {
          let clientObj = find(contract.clients, { _id: client });
          if (clientObj) {
            clientNames.push(clientObj.name);
          }
        }
      });

      clientNames = uniq(clientNames);
      clientNames = clientNames
        .map((name, i) => (i == 0 ? `${name}` : `\n${name}`))
        .toString();

      let assignedTo = find(users, { _id: call.assigned_to });

      if (call.quantity) {
        total = total + call.quantity;
      }

      return {
        ...call,
        title: assignedTo ? assignedTo.full_name : "",
        start: new Date(call.start_time),
        end: new Date(call.end_time),
        desc: contract ? contract.name : "",
        subdesc: clientNames,
      };
    });

    const eventStyleGetter = (event, start, end, isSelected) => {
      let backgroundColor;
      let color = "#000";

      const stage = isEmpty(this.props.codes)
        ? {}
        : find(this.props.codes, { _id: event.stage._id || event.stage });

      switch (stage.label) {
        case "Scheduled":
          backgroundColor = "#A2D9F4";
          break;
        case "Completed":
          backgroundColor = "#B9E3A4";
          break;
        case "Approved":
          backgroundColor = "#F6DF92";
          break;
        case "Invoiced":
          backgroundColor = "#FBA5A5";
          break;
        case "Cancelled":
          backgroundColor = "#CCCCCC";
          break;
        case "Non-Invoiced":
          backgroundColor = "#CEB9FF";
          break;
        default:
          backgroundColor = "#FF0000";
          break;
      }

      const style = {
        backgroundColor,
        color,
        fontSize: 14,
        display: "block",
      };

      return { style };
    };

    const views = {
      month: true,
      week: true,
      day: true,
      agenda: CustomAgenda,
    };

    const formats = {
      dayFormat: "dd MM/DD",
      agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
        `${localizer.format(start, "LT")}
      ${localizer.format(end, "LT")}`,
    };

    const minTime = new Date();
    minTime.setHours(6, 0, 0);

    return (
      <>
        <DragAndDropCalendar
          selectable
          drilldownView="day"
          views={views}
          defaultView="week"
          components={components}
          events={events}
          formats={formats}
          min={minTime}
          defaultDate={new Date()}
          eventPropGetter={(event) => eventStyleGetter(event)}
          onSelectSlot={(slotInfo) => console.log(slotInfo)}
          onSelectEvent={onSelectEvent}
          onEventDrop={(event) => onMoveEvent(event)}
        />
        <div
          style={{ marginTop: "10px", fontWeight: "bold", fontSize: "1.5em" }}
        >
          Total Hours: {total}
        </div>
      </>
    );
  }
}

CallCalendar.propTypes = {
  calls: PropTypes.array.isRequired,
  event: PropTypes.object,
  onSelectEvent: PropTypes.func,
  onMoveEvent: PropTypes.func,
};

export default DragDropContext(HTML5Backend)(CallCalendar);
