let param = "MyChoice+Reports";

const reports = {
  "Accounts List": [
    {
      name: "Account Contact List",
      type: "Accounts List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fAccount+Contact+List`,
    },
    {
      name: "Account List",
      type: "Accounts List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fAccount+List`,
    },
    {
      name: "Account Location List",
      type: "Accounts List ",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fAccount+Location+List`,
    },
  ],
  "Call Report": [
    {
      name: "Call Detail List",
      type: "Call Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fCall+Detail+List`,
    },
    {
      name: "Call Monthly Billing By Contract Report",
      type: "Call Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fCall+Monthly+Billing+By+Contract+Report`,
    },
    {
      name: "Call Payroll By Staff Report",
      type: "Call Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fCall+Payroll+By+Staff+Report`,
    },
    {
      name: "Clinical Fellow Report",
      type: "Call Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fClinical+Fellow+Report`,
    },
    {
      name: "Time in Facility By Discipline",
      type: "Call Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fTime+In+Facility+By+Discipline`,
    },
    // {
    //   name: "Staff Call Detail Report",
    //   type: "Call Report",
    //   url: `http://es-acct01/Reports/Pages/Report.aspx?ItemPath=%2f${param}%2fStaff+Call+Detail+List`
    // }
  ],
  "Contracts List": [
    {
      name: "Contract List",
      type: "Contracts List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fContract+List`,
    },
    {
      name: "Contract Detail List",
      type: "Contracts List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fContract+Detail+List`,
    },
    {
      name: "Contract Client List",
      type: "Contracts List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fContract+Client+List`,
    },
  ],
  "Payroll Report": [
    {
      name: "Payroll Report",
      type: "Payroll Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fPayroll+Report`,
    },
  ],
  "Revenue Report": [
    // {
    //   name: "Gross Margin by Contract",
    //   type: "Revenue Report",
    //   url: `http://es-acct01/Reports/Pages/Report.aspx?ItemPath=%2f${param}%2fGross+Margin+By+Contract`
    // },
    // {
    //   name: "Gross Margin by Staff",
    //   type: "Revenue Report",
    //   url: `http://es-acct01/Reports/Pages/Report.aspx?ItemPath=%2f${param}%2fGross+Margin+By+Staff`
    // },
    {
      name: "Invoice Detail List",
      type: "Revenue Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fInvoice+Detail+List`,
    },
    {
      name: "Max Obligation By Contract",
      type: "Revenue Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fMax+Obligation+By+Contract`,
    },
    // {
    //   name: "Monthly Service Units",
    //   type: "Revenue Report",
    //   url: `http://es-acct01/Reports/Pages/Report.aspx?ItemPath=%2f${param}%2fMonthly+Service+Units`
    // },
    {
      name: "Revenue Detail",
      type: "Revenue Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fRevenue+Detail+Report`,
    },
    {
      name: "Revenue Summary",
      type: "Revenue Report",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fRevenue+Summary+Report`,
    },
  ],
  "Setup List": [
    {
      name: "Codes List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fCodes+List`,
    },
    {
      name: "Department List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fDepartment+List`,
    },
    {
      name: "Employee License List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fEmployee+License+List`,
    },
    {
      name: "Employee List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fEmployees+List`,
    },
    {
      name: "GL Account List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fGL+Account+List`,
    },
    {
      name: "Item List",
      type: "Setup List",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fItems+List`,
    },
  ],
  "MyChoice Reports": [
    {
      name: "Utilization",
      type: "MyChoice Reports",
      url: `https://reports.eastersealsma.org/reports/Pages/Report.aspx?ItemPath=%2f${param}%2fService+Hours`,
    },
  ],
};

export default reports;
