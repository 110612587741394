import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as codeActions from "../../actions/codeActions";
import * as contactActions from "../../actions/contactActions";
import * as accountActions from "../../actions/accountActions";

import ContactForm from "./ContactForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class ContactEditPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { editing: false };
  }

  componentDidMount = () => {
    let { accounts, actions } = this.props;

    actions.getContact(this.props.match.params.id);
    if (isEmpty(accounts)) actions.loadAccounts();
  };

  handleSave = contact => {
    contact.id = this.props.contact._id;
    this.props.actions.updateContact(contact);
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { contact, saving, auth, ...rest } = this.props;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[
              { label: "Contacts" },
              { label: `${contact.last_name}, ${contact.first_name}` }
            ]}
          />
          <EditButton onClickEdit={this.handleEdit} auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ContactForm
              contact={contact}
              {...rest}
              saving={saving}
              editing={this.state.editing}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ContactEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  contact: PropTypes.object,
  savingContact: PropTypes.bool,
  codes: PropTypes.arrays
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    contact: state.reducers.contact,
    accounts: state.reducers.accounts,
    saving: state.reducers.savingContact,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...contactActions, ...accountActions, ...codeActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContactEditPage);
