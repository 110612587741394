import { push } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import { updateCallsSuccess } from "./callActions";
import invoiceService from "../services/invoiceService";
import callService from "../services/callService";

export function loadInvoiceSuccess(invoices) {
  return {
    type: types.LOAD_INVOICE_SUCCESS,
    invoices
  };
}

export function getInvoiceSuccess(invoice) {
  return {
    type: types.GET_INVOICE_SUCCESS,
    invoice
  };
}

export function bulkApproveSuccess() {
  return {
    type: types.BULK_APPROVE_SUCCESS
  };
}

export function bulkCompleteSuccess() {
  return {
    type: types.BULK_COMPLETE_SUCCESS
  };
}

export function bulkUpdateSuccess() {
  return {
    type: types.BULK_UPDATE_INVOICES_SUCCESS
  };
}

export function savingInvoice(status = true) {
  return {
    type: types.SAVING_INVOICE,
    savingInvoice: status
  };
}

export function loadingInvoice(status = true) {
  return {
    type: types.LOADING_INVOICE,
    loadingInvoice: status
  };
}

export function createInvoiceSuccess(invoice) {
  return {
    type: types.CREATE_INVOICE_SUCCESS,
    invoice
  };
}

export function clearInvoices() {
  return {
    type: types.DESELECT_ALL_INVOICES
  };
}

export function toggleInvoice(id) {
  return {
    type: types.TOGGLE_INVOICE,
    id: id
  };
}

export function toggleInvoices(invoices) {
  return {
    type: types.TOGGLE_INVOICES,
    invoices: invoices
  };
}

export function toggleAllGpInvoices(invoices) {
  return {
    type: types.TOGGLE_ALL_GP_INVOICES,
    invoices: invoices
  };
}

export function toggleGpInvoice(id) {
  return {
    type: types.TOGGLE_GP_INVOICE,
    id: id
  };
}

export function toggleInvoicePrint(number) {
  return {
    type: types.TOGGLE_INVOICE_PRINT,
    number: number
  };
}

export function loadingCall(status = true) {
  return {
    type: types.LOADING_CALL,
    loadingCall: status
  };
}

export function updateCallSuccess(call) {
  return {
    type: types.UPDATE_CALL_SUCCESS,
    call
  };
}

export function updateInvoiceSuccess(invoice) {
  return {
    type: types.UPDATE_INVOICE_SUCCESS,
    invoice
  };
}

export function deleteInvoiceSuccess(invoiceId) {
  return {
    type: types.DELETE_INVOICE_SUCCESS,
    invoiceId
  };
}

export function requestInvoiceId(invoiceId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_INVOICE_ID,
      invoiceToVoid: invoiceId
    });
    dispatch(showModalSuccess("invoiceDeleteModal"));
  };
}

export function updateInvoicedCall(call) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return callService
      .updateInvoicedCall(call)
      .then(updatedCall => {
        dispatch(updateCallSuccess(updatedCall));
      })
      .catch(error => {
        dispatch(savingInvoice(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function loadInvoices() {
  return dispatch => {
    dispatch(loadingInvoice());
    dispatch(hideAlertSuccess());
    return invoiceService
      .loadInvoices()
      .then(data => {
        dispatch(loadInvoiceSuccess(data));
        dispatch(loadingInvoice(false));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getInvoice(id, showINVOICEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return invoiceService
      .getInvoice(id)
      .then(invoice => {
        dispatch(getInvoiceSuccess(invoice));
        if (showINVOICEDetails) {
          dispatch(showModalSuccess("invoiceDetailsModal"));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createInvoice(invoice) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingInvoice());
    return invoiceService
      .createInvoice(invoice)
      .then(createdInvoice => {
        dispatch(createInvoiceSuccess(createdInvoice));
        let updatedCalls = invoice.invoiceLines.map(line => ({
          _id: line.call_id
        }));

        dispatch(updateCallsSuccess(updatedCalls));
        dispatch(savingInvoice(false));
        dispatch(showAlertSuccess("Invoice created successfully", "success"));
      })
      .catch(error => {
        dispatch(savingInvoice(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function updateInvoice(invoice) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingInvoice());
    return invoiceService
      .updateInvoice(invoice)
      .then(updatedInvoice => {
        dispatch(updateInvoiceSuccess(updatedInvoice));
        dispatch(savingInvoice(false));
        dispatch(showAlertSuccess("Invoice updated successfully", "success"));
      })
      .catch(error => {
        dispatch(savingInvoice(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteInvoice(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return invoiceService
      .deleteInvoice(id)
      .then(() => {
        dispatch(deleteInvoiceSuccess(id));
        dispatch(showAlertSuccess("Invoice deleted successfully", "success"));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function bulkApprove(idArray, user, stageID) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return invoiceService
      .bulkApprove(idArray, user, stageID)
      .then(responseArray => {
        dispatch(
          showAlertSuccess(
            `${responseArray.nModified} invoices approved`,
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function bulkComplete(idArray, user, stageID) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return invoiceService
      .bulkComplete(idArray, user, stageID)
      .then(responseArray => {
        dispatch(
          showAlertSuccess(
            `${responseArray.nModified} invoices completed`,
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function bulkUpdateInvoices(idArray, user, stageID) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return invoiceService
      .bulkUpdate(idArray, user, stageID)
      .then(responseArray => {
        dispatch(
          showAlertSuccess(
            `${responseArray.nModified} invoices updated`,
            "success"
          )
        );
      })
      .catch(error => {
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}
