import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as glaccountActions from "../../actions/glaccountActions";

import { Row, Col } from "react-bootstrap";

import DynamicForm from "../common/DynamicForm";
import DynamicHeader from "../common/DynamicHeader";
import find from "lodash/find";

class GlaccountEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editing: false
    };

    autoBind(this);

    props.actions.getGlaccount(props.match.params.id);
  }

  handleSave(glaccount) {
    let data = {
      id: this.props.glaccount.id,
      ...glaccount
    };
    this.props.actions.updateGlaccount(data);
  }

  handleEdit() {
    this.setState({
      editing: !this.state.editing
    });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={12}>
            <h1>{this.props.glaccount.description}</h1>
          </Col>
          <Col md={9}>
            <h3>
              <DynamicHeader entity="Glaccount" /> Info
            </h3>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-warning btn-sm right"
              onClick={this.handleEdit}
            >
              EDIT
            </button>
          </Col>
        </Row>
        <Row className="mt">
          <Col md={12}>
            <DynamicForm
              fieldData={find(this.props.labels, { entity: "Glaccount" })}
              onCancel={this.handleCancel}
              onSave={this.handleSave}
              saving={this.props.savingGlaccount}
              entity={"Glaccount"}
              editing={this.state.editing}
              adding={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

GlaccountEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingGlaccount: PropTypes.bool,
  glaccount: PropTypes.object,
  params: PropTypes.object,
  labels: PropTypes.array
};

GlaccountEditPage.contextTypes = {
  router: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    savingGlaccount: state.reducers.savingGlaccount,
    glaccount: state.reducers.glaccount,
    labels: state.reducers.labels.labels
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...glaccountActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(GlaccountEditPage);
