import React from "react";
import PropTypes from "prop-types";

import { Panel, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const ReportsList = ({ reports }) => (
  <div className="reports">
    {Object.keys(reports).map((list, idx) => {
      let reportItems = reports[list];
      return (
        <Panel key={idx}>
          <Panel.Heading>
            <Panel.Title componentClass="h4">{list}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <ListGroup>
              {reportItems.map((item, i) => (
                <ListGroupItem key={i} href={item.url} target="_blank">
                  <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
                  {item.name}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Panel.Body>
        </Panel>
      );
    })}
  </div>
);

ReportsList.propTypes = {
  reports: PropTypes.object
};

export default ReportsList;
