import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import Loading from "../common/Loading";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip as ReactTooltip } from "react-tooltip";
import matchSorter from "match-sorter";
import moment from "moment";

const ContractList = ({
  contracts,
  auth,
  onClickView,
  onClickDelete,
  onClickExport,
}) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Number",
      accessor: "number",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["number"] }),
      filterAll: true,
    },
    {
      Header: "Account",
      accessor: "parent_account.name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => item["parent_account.name"]],
        }),
      filterAll: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(item) => moment(item.start_date).format("MM/DD/YYYY")],
        }),
      filterAll: true,
      Cell: (row) => <span>{moment.utc(row.value).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "Status",
      accessor: "stage.label",
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: [(row) => row[`stage.label`]],
        });
      },
      filterAll: true,
      Cell: (row) => {
        let className = "default";
        let endDate = row.original.end_date;
        if (row.value == "Approved") {
          if (moment().add(30, "days").isBefore(moment(endDate))) {
            // Green after 30 days
            className = "success";
          } else if (
            moment().add(30, "days").isAfter(moment(endDate)) &&
            moment().isBefore(moment(endDate))
          ) {
            // Yellow within 30 days
            className = "warning";
          } else if (moment().isAfter(moment(endDate))) {
            // Red after current date
            className = "danger";
          }
        }
        return (
          <div>
            <span className={`status ${className}`} />
            {row.value}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let expired = false;
        let endDate = row.original.end_date;
        let stage = row.original.stage;

        // Expired if end date is after current date
        if (moment().isAfter(moment(endDate)) && stage.label == "Approved") {
          expired = true;
        }

        let hasCalls = row.original.has_calls;

        return (
          <div className="text-center actions">
            <DropdownButton title="Actions" id={"dropdown"}>
              <LinkContainer to={`/app/contracts/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              {auth.maxRole == 9000 && (
                <MenuItem
                  data-tooltip-content="There are calls associated with this contract."
                  data-tooltip-id="hasCalls"
                  onClick={() => (!hasCalls ? onClickDelete(row.value) : "")}
                  disabled={hasCalls}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  Delete
                </MenuItem>
              )}
              {hasCalls && (
                <ReactTooltip
                  id="hasCalls"
                  place="left"
                  type="dark"
                  effect="solid"
                />
              )}
              {!expired && (
                <MenuItem onClick={() => onClickExport(row.value)}>
                  <FontAwesomeIcon icon={faDownload} />
                  Export
                </MenuItem>
              )}
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  let isLoading = !contracts.length;

  return (
    <ReactTable
      className="-highlight"
      filterable
      LoadingComponent={() => <Loading active={isLoading} />}
      data={contracts}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "name",
          asc: true,
        },
      ]}
    />
  );
};

ContractList.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  calls: PropTypes.array.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickPreview: PropTypes.func.isRequired,
};

export default ContractList;
