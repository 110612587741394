import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as codeActions from "../../actions/codeActions";
import {} from "../../helpers";
import CodeForm from "./CodeForm";

class CodeAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(code) {
    this.props.actions.createCode(code);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Code</h1>
        <CodeForm
          onSave={this.handleSave}
          saving={this.props.savingCode}
          code={this.props.code}
        />
      </div>
    );
  }
}

CodeAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingCode: PropTypes.bool,
  code: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  a;
  return {
    state: state.reducers,
    savingCode: state.reducers.savingCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...codeActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(CodeAddPage);
