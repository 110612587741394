import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as itemActions from "../../actions/itemActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as modalActions from "../../actions/modalActions";

import * as labelActions from "../../actions/labelActions";

import ProcedureList from "./ProcedureList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ProcedureForm from "./ProcedureForm";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";

class ProcedurePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      add: false
    };

    props.actions.loadItems();
    props.actions.loadChargetypes();
    autoBind(this);
  }

  onClickDelete(itemId) {
    this.props.actions.requestItemId(itemId);
  }

  handleDelete() {
    this.props.actions.deleteItem(this.props.itemToDelete);
  }

  handleSave = async item => {
    await this.props.actions.createItem(item);
    await this.props.actions.loadItems();
    this.setState({ add: false });
  };

  handleCancel() {
    this.setState({ add: false });
    this.refs.form.reset();
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Procedures" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} xs={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Procedures</h4>
                  <ProcedureForm
                    item={{}}
                    chargetypes={this.props.chargetypes}
                    codes={this.props.codes}
                    onSave={this.handleSave}
                    editing={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ProcedureList
              items={this.props.items}
              onClickDelete={this.onClickDelete}
            />
            <ConfirmModal
              id="itemDeleteModal"
              title="Delete Item"
              body="Are you sure you want to delete this item?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ProcedurePage.propTypes = {
  actions: PropTypes.object,

  modal: PropTypes.object,
  chargetypes: PropTypes.array.isRequired,
  savingItem: PropTypes.bool,
  codes: PropTypes.array,
  labels: PropTypes.array,
  itemToDelete: PropTypes.string,
  items: PropTypes.array.isRequired
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingItem: state.reducers.savingItem,
    labels: state.reducers.labels.labels,
    codes: state.reducers.codes,
    chargetypes: state.reducers.chargetypes,
    itemToDelete: state.reducers.itemToDelete,
    items: state.reducers.items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,

        ...itemActions,

        ...chargetypeActions,
        ...labelActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ProcedurePage);
