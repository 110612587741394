import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  Checkbox,
  CheckboxGroup,
  Input,
  RadioGroup,
  Row,
  Select,
  File,
  Textarea,
  Form
} from "formsy-react-components";
import * as accountActions from "../../actions/accountActions";
import * as divisionActions from "../../actions/divisionActions";
import * as chargetypeActions from "../../actions/chargetypeActions";

import sortBy from "lodash/sortBy";
import toLower from "lodash/toLower";
import find from "lodash/find";

class Field extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.field.sourcedFrom) {
      switch (this.props.field.sourcedFrom) {
        case "chargetype":
          this.props.actions.loadChargetypes();
          break;
        case "account":
          this.props.actions.loadAccounts();
          break;
        case "division":
          this.props.actions.loadDivisions();
          break;
      }
    }
  }

  generateOptions(value, field) {
    let optionsArray = [];

    if (field.sourcedFrom) {
      switch (field.sourcedFrom) {
        case "chargetype":
          this.props.chargetypes.map(chargetype => {
            optionsArray.push({
              value: chargetype._id,
              label: chargetype.type
            });
          });
          break;
        case "account":
          this.props.accounts.map(account => {
            optionsArray.push({
              value: account._id,
              label: account.name
            });
          });
          break;
        case "division":
          this.props.divisions.map(division => {
            optionsArray.push({
              value: division._id,
              label: division.description
            });
          });
          break;
      }
    } else {
      this.props.sysCodes.map(code => {
        if (
          (code.entity == this.props.entity || code.entity == "*") &&
          code.field == field.key
        ) {
          let orderNum = code.order ? code.order : false;

          optionsArray.push({
            value: code._id,
            label: code.label,
            order: code.order
          });
        }
      });
    }
    if (optionsArray.length) {
      if (optionsArray[0].order) {
        optionsArray = sortBy(optionsArray, "order");
      } else {
        optionsArray = sortBy(optionsArray, "label");
      }
    }

    optionsArray.map(option => {
      delete option.order;
    });

    optionsArray.unshift({
      value: null,
      label: "Please select...",
      hidden: true
    });

    return optionsArray;
  }

  render() {
    let fieldComponent;
    let fieldOptions;
    let name = `${this.props.parent ? this.props.parent + "." : ""}${
      this.props.field.key
    }`;
    let value;

    if (this.props.value) {
      value = this.props.value;
    } else {
      if (!this.props.adding) {
        if (this.props.parent) {
          let base = this.props[toLower(this.props.entity)][this.props.parent]
            ? this.props[toLower(this.props.entity)][this.props.parent]
            : {};
          value = base[this.props.field.key] || "";
        } else {
          value =
            this.props[toLower(this.props.entity)][this.props.field.key] || "";
          if (this.props.field.field_sub_type == "date")
            value = value.substring(0, 10);
        }

        console.log(this.props.field);
      } else {
        value = "";
      }
    }

    switch (this.props.field.field_type) {
      case "Checkbox":
        fieldComponent = (
          <div
            key={this.props.field.key}
            className={this.props.field.class.join(" ")}
          >
            <Checkbox
              formNoValidate
              name={name}
              onChange={this.props.onChange}
              help={this.props.field.instructions}
              value={value}
              valueLabel={this.props.field.label}
            />
          </div>
        );
        break;
      case "CheckboxGroup":
        break;
      case "Input":
        fieldComponent = (
          <div
            key={this.props.field.key}
            className={this.props.field.class.join(" ")}
          >
            <Input
              formNoValidate
              type={this.props.field.field_sub_type}
              required={this.props.field.required}
              name={name}
              onChange={this.props.onChange}
              label={this.props.field.label}
              help={this.props.field.instructions}
              value={value}
              disabled={!this.props.editing}
            />
          </div>
        );
        break;
      case "RadioGroup":
        break;
      case "Row":
        break;
      case "Select":
        fieldOptions = this.generateOptions(value, this.props.field);
        fieldComponent = (
          <div
            key={this.props.field.key}
            className={this.props.field.class.join(" ")}
          >
            <Select
              formNoValidate
              name={name}
              required={this.props.field.required}
              label={this.props.field.label}
              help={this.props.field.instructions}
              onChange={this.props.onChange}
              options={
                this.props.field.options.length > 0
                  ? this.props.field.options
                  : fieldOptions
              }
              value={this.props.value ? this.props.value : value}
              disabled={
                !this.props.editing ||
                // temporarily disable fields where dropdowns are not mapped
                (fieldOptions.length == 1 &&
                  this.props.field.options.length == 0)
                  ? true
                  : false
              }
            />
          </div>
        );
        break;
      case "File":
        break;
      case "Textarea":
        break;
      case "Form":
        break;
    }
    return fieldComponent;
  }
}

Field.propTypes = {
  actions: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  sysCodes: PropTypes.array.isRequired,
  chargetypes: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  parent: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  entity: PropTypes.string.isRequired
};

function mapStatesToProps(state, ownProps) {
  // let department = find(state.reducers.division.departments, {
  //   id: ownProps.params.deptId
  // });

  return {
    state: state.reducers,
    
    savingContact: state.reducers.savingContact,
    contact: state.reducers.contact,
    contract: state.reducers.contract,
    division: state.reducers.division,
    divisions: state.reducers.divisions,
    // department: department,
    glaccount: state.reducers.glaccount,
    zip: state.reducers.zip,
    role: state.reducers.role,
    item: state.reducers.item,
    accounts: state.reducers.accounts,
    account: state.reducers.account,
    chargetypes: state.reducers.chargetypes,
    user: state.reducers.user,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...accountActions,
        ...chargetypeActions,
        ...divisionActions
      },
      dispatch
    )
  };
}

export default withRouter(
  connect(
    mapStatesToProps,
    mapDispatchToProps
  )(Field)
);
