import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import { Row, Col } from "react-bootstrap";
import ProfileForm from "./ProfileForm";
import EditButton from "../common/EditButton";

class AccountSettings extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { editing: false };
  }

  handleSave = user => {
    let data = {
      id: this.props.auth.user.id,
      ...user
    };
    this.props.actions.updateUser(data);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleEdit = () => {
    this.setState({
      editing: !this.state.editing
    });
  };

  onChangePassword = () => {
    this.props.actions.getUser(this.props.auth.user.id, true);
  };

  handleChangePassword = password => {
    let data = {
      ...this.props.auth.user,
      password
    };
    this.props.actions.updateUser(data, true);
  };

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <Col md={7} xs={7}>
            <h1>Account Information</h1>
          </Col>
          <EditButton onClickEdit={this.handleEdit} />
          <Col md={12} xs={12}>
            <ProfileForm
              user={this.props.auth.user}
              editing={this.state.editing}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              saving={this.props.savingUser}
              onChangePassword={this.onChangePassword}
              handleChangePassword={this.handleChangePassword}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AccountSettings.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  auth: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    modal: state.reducers.modal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions, ...modalActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AccountSettings);
