import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";
import * as modalActions from "../../actions/modalActions";

import ContactForm from "../contact/ContactForm";
import ContactList from "../contact/ContactList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class AccountContacts extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount = () => {
    let { account, accounts, actions } = this.props;
    actions.getAccount(this.props.match.params.id);
    if (isEmpty(accounts)) actions.loadAccounts();
  };

  onClickDelete = contactId => {
    this.props.actions.requestContactId(contactId);
  };

  handleDelete = async () => {
    await this.props.actions.deleteContact(this.props.contactToDelete);
    await this.props.actions.getAccount(this.props.match.params.id);
  };

  onClickAdd = () => {
    this.props.actions.getAccount(this.props.match.params.id, true);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleSave = contact => {
    this.props.actions.createContact(contact);
  };

  render() {
    let { account, codes, savingContact, auth } = this.props;

    const contactAddForm = (
      <ContactForm
        contact={{ account: account._id }}
        accounts={this.props.accounts}
        saving={savingContact}
        codes={codes}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        editing={auth.maxRole >= 6000}
        adding
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${account.name}` }, { label: "Contacts" }]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {!isEmpty(account) && (
              <ContactList
                contacts={account.contacts}
                auth={auth}
                onClickView={id =>
                  this.props.history.push(`/app/contacts/${id}`)
                }
                onClickDelete={this.onClickDelete}
              />
            )}
          </Col>
        </Row>
        <Modal
          id="accountDetailsModal"
          title="Add Contact"
          body={contactAddForm}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="contactDeleteModal"
          title="Delete Contact"
          body="Are you sure you want to delete this contact?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

AccountContacts.propTypes = {
  actions: PropTypes.object.isRequired,
  labels: PropTypes.array,
  account: PropTypes.object,
  accounts: PropTypes.array,
  contactToDelete: PropTypes.string,
  params: PropTypes.object,
  modal: PropTypes.object,
  codes: PropTypes.array,
  savingContact: PropTypes.bool
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    account: state.reducers.account,
    accounts: state.reducers.accounts,
    contactToDelete: state.reducers.contactToDelete,
    savingContact: state.reducers.savingContact,
    modal: state.reducers.modal,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...accountActions,
        ...contactActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AccountContacts);
