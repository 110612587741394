import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const glaccounts = (state = initialState.glaccounts, action) => {
  switch (action.type) {
    case types.LOAD_GLACCOUNT_SUCCESS:
      return action.glaccounts;

    case types.CREATE_GLACCOUNT_SUCCESS:
      return [...state, Object.assign({}, action.glaccounts)];

    case types.UPDATE_GLACCOUNT_SUCCESS:
      return [
        ...state.filter(account => account.id !== action.glaccountId),
        Object.assign({}, action.glaccount)
      ];

    case types.DELETE_GLACCOUNT_SUCCESS:
      return [
        ...state.filter(account => account.id !== action.glaccountId),
        Object.assign({}, action.glaccount)
      ];

    default:
      return state;
  }
};

export const glaccount = (state = initialState.glaccount, action) => {
  switch (action.type) {
    case types.GET_GLACCOUNT_SUCCESS:
      return action.glaccount;

    default:
      return state;
  }
};

export const savingGlaccount = (
  state = initialState.savingGlaccount,
  action
) => {
  switch (action.type) {
    case types.SAVING_GLACCOUNT:
      return action.savingGlaccount;

    default:
      return state;
  }
};

export const glaccountToDelete = (
  state = initialState.glaccountToDelete,
  action
) => {
  switch (action.type) {
    case types.REQUEST_GLACCOUNT_ID:
      return action.glaccountToDelete;

    default:
      return state;
  }
};
