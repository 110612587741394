import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as invoiceActions from "../../actions/invoiceActions";

import Loading from "../common/Loading";
import ReactTable from "react-table";
import { Button } from "react-bootstrap";

import moment from "moment";
import isEmpty from "lodash/isEmpty";

class InvoiceApprovalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      selectAll: false,
      selected: {},
      pivotBy: "completed_by"
    };

    autoBind(this);
  }

  componentDidMount = () => {
    this.handleFilters(this.props);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filters !== this.props.filters ||
      nextProps.invoices !== this.props.invoices
    ) {
      this.handleFilters(nextProps);
    }
  }

  handleFilters = props => {
    let filters = props.filters;
    let filteredInvoices = props.invoices;
    let rows = [];

    // NUMBER
    if (filters.number !== this.props.filters.number) {
      filteredInvoices.map(invoices => {
        if (
          !filters.number.find(number => number.value === invoices._id) &&
          this.state.selected[invoices._id]
        ) {
          rows.push(invoices._id);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.number && filters.number.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.number.find(number => number.value === invoice._id)
          ? invoice
          : false
      );
    }

    // ACCOUNT
    if (filters.account !== this.props.filters.account) {
      filteredInvoices.map(invoices => {
        if (
          !filters.account.find(
            account => account.label === invoices.parent_account.name
          ) &&
          this.state.selected[invoices._id]
        ) {
          rows.push(invoices._id);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.account && filters.account.length) {
      filteredInvoices = filteredInvoices.filter(invoices =>
        filters.account.find(
          account => account.label === invoices.parent_account.name
        )
      );
    }

    // CONTRACT
    if (filters.contract !== this.props.filters.contract) {
      filteredInvoices.map(invoices => {
        if (
          !filters.contract.find(
            contract => contract.label === invoices.contract.name
          ) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.contract && filters.contract.length) {
      filteredInvoices = filteredInvoices.filter(invoices =>
        filters.contract.find(
          contract => contract.label === invoices.contract.name
        )
      );
    }

    // DIVISION
    if (filters.division !== this.props.filters.division) {
      filteredInvoices.map(invoices => {
        if (
          !filters.division.find(
            division => division.value === invoices.division._id
          ) &&
          this.state.selected[invoices._id]
        ) {
          rows.push(invoices._id);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.division && filters.division.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.division.find(div => div.value === invoice.division._id)
          ? invoice
          : false
      );
    }

    // DATE
    if (filters.start_time && filters.end_time) {
      filteredInvoices.map(invoices => {
        if (
          moment.utc(filters.start_time).startOf("day") <=
            moment.utc(invoices.document_date).startOf("day") &&
          moment.utc(filters.end_time).startOf("day") >=
            moment.utc(invoices.document_date).startOf("day")
        ) {
          return invoices;
        } else {
          if (this.state.selected[invoices._id]) {
            rows.push(invoices._id);
          }
        }
      });
      this.toggleRows(rows);
    } else if (filters.start_time) {
      filteredInvoices.map(invoices => {
        if (
          moment
            .utc(filters.start_time)
            .startOf("day")
            .format("MM/DD/YYYY") ==
          moment
            .utc(invoices.document_date)
            .startOf("day")
            .format("MM/DD/YYYY")
        ) {
          return invoices;
        } else {
          if (this.state.selected[invoices._id]) {
            rows.push(invoices._id);
          }
        }
      });
      this.toggleRows(rows);
    }

    if (filters.start_time && filters.end_time) {
      filteredInvoices = filteredInvoices.filter(invoice => {
        return moment.utc(filters.start_time).startOf("day") <=
          moment.utc(invoice.document_date).startOf("day") &&
          moment.utc(filters.end_time).startOf("day") >=
            moment.utc(invoice.document_date).startOf("day")
          ? invoice
          : false;
      });
    } else if (filters.start_time) {
      filteredInvoices = filteredInvoices.filter(invoice => {
        if (
          moment
            .utc(filters.start_time)
            .startOf("day")
            .format("MM/DD/YYYY") ==
          moment
            .utc(invoice.document_date)
            .startOf("day")
            .format("MM/DD/YYYY")
        ) {
          return invoice;
        }
      });
    }

    if (
      isEmpty(filters.account) &&
      isEmpty(filters.number) &&
      isEmpty(filters.division) &&
      !filters.start_time &&
      !filters.end_time
    ) {
      this.props.onClearInvoices();
    }

    this.setState({ invoices: filteredInvoices });
  };

  toggleRow = id => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.props.onToggle(id);
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
  };

  toggleRows = rows => {
    const newSelected = Object.assign({}, this.state.selected);
    rows.map(id => {
      this.props.onToggle(id);
      newSelected[id] = false;
    });

    this.setState({ selected: newSelected, selectAll: 2 });
  };

  toggleSelectAll = () => {
    let newSelected = {};

    if (this.state.selectAll == 0) {
      this.state.invoices.map(invoice => {
        newSelected[invoice._id] = true;
        this.props.onToggle(invoice._id);
      });
    } else if (this.state.selectAll == 1) {
      this.state.invoices.map(invoice => {
        newSelected[invoice._id] = false;
        this.props.onToggle(invoice._id);
      });
    } else if (this.state.selectAll == 2) {
      Object.entries(this.state.selected).map(invoice => {
        if (invoice[1]) {
          this.props.onToggle(invoice[0]);
        }
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll == 0 ? 1 : 0
    });
  };

  render() {
    const { loadingInvoice } = this.props;
    const { invoices, selectAll } = this.state;

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onClick={() => this.toggleSelectAll()}
            checked={selectAll == 1}
          />
        ),
        Cell: row => (
          <input
            type="checkbox"
            onClick={() => this.toggleRow(row.value)}
            checked={this.state.selected[row.original._id] === true}
          />
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center"
        }
      },
      {
        Header: "Number",
        id: "number",
        maxWidth: 120,
        accessor: d => d.number
      },
      {
        Header: "Account",
        accessor: "parent_account.name"
      },
      {
        Header: "Contract",
        accessor: "contract.name"
      },
      {
        Header: "Date",
        accessor: "document_date",
        Cell: row => <span>{moment.utc(row.value).format("MM/DD/YYYY")}</span>
      },
      {
        Header: "Division",
        id: "division",
        accessor: d => d.division.description
      },
      {
        Header: "Format",
        accessor: "format.label"
      },
      {
        Header: "Action",
        accessor: "_id",
        sortable: false,
        Cell: row => (
          <div className="text-center actions">
            <Button
              title="Preview"
              onClick={() => this.props.onClickPreview(row.value)}
            >
              Preview
            </Button>
          </div>
        )
      }
    ];

    const checkboxProps = {
      selectAll,
      selectType: "checkbox",
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const selected = this.state.selected[row.original._id] === true;
          return {
            style: {
              color: selected ? "#cf4a03" : "inherit",
              fontWeight: selected ? 500 : 400
            }
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (state, rowInfo, column) => {
        return {
          onClick: () => {
            if (rowInfo) {
              if (column.id !== "_id") {
                this.toggleRow(rowInfo.original._id, rowInfo.original.number);
              }
            }
          }
        };
      }
    };

    return (
      <ReactTable
        className={"-striped -highlight"}
        LoadingComponent={() => <Loading active={loadingInvoice} />}
        data={invoices}
        columns={columns}
        showPagination={true}
        {...checkboxProps}
        defaultPageSize={10}
        defaultSorted={[
          {
            id: "number",
            asc: true
          }
        ]}
      />
    );
  }
}

InvoiceApprovalList.propTypes = {
  invoicesToPrint: PropTypes.array,
  invoices: PropTypes.array,
  onToggle: PropTypes.func,
  onClickPreview: PropTypes.func,
  auth: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    invoicesToPrint: state.reducers.invoicesToPrint,
    loadingInvoice: state.reducers.loadingInvoice
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(InvoiceApprovalList);
