import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as zipActions from "../../actions/zipActions";
import * as modalActions from "../../actions/modalActions";
import * as labelActions from "../../actions/labelActions";

import ZipList from "./ZipList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";
import DynamicForm from "../common/DynamicForm";
import find from "lodash/find";

class ZipPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      add: false
    };

    autoBind(this);

    props.actions.loadZips();
  }

  componentDidMount = () => {
    this.props.actions.loadLabels();
  };

  onClickEdit(zipId) {
    this.props.actions.getZip(zipId, true);
  }

  onClickDelete(zipId) {
    this.props.actions.requestZipId(zipId);
  }

  handleCancel() {
    this.setState({ add: !this.state.add });
  }

  handleDelete() {
    this.props.actions.deleteZip(this.props.zipToDelete);
  }

  handleSave = async zip => {
    await this.props.actions.createZip(zip);
    await this.props.actions.loadZips();
    this.setState({ add: false });
  };

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Zip" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Zip Code</h4>
                  <DynamicForm
                    fieldData={find(this.props.labels, {
                      entity: "Zip"
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Zip"}
                    saving={this.props.savingZip}
                    editing={true}
                    adding={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ZipList
              zips={this.props.zips}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
            <Modal
              id="zipDetailsModal"
              title="Zip Info"
              body={this.props.zip.name}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="zipDeleteModal"
              title="Delete Zip"
              body="Are you sure you want to delete this zip?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ZipPage.propTypes = {
  actions: PropTypes.object,

  modal: PropTypes.object,
  savingZip: PropTypes.bool,
  labels: PropTypes.array,
  zipToDelete: PropTypes.string,
  zips: PropTypes.array.isRequired,
  zip: PropTypes.object.isRequired
};

function mapStatesToProps(state, ownProps) {
  let zip = {
    id: 0,
    zip: "",
    city: "",
    county: "",
    region: ""
  };

  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingZip: state.reducers.savingZip,
    labels: state.reducers.labels.labels,
    zipToDelete: state.reducers.zipToDelete,
    zips: state.reducers.zips.zips,
    zip: zip
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...zipActions,
        ...modalActions,
        ...labelActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ZipPage);
