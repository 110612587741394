import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contractActions from "../../actions/contractActions";
import * as modalActions from "../../actions/modalActions";

import AccountList from "./AccountList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import ConfirmModal from "../common/ConfirmModal";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class AccountPage extends Component {
  componentDidMount = () => {
    let { contracts, actions } = this.props;

    actions.loadAccounts();

    isEmpty(contracts) && actions.loadContracts();
  };

  onClickDelete = accountId => {
    this.props.actions.requestAccountId(accountId);
  };

  handleDelete = () => {
    this.props.actions.deleteAccount(this.props.accountToDelete);
  };

  render() {
    let { auth } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Accounts" }]} />
          <AddButton entity="accounts" auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <AccountList
              {...this.props}
              onClickView={id => this.props.history.push(`/app/accounts/${id}`)}
              onClickDelete={this.onClickDelete}
            />
          </Col>
        </Row>
        <ConfirmModal
          id="accountDeleteModal"
          title="Delete Account"
          body="Are you sure you want to delete this account?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

AccountPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  accounts: PropTypes.array.isRequired,
  accountToDelete: PropTypes.string,
  contracts: PropTypes.array.isRequired,
  codes: PropTypes.array.isRequired
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    accounts: state.reducers.accounts,
    accountToDelete: state.reducers.accountToDelete,
    contracts: state.reducers.contracts,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...accountActions, ...contractActions, ...modalActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AccountPage);
