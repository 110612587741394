import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";
import * as divisionActions from "../../actions/divisionActions";
import * as glaccountActions from "../../actions/glaccountActions";
import * as roleActions from "../../actions/roleActions";
import * as codeActions from "../../actions/codeActions";

import UserForm from "./UserForm";
import BreadCrumbs from "../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class UserAddPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    let { divisions, glaccounts, roles, actions } = this.props;

    // Load active users
    actions.loadUsers();

    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(glaccounts)) actions.loadGlaccounts();
    if (isEmpty(roles)) actions.loadRoles();
  }

  handleSave = user => {
    this.props.actions.createUser(user);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    let { saving } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Employees" }, { label: "Add User" }]}
          />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <UserForm
              user={{}}
              {...this.props}
              saving={saving}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              adding
              editing
            />
          </Col>
        </Row>
      </div>
    );
  }
}

UserAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
  labels: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
  divisions: PropTypes.array,
  glaccounts: PropTypes.array,
  codes: PropTypes.array,
  roles: PropTypes.array
};

function mapStatesToProps(state) {
  return {
    state: state,
    saving: state.reducers.savingUser,
    users: state.reducers.users,
    glaccounts: state.reducers.glaccounts,
    divisions: state.reducers.divisions,
    codes: state.reducers.codes,
    roles: state.reducers.roles.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...divisionActions,
        ...glaccountActions,
        ...roleActions,
        ...codeActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(UserAddPage);
