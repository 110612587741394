import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as glaccountActions from "../../actions/glaccountActions";
import * as modalActions from "../../actions/modalActions";

import * as labelActions from "../../actions/labelActions";
import GlaccountList from "./GlaccountList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";
import DynamicForm from "../common/DynamicForm";
import find from "lodash/find";

class GlaccountPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      add: false
    };

    props.actions.loadGlaccounts();
    autoBind(this);
  }

  componentDidMount = () => {
    this.props.actions.loadLabels();
  };

  onClickEdit(glaccountId) {
    this.props.actions.getGlaccount(glaccountId, true);
  }

  onClickDelete(glaccountId) {
    this.props.actions.requestGlaccountId(glaccountId);
  }

  handleDelete() {
    this.props.actions.deleteGlaccount(this.props.glaccountToDelete);
  }

  handleSave = async glaccount => {
    await this.props.actions.createGlaccount(glaccount);
    await this.props.actions.loadGlaccounts();
    this.setState({ add: false });
  };

  handleCancel() {
    this.setState({ add: false });
  }

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "GL Accounts" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add GL Account</h4>
                  <DynamicForm
                    fieldData={find(this.props.labels, {
                      entity: "Glaccount"
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Glaccount"}
                    saving={this.props.savingGlaccount}
                    editing={true}
                    adding={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <GlaccountList
              glaccounts={this.props.glaccounts}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
            <Modal
              id="glaccountDetailsModal"
              title="Glaccount Info"
              body={this.props.glaccount.name}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="glaccountDeleteModal"
              title="Delete Glaccount"
              body="Are you sure you want to delete this glaccount?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

GlaccountPage.propTypes = {
  actions: PropTypes.object,

  modal: PropTypes.object,
  savingGlaccount: PropTypes.bool,
  labels: PropTypes.array,
  glaccountToDelete: PropTypes.string,
  glaccounts: PropTypes.array.isRequired,
  glaccount: PropTypes.object.isRequired
};

function mapStatesToProps(state, ownProps) {
  let glaccount = {
    id: 0,
    description: "",
    revenue_code: 0
  };

  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingGlaccount: state.reducers.savingGlaccount,
    labels: state.reducers.labels.labels,
    glaccountToDelete: state.reducers.glaccountToDelete,
    glaccounts: state.reducers.glaccounts,
    glaccount: glaccount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...glaccountActions,
        ...modalActions,

        ...labelActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(GlaccountPage);
