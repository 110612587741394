import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import Loading from "../common/Loading";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import matchSorter from "match-sorter";
import find from "lodash/find";

const UserList = ({ users, loading, onClickView }) => {
  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["first_name"] }),
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["last_name"] }),
    },
    {
      Header: "Payroll ID",
      accessor: "payroll_id",
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["payroll_id"] }),
    },
    {
      Header: "Reports To",
      accessor: "reports_to",
      filterAll: true,
      Cell: (row) => {
        let reportsTo = find(users, { payroll_id: row.value });
        return reportsTo ? reportsTo.full_name : "--";
      },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            (row) => {
              let reportsTo = find(users, { payroll_id: row.reports_to });
              return reportsTo ? reportsTo.full_name : "--";
            },
          ],
        }),
      sortMethod: (a, b) => {
        let userA = find(users, { reports_to: a });
        let userB = find(users, { reports_to: b });

        if (userA && userB) {
          if (userA.last_name.length == userB.last_name.length) {
            return userA.last_name > userB.last_name ? 1 : -1;
          }
          return userA.last_name.length > userB.last_name.length ? 1 : -1;
        }
      },
    },
    {
      Header: "Title",
      accessor: "job_title",
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["job_title"] }),
    },
    {
      Header: "Role",
      accessor: "roles",
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            (row) => matchSorter(row.roles, filter.value, { key: ["name"] }),
          ],
        }),
      Cell: (row) =>
        row.value ? row.value.map((role) => role.name).join(", ") : "--",
    },
    {
      Header: "Division",
      accessor: "division.code",
      filterAll: true,
      maxWidth: 100,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [(row) => row["division.code"]],
        }),
    },
    {
      accessor: "_id",
      sortable: false,
      filterable: false,
      maxWidth: 120,
      Cell: (row) => {
        // let hasCalls = calls.find(
        //   (call) => call.assigned_to && call.assigned_to._id == row.value
        // )
        //   ? true
        //   : false;

        return (
          <div className="text-center actions">
            <DropdownButton title="Actions" id={"dropdown"}>
              <LinkContainer to={`/app/users/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              {/* {auth.maxRole == 9000 && (
                <MenuItem
                  data-tip="There are calls associated with this user."
                  data-for="hasCalls"
                  onClick={() => (!hasCalls ? onClickDelete(row.value) : "")}
                  disabled={hasCalls}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                  Delete
                </MenuItem>
              )}
              {hasCalls && (
                <ReactTooltip
                  id="hasCalls"
                  place="left"
                  type="dark"
                  effect="solid"
                />
              )} */}
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        },
      };
    },
  };

  return (
    <ReactTable
      filterable
      className="-highlight"
      data={users}
      LoadingComponent={() => <Loading active={loading} />}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "first_name",
          asc: true,
        },
      ]}
    />
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      full_name: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default UserList;
