import { push } from 'react-router-redux';
import * as types from './actionTypes';
import { showModalSuccess } from './modalActions';
import { showAlertSuccess, hideAlertSuccess } from './alertActions';
import zipService from '../services/zipService';

export function loadZipSuccess(zips) {
  return {
    type: types.LOAD_ZIP_SUCCESS,
    zips
  };
}

export function getZipSuccess(zip) {
  return {
    type: types.GET_ZIP_SUCCESS,
    zip
  };
}

export function savingZip(status = true) {
  return {
    type: types.SAVING_ZIP,
    savingZip: status
  };
}

export function createZipSuccess(zip) {
  return {
    type: types.CREATE_ZIP_SUCCESS,
    zip
  };
}

export function updateZipSuccess(zip) {
  return {
    type: types.UPDATE_ZIP_SUCCESS,
    zip
  };
}

export function requestZipId(zipId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_ZIP_ID,
      zipToDelete: zipId
    });
    dispatch(showModalSuccess('zipDeleteModal'));
  };
}

export function deleteZipSuccess(zipId) {
  return {
    type: types.DELETE_ZIP_SUCCESS,
    zipId
  };
}

export function loadZips() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return zipService
      .loadZips()
      .then(data => dispatch(loadZipSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function getZip(id, showZIPDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return zipService
      .getZip(id)
      .then(zip => {
        dispatch(getZipSuccess(zip));
        if (showZIPDetails) {
          dispatch(showModalSuccess('zipDetailsModal'));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function createZip(zip) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingZip());
    return zipService
      .createZip(zip)
      .then(createdZip => {
        dispatch(createZipSuccess(createdZip));
        dispatch(savingZip(false));
        dispatch(showAlertSuccess('Zip created successfully', 'success'));
        dispatch(push('/app/settings/zips'));
      })
      .catch(error => {
        dispatch(savingZip(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateZip(zip) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingZip());
    return zipService
      .updateZip(zip)
      .then(updatedZip => {
        dispatch(updateZipSuccess(updatedZip));
        dispatch(savingZip(false));
        dispatch(showAlertSuccess('Zip updated successfully', 'success'));
        dispatch(push('/app/settings/zips'));
      })
      .catch(error => {
        dispatch(savingZip(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteZip(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return zipService
      .deleteZip(id)
      .then(() => {
        dispatch(deleteZipSuccess(id));
        dispatch(showAlertSuccess('Zip removed', 'success'));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}
