import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contactActions from "../../actions/contactActions";

import ContactForm from "./ContactForm";
import BreadCrumbs from "../common/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class ContactAddPage extends Component {
  componentDidMount = () => {
    let { accounts, actions } = this.props;
    if (isEmpty(accounts)) actions.loadAccounts();
  };

  handleSave = contact => {
    this.props.actions.createContact(contact);
  };

  handleCancel = () => {
    this.props.actions.cancelAddContact();
  };

  render() {
    let { saving, accounts, codes } = this.props;
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Contacts" }, { label: "Add Contact" }]}
          />
        </Row>
        <Row>
          <Col md={12}>
            <ContactForm
              contact={{}}
              saving={saving}
              accounts={accounts}
              codes={codes}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              editing
              adding
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ContactAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingContact: PropTypes.bool,
  contact: PropTypes.object,
  accounts: PropTypes.array,
  codes: PropTypes.array
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    saving: state.reducers.savingContact,
    accounts: state.reducers.accounts,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...contactActions, ...accountActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContactAddPage);
