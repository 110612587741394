import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";

import * as callActions from "../../actions/callActions";
import * as contractActions from "../../actions/contractActions";
import * as divisionActions from "../../actions/divisionActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as glaccountActions from "../../actions/glaccountActions";
import * as itemActions from "../../actions/itemActions";
import * as invoiceActions from "../../actions/invoiceActions";
import * as userActions from "../../actions/userActions";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerRangeController } from "react-dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faRedo } from "@fortawesome/free-solid-svg-icons";
import BreadCrumbs from "../common/BreadCrumbs";

import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import moment from "moment";
import PapaParse from "papaparse";
import FileSaver from "file-saver";

class AdpPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: "startDate",
      adpSyncDisabled: false,
      lastAdpUpdate: null
    };
  }

  componentDidMount = () => {
    let {
      contracts,
      items,
      chargetypes,
      divisions,
      glaccounts,
      invoices,
      users,
      actions
    } = this.props;

    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(items)) actions.loadItems();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(glaccounts)) actions.loadGlaccounts();
    if (isEmpty(invoices)) actions.loadInvoices();
    actions.loadUsers();
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.calls !== this.props.calls) {
      this.setState({ calls: nextProps.calls }, () => {
        let csv = PapaParse.unparse(this.makeADPObject());
        FileSaver.saveAs(new Blob([csv], { type: "text/csv" }), "Export.csv");
      });
    }
  };

  findCallsForWeek = () => {
    let { codes, auth } = this.props;
    let stages = codes
      .filter(
        code =>
          code.entity == "Call" &&
          (code.value == "Approved" ||
            code.value == "Invoiced" ||
            code.value == "Non-Invoiced")
      )
      .map(code => code._id);

    this.props.actions.loadCallsByQuery({
      auth,
      stage: stages,
      start_time: { $gte: new Date(this.state.startDate.toISOString()) },
      end_time: { $lte: new Date(this.state.endDate.toISOString()) }
    });
  };

  makeADPObject = () => {
    let calls = this.state.calls;
    let rows = [];

    let callsByPayWeek = groupBy(calls, call => {
      // let date = moment.utc(call.start_time).format("MM/DD/YYYY");
      let date = call.start_time;
      let sunday = moment(date)
        .day("Sunday")
        .format("MM/DD/YYYY");
      let saturday = moment(date)
        .day("Saturday")
        .format("MM/DD/YYYY");

      return `${call.assigned_to.full_name} (${sunday} - ${saturday})`;
    });

    Object.values(callsByPayWeek).map(calls => {
      let overTime = 0;
      let totalHours = 0;
      let hrs3codes = [
        { value: "10", label: "PTO" },
        { value: "H", label: "Holiday" },
        { value: "J", label: "Jury Duty" },
        { value: "B", label: "Bereavement" },
        { value: "S", label: "Sick" }
      ];

      calls.map(call => {
        // console.log("call", call);
        let contract = find(this.props.contracts, { _id: call.contract._id });
        call.entries.map(entry => {
          let hours = 0;
          let personalHours = 0;
          let hrs3code;

          let employee = call.assigned_to;
          let classification = find(this.props.codes, { _id: employee.class });
          let expenseCode = find(this.props.glaccounts, {
            id: employee.gl_expense
          });

          let contractLine = find(contract.lines, { _id: entry.procedure });
          let department;
          if (contractLine) {
            let division = find(this.props.divisions, {
              _id: contractLine.division._id
            });

            department = find(division.departments, {
              _id: contractLine.department
            });
          }

          let chargetype = find(this.props.chargetypes, {
            id: entry.charge_type
          });

          // Don't calculate overtime for per diem employees
          // if (classification.label !== "Per Diem") {
          if (
            chargetype.type == "Billable" ||
            chargetype.type == "Non-Billable"
          ) {
            hours = entry.quantity;
            totalHours += entry.quantity;
          } else if (
            chargetype.type == "Personal" ||
            chargetype.type == "Overhead"
          ) {
            let contractLine = find(call.contract.lines, {
              _id: entry.procedure
            });

            let personalItem = find(this.props.items, {
              _id: contractLine.item
            });

            hrs3code = personalItem.hrs3code;

            personalHours = entry.quantity;
          }

          if (overTime == 0 && totalHours >= 40) {
            overTime = totalHours - 40;
            hours = hours - overTime;
            // after initial overtime, add on additional hours
          } else if (totalHours >= 40) {
            overTime = hours;
            hours = hours - overTime;
          }

          const payrollId = call.assigned_to.payroll_id;

          let adpObj = {
            "Employee ID": payrollId,
            DATEDOC: moment
              .utc(call.start_time)
              .format("MM/DD/YYYY")
              .toString(),
            "Overtime Hrs": overTime,
            "Expense Code": `${expenseCode.code}`,
            ESDept: department ? department.dept_id : "",
            "Rate Code": "",
            "Hours / Contract Hrs": hours,
            "HRS3 Code": hrs3code,
            "HRS3 Amount": personalHours
          };

          rows.push(adpObj);
          // }
        });
      });
    });

    return orderBy(rows, "DATEDOC");
  };

  handleChangeDate = (startDate, endDate) => {
    let start_date;
    let end_date;

    if (this.state.focusedInput == "endDate") {
      start_date = moment(endDate)
        .subtract(2, "w")
        .startOf("week");
      end_date = moment(endDate)
        .subtract(1, "w")
        .endOf("week");
      if (endDate == null) {
        start_date = moment(startDate)
          .subtract(2, "w")
          .startOf("week");
        end_date = moment(startDate)
          .subtract(1, "w")
          .endOf("week");
      }
    } else {
      end_date = moment(startDate)
        .subtract(1, "w")
        .endOf("week");

      start_date = moment(startDate)
        .subtract(2, "w")
        .startOf("week");
    }

    this.setState(
      {
        startDate: start_date,
        endDate: end_date
      },
      () => console.log(this.state)
    );
  };

  disableSyncButton = () => {
    this.setState({ adpSyncDisabled: true });
  };

  enableSyncButton = () => {
    this.setState({ adpSyncDisabled: false });
  };

  handleAdpSync = async () => {
    await this.disableSyncButton();
    await this.props.actions.syncAdpWorker();
    await this.props.actions.syncAdpCertData();
    await this.enableSyncButton();
  };

  render() {
    let { startDate, endDate, focusedInput } = this.state;

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "ADP" }]}
          />
        </Row>
        <Row>
          <Col md={9}>
            <h2>Payroll Export</h2>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-success pull-right"
              type="button"
              onClick={this.findCallsForWeek}
            >
              <FontAwesomeIcon icon={faFile} />
              {this.props.loadingCall ? "Exporting" : "Export"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={2}
              focusedInput={focusedInput}
              enableOutsideDays={true}
              onDatesChange={({ startDate, endDate }) => {
                this.handleChangeDate(startDate, endDate);
              }}
              onFocusChange={focusedInput =>
                this.setState({ focusedInput: focusedInput || "startDate" })
              }
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={9}>
            <h2>Employee Sync</h2>
          </Col>
          <Col md={3}>
            <button
              className="btn btn-success pull-right"
              type="button"
              disabled={this.state.adpSyncDisabled}
              onClick={this.handleAdpSync}
            >
              <FontAwesomeIcon icon={faRedo} />
              Sync
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

AdpPage.propTypes = {
  actions: PropTypes.object,
  calls: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  items: PropTypes.array,
  divisions: PropTypes.array,
  glaccounts: PropTypes.array,
  chargetypes: PropTypes.array,
  invoices: PropTypes.array,
  invoice: PropTypes.object,
  users: PropTypes.array
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    contracts: state.reducers.contracts,
    items: state.reducers.items,
    loadingCall: state.reducers.loadingCall,
    chargetypes: state.reducers.chargetypes,
    glaccounts: state.reducers.glaccounts,
    divisions: state.reducers.divisions,
    invoices: state.reducers.invoices,
    invoice: state.reducers.invoice,
    users: state.reducers.users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...callActions,
        ...contractActions,
        ...chargetypeActions,
        ...divisionActions,
        ...itemActions,
        ...glaccountActions,
        ...invoiceActions,
        ...userActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(AdpPage);
