import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";

import * as userActions from "../actions/userActions";
import * as codeActions from "../actions/codeActions";
import * as alertActions from "../actions/alertActions";

import LoginPage from "./login/LoginPage";
import ResetPage from "./reset/ResetPage";
import ForgotPage from "./forgot/ForgotPage";
import PrivateRoute from "./routes/PrivateRoute";
import UserRoute from "./routes/UserRoute";
import ErrorHandler from "./common/ErrorHandler";
import MaintenancePage from "./MaintenancePage";
import windowSize from "react-window-size";
import IdleTimer from "react-idle-timer";
import { alertMessage } from "../helpers";

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isAuthenticated: false };
  }

  componentDidMount = () => {
    let { router, actions } = this.props;
    if (router.currentLocation && !router.currentLocation.includes("/reset")) {
      const token = localStorage.getItem("es_token");
      actions.checkAuth(token);
    }

    if (this.props.auth.isAuth) {
      actions.loadCodes();
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.auth !== this.props.auth) {
      this.setState({ isAuthenticated: nextProps.auth.isAuth }, () => {
        if (this.state.isAuthenticated) this.props.actions.loadCodes();
      });
    }
  };

  componentWillUpdate = (nextProps) => {
    if (nextProps.alert !== this.props.alert) {
      alertMessage(nextProps.alert);
    }
  };

  onIdle = () => {
    console.log("Logging out user...");
    this.props.actions.logoutUser();
  };

  render() {
    const { auth, windowWidth } = this.props;
    const { isAuthenticated } = this.state;

    let maintenanceMode = false; // process.env.MAINTENANCE_MODE;

    return (
      <ErrorHandler>
        <div className="container-fluid">
          <IdleTimer
            element={document}
            onIdle={this.onIdle}
            onActive={this.onActive}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 30}
          />
          {!maintenanceMode ? (
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route exact path="/" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/forgot" component={ForgotPage} />
              <Route path="/reset/:token" component={ResetPage} />
              {/* Protected */}
              {isAuthenticated && (
                <PrivateRoute
                  path="/app"
                  component={UserRoute}
                  auth={auth}
                  isMobile={windowWidth < 768}
                  handleLogout={this.logout}
                />
              )}
            </Switch>
          ) : (
            <Switch>
              <Redirect exact from="/login" to="/" />
              <Route exact path="/" component={MaintenancePage} />
            </Switch>
          )}
        </div>
      </ErrorHandler>
    );
  }
}

App.propTypes = {
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  alert: PropTypes.object,
};

function mapStatesToProps(state) {
  return {
    state: state,
    alert: state.reducers.alert,
    auth: state.reducers.auth,
    router: state.reducers.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...userActions, ...alertActions, ...codeActions },
      dispatch
    ),
  };
}

export default windowSize(
  withRouter(connect(mapStatesToProps, mapDispatchToProps)(App))
);
