/* eslint-disable import/default */

import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";

import { configureStore } from "./store/configureStore";
import { Provider } from "react-redux";

import { ConnectedRouter, push } from "react-router-redux";
import { createBrowserHistory } from "history";
import App from "./components/App";

// CSS Imports
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/toastr/build/toastr.min.css";
import "react-dates/lib/css/_datepicker.css";
import "animate.css";
import "react-dates/initialize";
import "./styles/styles.scss";
import "react-table/react-table.css";

// Third Party Imports
import ReactGA from "react-ga";
import axios from "axios";
import isObject from "lodash/isObject";

ReactGA.initialize("UA-79143266-39");

const history = createBrowserHistory();
const store = configureStore({}, history);

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    if (!config.url.includes("adp")) {
      // Append token on every api request, except to ADP
      let token = localStorage.getItem("es_token");
      let username = localStorage.getItem("username");

      if (token) {
        if (config.data) {
          for (let key in config.data) {
            if (
              isObject(config.data[key]) &&
              !Array.isArray(config.data[key])
            ) {
              // append active user to all requests w/ body to maintain audit trail
              if (config.method == "post") {
                config.data[key].created_by = username;
              } else {
                config.data[key].modified_by = username;
              }
            }
          }
        }

        // Append token to all request headers
        config.headers.Authorization = "Bearer " + token;
        config.headers.username = username;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  function(error) {
    console.log("error", error);
    if (error && error.response) {
      if (error.response.status == 401 || error.response.status == 403) {
        store.dispatch(push("/login"));
      }
    }
    return Promise.reject(error);
  }
);

function fireTracking() {
  ReactGA.pageview(window.location.pathname);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/App", () =>
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>,
      document.getElementById("app")
    )
  );
}
