import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { LinkContainer } from "react-router-bootstrap";
import orderBy from "lodash/orderBy";

import CallService from "../../services/callService";
import * as callActions from "../../actions/callActions";

import Loading from "../common/Loading";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, MenuItem } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

class CallApprovalList extends Component {
  state = {
    selectAll: false,
    selected: {},
    calls: []
  };

  componentWillUnmount = () => {
    this.setState({ selectAll: false, selected: {} });
  };

  componentDidMount = () => {
    this.handleFilters(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.filters !== this.props.filters ||
      nextProps.calls !== this.props.calls
    ) {
      this.handleFilters(nextProps);
    }
  };

  handleFilters = props => {
    let filters = props.filters;
    let filteredCalls = props.calls;
    let rows = [];
    //filter for active/inactive users
    if (filters.active === "A") {
      filteredCalls = filteredCalls.filter(call => {
        return call.assigned_to.termination_date == "";
      });
    }
    if (filters.active === "I") {
      filteredCalls = filteredCalls.filter(call => {
        return call.assigned_to.termination_date != "";
      });
    }
    //other filters
    if (
      isEmpty(filters.contract) &&
      isEmpty(filters.assigned_to) &&
      isEmpty(filters.parent_account) &&
      isEmpty(filters.division) &&
      isEmpty(filters["assigned_to.job_title"]) &&
      !filters.start_time &&
      !filters.end_date
    ) {
      this.setState({ selectAll: 2, selected: {} });
      this.props.onClearCalls();
      this.setState({ calls: filteredCalls });
    } else {
      // ACCOUNT
      if (filters.parent_account !== this.props.filters.parent_account) {
        filteredCalls.map(call => {
          if (
            !filters.parent_account.find(
              account => account.value === call.parent_account._id
            ) &&
            this.state.selected[call._id]
          ) {
            rows.push(call._id);
          }
        });
        this.toggleRows(rows);
      }
      if (filters.parent_account && filters.parent_account.length) {
        filteredCalls = filteredCalls.filter(call =>
          filters.parent_account.find(
            account => account.value === call.parent_account._id
          )
        );
      }

      // CONTRACT
      if (filters.contract !== this.props.filters.contract) {
        filteredCalls.map(call => {
          if (
            !filters.contract.find(
              contract => contract.value === call.contract._id
            ) &&
            this.state.selected[call._id]
          ) {
            rows.push(call._id);
          }
        });
        this.toggleRows(rows);
      }
      if (filters.contract && filters.contract.length) {
        filteredCalls = filteredCalls.filter(call =>
          filters.contract.find(
            contract => contract.value === call.contract._id
          )
        );
      }

      // EMPLOYEE
      if (filters.assigned_to !== this.props.filters.assigned_to) {
        // console.log("assigned to", filters.assigned_to);
        // console.log("assigned to props", this.props.filters.assigned_to);
        filteredCalls.map(call => {
          if (
            !filters.assigned_to.find(
              user => user.value === call.assigned_to._id
            ) &&
            this.state.selected[call._id]
          ) {
            rows.push(call._id);
          }
        });
        this.toggleRows(rows);
      }
      if (filters.assigned_to && filters.assigned_to.length) {
        filteredCalls = filteredCalls.filter(
          call =>
            call.assigned_to &&
            filters.assigned_to.find(
              user => user.value === call.assigned_to._id
            )
        );
        filteredCalls = orderBy(filteredCalls, "start_time");
      }

      // DIVISION
      if (filters.division !== this.props.filters.division) {
        filteredCalls.map(call => {
          if (
            !filters.division.find(
              division => division.value == call.division
            ) &&
            this.state.selected[call._id]
          ) {
            rows.push(call._id);
          }
        });
        this.toggleRows(rows);
      }
      if (filters.division && filters.division.length) {
        filteredCalls = filteredCalls.filter(call =>
          filters.division.find(division => division.value == call.division)
        );
      }

      if (
        filters["assigned_to.job_title"] !==
        this.props.filters["assigned_to.job_title"]
      ) {
        filteredCalls.map(call => {
          if (
            !filters["assigned_to.job_title"].find(title => {
              let user = this.props.users.find(
                u => u._id == call.assigned_to._id
              );
              if (!call.assigned_to);
              return user && title.value === user.job_title;
            }) &&
            this.state.selected[call._id]
          ) {
            rows.push(call._id);
          }
        });
        this.toggleRows(rows);
      }
      if (
        filters["assigned_to.job_title"] &&
        filters["assigned_to.job_title"].length
      ) {
        let user;
        filteredCalls = filteredCalls.filter(call =>
          filters["assigned_to.job_title"].find(title => {
            user = this.props.users.find(
              user => user._id === call.assigned_to._id
            );
            return user && title.value === user.job_title;
          })
            ? call
            : false
        );
      }

      // DATE
      if (filters.start_time && filters.end_time) {
        filteredCalls.map(call => {
          if (
            moment(filters.start_time).startOf("day") <=
              moment(call.start_time).startOf("day") &&
            moment(filters.end_time).startOf("day") >=
              moment(call.end_time).startOf("day")
          ) {
            return call;
          } else {
            if (this.state.selected[call._id]) {
              rows.push(call._id);
            }
          }
        });

        if (
          moment(filters.start_time).format("MM/DD/YY") !==
            moment(this.props.filters.start_time).format("MM/DD/YY") ||
          moment(filters.end_time).format("MM/DD/YY") !==
            moment(this.props.filters.end_time).format("MM/DD/YY")
        ) {
          this.toggleRows(rows);
        }

        filteredCalls = filteredCalls.filter(call => {
          if (
            moment(filters.start_time).startOf("day") <=
              moment(call.start_time).startOf("day") &&
            moment(filters.end_time).startOf("day") >=
              moment(call.end_time).startOf("day")
          ) {
            return call;
          }
        });
      } else if (filters.start_time) {
        filteredCalls.map(call => {
          if (
            moment(filters.start_time).startOf("day") <=
              moment(call.start_time).startOf("day") &&
            moment(filters.end_date).startOf("day") >=
              moment(call.end_time).startOf("day")
          ) {
            return call;
          } else {
            if (this.state.selected[call._id]) {
              rows.push(call._id);
            }
          }
        });

        if (
          moment(filters.start_time).format("MM/DD/YY") !==
          moment(this.props.filters.start_time).format("MM/DD/YY")
        ) {
          this.toggleRows(rows);
        }

        filteredCalls = filteredCalls.filter(call => {
          if (
            moment(call.start_time).format("MM/DD/YYYY") >=
            moment(filters.start_time).format("MM/DD/YYYY")
          ) {
            return call;
          }
        });
      }
      this.setState({ calls: filteredCalls });
    }
  };

  toggleRow = id => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.props.onToggle(id);
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
  };

  toggleRows = rows => {
    const newSelected = Object.assign({}, this.state.selected);
    rows.map(id => {
      this.props.onToggle(id);
      newSelected[id] = false;
    });

    // this.setState({ selected: newSelected, selectAll: 2 });
    this.setState({ selected: {}, selectAll: 0 });
    this.props.actions.clearApprovedCalls();
  };

  toggleSelectAll = () => {
    let newSelected = {};
    let calls = this.state.calls;

    if (this.state.selectAll == 0) {
      this.props.onToggleAll(calls.map(call => call._id));
      calls.map(call => {
        newSelected[call._id] = true;
      });
    } else if (this.state.selectAll == 1) {
      this.props.onToggleAll(calls.map(call => call._id));
      calls.map(call => {
        newSelected[call._id] = false;
      });
    } else if (this.state.selectAll == 2) {
      Object.entries(this.state.selected).map(call => {
        if (call[1]) {
          this.props.onToggle(call[0]);
        }
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll == 0 ? 1 : 0
    });
  };

  render() {
    const { filters } = this.props;
    const { selectAll, calls } = this.state;

    const columns = [
      { expander: true, show: false },
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onClick={() => this.toggleSelectAll()}
            checked={selectAll == 1}
          />
        ),
        Cell: row => (
          <input
            type="checkbox"
            onClick={() => this.toggleRow(row.value)}
            checked={this.state.selected[row.original._id] === true}
          />
        ),
        Footer: (
          <span className="footer-totals">
            <span className="total">
              <label>Total Hours: </label>
              {calls.length
                ? calls
                    .filter(call => call.quantity)
                    .reduce((total, call) => total + call.quantity, 0)
                    .toFixed(2)
                : "--"}
            </span>
          </span>
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center"
        }
      },
      {
        Header: "Employee",
        id: "assigned_to",
        accessor: "assigned_to",
        Cell: ({ row, value }) => (
          <span>
            {value.full_name
              ? value.full_name
              : `${value.last_name}, ${value.first_name}`}
          </span>
        )
      },
      {
        Header: "Date of Service",
        id: "start_time",
        accessor: d => d.start_time,
        Cell: row => {
          return <span>{moment(row.value).format("MM/DD/YYYY")}</span>;
        }
      },
      {
        Header: "Contract",
        accessor: "contract.name",
        Cell: row => {
          return <span>{row.value}</span>;
        }
      },
      {
        Header: "Duration",
        accessor: "quantity",
        Cell: row => {
          return (
            <span
              onClick={e => this.props.onClickDetail(e, row.original._id)}
              className="table-action"
            >
              {row.value ? row.value.toFixed(2) : "--"}
              <FontAwesomeIcon icon={faSearch} />
            </span>
          );
        }
      },
      {
        Header: "Billing Quantity",
        accessor: "billing_quantity",
        Cell: ({ row, value }) => <span>{value ? value.toFixed(2) : "--"}</span>
      },
      {
        Header: "",
        accessor: "_id",
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="text-center actions">
            <DropdownButton title="Actions" id={`dropdown`}>
              <LinkContainer to={`/app/calls/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              <MenuItem onClick={() => this.props.onClickDelete(row.value)}>
                <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
                Delete
              </MenuItem>
            </DropdownButton>
          </div>
        )
      }
    ];

    const subColumns = [
      {
        Header: "Procedure",
        accessor: "procedure",
        Cell: row => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, call => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call._id });
          }

          if (call) {
            let contract = find(this.props.contracts, {
              _id: call.contract._id || call.contract
            });
            if (contract) {
              let procedure = contract
                ? find(contract.lines, { _id: row.value }) ||
                  find(this.props.items, { id: row.value })
                : "";
              return procedure ? procedure.item.description : "--";
            }
          }
          return "--";
        }
      },
      {
        Header: "Duration",
        accessor: "quantity",
        Cell: row => (row.value ? row.value.toFixed(2) : "--")
      },
      {
        Header: "Quantity",
        accessor: "billing_quantity",
        Cell: row => (row.value ? row.value.toFixed(2) : "--")
      },
      {
        Header: "UOM",
        accessor: "procedure",
        sortable: false,
        Cell: row => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, call => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call._id });
          }

          let procedure =
            call && call.contract
              ? find(call.contract.lines, { _id: row.value }) ||
                find(this.props.items, { id: row.value })
              : "";
          return procedure ? procedure.uom.value : "--";
        }
      },
      {
        Header: "Client",
        accessor: "client",
        Cell: row => {
          let call = row.original.call;
          if (!call) {
            call = find(this.props.calls, call => {
              let entry = find(call.entries, { _id: row.original._id });
              if (entry) return call;
            });
          } else {
            call = find(this.props.calls, { _id: call._id });
          }
          let client = call && find(call.contract.clients, { _id: row.value });
          return client ? client.name : "--";
        }
      }
    ];

    const checkboxProps = {
      selectAll,
      selectType: "checkbox",
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const selected = this.state.selected[row.original._id] === true;
          return {
            style: {
              color: selected ? "#cf4a03" : "inherit",
              fontWeight: selected ? 500 : 400
            }
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (state, rowInfo, column) => {
        return {
          onClick: () => {
            if (column.id !== "_id") {
              this.toggleRow(rowInfo.original._id);
            }
          }
        };
      }
    };

    return (
      <ReactTable
        className="-grouped"
        data={calls}
        columns={columns}
        defaultPageSize={calls.length ? calls.length : 10}
        showPagination={true}
        expanded={calls.map(x => true)}
        defaultSorted={[
          {
            id: "assigned_to",
            asc: true
          }
        ]}
        {...checkboxProps}
        SubComponent={row => (
          <div
            style={{
              padding: "0 60px 20px 60px",
              width: "100%",
              margin: "0 auto"
            }}
          >
            <ReactTable
              className="subtable"
              data={row.original.entries}
              columns={subColumns}
              showPagination={false}
              pageSize={row.original.entries.length}
            />
          </div>
        )}
      />
    );
  }
}

CallApprovalList.propTypes = {
  callsToApprove: PropTypes.array,
  calls: PropTypes.array.isRequired,
  items: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  onToggle: PropTypes.func,
  onClickDetail: PropTypes.func,
  onClickDelete: PropTypes.func,
  auth: PropTypes.object,
  filter: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    callsToApprove: state.reducers.callsToApprove,
    loadingCall: state.reducers.loadingCall
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...callActions }, dispatch)
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallApprovalList);
