import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import autoBind from "react-autobind";

import Formsy from "formsy-react";
import { Input, Checkbox } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";

class ChargetypeForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false
    };

    autoBind(this);
  }

  enableButton() {
    this.setState({
      canSubmit: true
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false
    });
  }

  submit(model) {
    this.props.onSave(model);
  }

  resetForm() {
    this.refs.form.reset();
  }

  render() {
    let { chargetype, editing, saving } = this.props;
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Col md={12}>
          <Input
            formNoValidate
            required
            name="name"
            label="Name"
            placeholder="Name"
            disabled={!editing}
            value={chargetype.type}
          />
        </Col>
        <Col md={4} xs={4}>
          <Checkbox
            formNoValidate
            name="overtime"
            label="Overtime"
            value={chargetype.overtime}
            disabled={!editing}
          />
        </Col>
        <Col md={4} xs={4}>
          <Checkbox
            formNoValidate
            name="employee_entry"
            label="Employee Entry"
            value={chargetype.employee_entry}
            disabled={!editing}
          />
        </Col>
        <Col md={12} xs={12} className="action-footer">
          <Link className="btn btn-danger" to="/app/settings/chargetypes">
            Cancel
          </Link>
          <input
            className="btn btn-success"
            type="submit"
            disabled={!this.state.canSubmit}
            value={saving ? "Saving... " : "Save"}
          />
        </Col>
      </Formsy>
    );
  }
}

ChargetypeForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  chargetype: PropTypes.object.isRequired
};

export default ChargetypeForm;
