import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as divisionActions from "../../actions/divisionActions";
import * as modalActions from "../../actions/modalActions";
import * as labelActions from "../../actions/labelActions";

import DivisionList from "./DivisionList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";

import { Row, Col, Collapse } from "react-bootstrap";
import DivisionForm from "./DivisionForm";
// import DepartmentForm from './DepartmentForm';
import DynamicForm from "../common/DynamicForm";
import find from "lodash/find";

class DivisionPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { add: false };

    autoBind(this);

    props.actions.loadDivisions();
  }

  componentDidMount = () => {
    this.props.actions.loadLabels();
  };

  onClickEdit = divisionId => {
    this.props.actions.getDivision(divisionId, true);
  };

  onClickDelete = divisionId => {
    this.props.actions.requestDivisionId(divisionId);
  };

  onClickDeleteDept = departmentId => {
    this.props.actions.requestDepartmentId(departmentId);
  };

  handleCancel = () => {
    this.setState({ add: false });
  };

  handleDelete = () => {
    this.props.actions.deleteDivision(this.props.divisionToDelete);
  };

  handleDeleteDept = async () => {
    let department = this.props.departmentToDelete;
    let division = {
      id: department.division
    };
    await this.props.actions.deleteDepartment(
      division,
      this.props.departmentToDelete
    );
    await this.props.actions.loadDivisions();
  };

  handleSave = async division => {
    this.setState({ add: false });
    await this.props.actions.createDivision(division);
    await this.props.actions.loadDivisions();
  };

  handleSaveDept = async department => {
    this.setState({ add: false });
    let division = { id: department.division };
    await this.props.actions.createDepartment(division, department);
    await this.props.actions.loadDivisions();
  };

  render() {
    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Settings" }, { label: "Divisions" }]}
          />
          <AddButton
            onClickAdd={() => this.setState({ add: !this.state.add })}
          />
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.add}>
              <div>
                <Row>
                  <h4>Add Division</h4>
                  <DynamicForm
                    ref="form"
                    fieldData={find(this.props.labels, {
                      entity: "Division"
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    entity={"Division"}
                    saving={this.props.savingDivision}
                    editing={true}
                    adding={true}
                  />
                </Row>
                <Row>
                  <h4>Add Department</h4>
                  <DynamicForm
                    ref="form"
                    fieldData={find(this.props.labels, {
                      entity: "Department"
                    })}
                    onCancel={this.handleCancel}
                    onSave={this.handleSaveDept}
                    entity={"Department"}
                    saving={this.props.savingDivision}
                    editing={true}
                    adding={true}
                  />
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DivisionList
              divisions={this.props.divisions.filter(
                division => division._id != null
              )}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
              onClickDeleteDept={this.onClickDeleteDept}
            />
            <Modal
              id="divisionDetailsModal"
              title="Division Info"
              body={this.props.division.name}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
            <ConfirmModal
              id="divisionDeleteModal"
              title="Delete Division"
              body="Are you sure you want to delete this division?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDelete}
            />
            <ConfirmModal
              id="departmentDeleteModal"
              title="Delete Department"
              body="Are you sure you want to delete this department?"
              modal={this.props.modal}
              close={this.props.actions.hideModal}
              confirm={this.handleDeleteDept}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DivisionPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  savingDivision: PropTypes.bool,
  labels: PropTypes.array,
  divisionToDelete: PropTypes.string,
  departmentToDelete: PropTypes.string,
  divisions: PropTypes.array.isRequired,
  division: PropTypes.object.isRequired
};

function mapStatesToProps(state, ownProps) {
  let division = {
    id: 0,
    description: "",
    code: "",
    departments: []
  };

  return {
    state: state.reducers,

    modal: state.reducers.modal,
    savingDivision: state.reducers.savingDivision,
    labels: state.reducers.labels.labels,
    divisionToDelete: state.reducers.divisionToDelete,
    departmentToDelete: state.reducers.departmentToDelete,
    divisions: state.reducers.divisions,
    division: division
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...divisionActions, ...modalActions, ...labelActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(DivisionPage);
