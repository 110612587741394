import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import CallService from "../../services/callService";

import * as callActions from "../../actions/callActions";
import * as modalActions from "../../actions/modalActions";
import * as divisionActions from "../../actions/divisionActions";
import * as userActions from "../../actions/userActions";
import * as accountActions from "../../actions/accountActions";
import * as codeActions from "../../actions/codeActions";
import * as contractActions from "../../actions/contractActions";

import { Row, Col } from "react-bootstrap";
import CallList from "./CallList";
import CallCalendar from "./calendar/CallCalendar";
import FilterComponent from "../common/FilterComponent";
import BreadCrumbs from "../common/BreadCrumbs";
import ConfirmModal from "../common/ConfirmModal";
import Loading from "../common/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faListUl,
  faFilter,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

import isEmpty from "lodash/isEmpty";
import moment from "moment";

class CallPage extends Component {
  constructor(props, context) {
    super(props, context);

    const isList = localStorage.getItem("list")
      ? JSON.parse(localStorage.getItem("list"))
      : props.isMobile
      ? true
      : false;

    this.state = {
      list: isList,
      open: true,
      calls: [],
      total: 0,
      filters: localStorage.getItem("filters")
        ? JSON.parse(localStorage.getItem("filters"))
        : {
            parent_account: [],
            contract: [],
            divisions: [],
            "assigned_to.job_title": [],
            assigned_to: [],
            stage: [],
            start_time: moment(new Date()).startOf("month"),
            end_time: moment(new Date()).endOf("month"),
            active: "A"
          }
    };
  }

  componentDidMount = () => {
    let { accounts, contracts, divisions, actions, codes } = this.props;

    actions.loadUsers(true);

    if (isEmpty(contracts)) actions.loadContracts();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(codes)) actions.loadCodes();
  };

  fetchCalls = async newState => {
    const state = { ...newState };
    if (!this.state.list) {
      const start_time = state.filtered.start_time;
      const end_time = state.filtered.end_time;

      if (
        moment(start_time).format("MM/DD/YY") !==
        moment(this.state.filters.start_time).format("MM/DD/YY")
      ) {
        this.setState({
          filters: { ...this.state.filters, start_time, end_time }
        });
      }
    }

    state.auth = this.props.auth;

    let { calls, pages } = await CallService.loadCalls({
      ...state,
      filtered: { ...state.filtered, active: this.state.filters.active === "A" }
    });

    this.setState({
      calls,
      pages
    });
  };

  onClickDelete = callId => {
    this.props.actions.requestCallId(callId);
  };

  onClickToggle = () => {
    this.setState(
      { list: !this.state.list },
      localStorage.setItem("list", !this.state.list)
    );
  };

  handleSelect = call => {
    this.props.history.push(`/app/calls/${call._id}`);
  };

  handleMove = ({ event, start, end }) => {
    let data = {
      ...event,
      start_time: moment.utc(start).subtract("30", "minute"),
      end_time: moment.utc(end).subtract("30", "minute")
    };

    this.props.actions.updateCall(data);
  };

  handleDelete = () => {
    this.props.actions.deleteCall(this.props.callToDelete);
  };

  handleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleChangeFilters = (selectedOptions, field) => {
    this.setState(
      {
        filters: { ...this.state.filters, [field]: selectedOptions }
      },
      () => {
        localStorage.setItem("filters", JSON.stringify(this.state.filters));
      }
    );
  };

  handleChangeDate = (startDate, endDate) => {
    let { filters } = this.state;
    if (startDate) {
      filters.start_time = moment(startDate).startOf("day");
      this.setState({ filters: { ...filters } }, () => {
        localStorage.setItem("filters", JSON.stringify(this.state.filters));
      });
    }
    if (endDate) {
      filters.end_time = moment(endDate).endOf("day");
      this.setState({ filters: { ...filters } }, () => {
        localStorage.setItem("filters", JSON.stringify(this.state.filters));
      });
    }
  };

  resetFilters = () => {
    this.setState({
      filters: {
        parent_account: [],
        assigned_to: [],
        stage: [],
        contract: [],
        division: [],
        "assigned_to.job_title": [],
        start_time: this.state.list
          ? moment(new Date()).startOf("month")
          : this.state.start_time,
        end_time: this.state.list
          ? moment(new Date()).endOf("month")
          : this.state.end_time,
        active: "A"
      }
    });
    localStorage.removeItem("filters");
  };

  toggleActiveUsers = (field, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          assigned_to: [],
          active: value
        }
      },
      () => {
        localStorage.setItem("filters", JSON.stringify(this.state.filters));
      }
    );
  };

  render() {
    let { contracts, codes, isMobile, auth, users, accounts } = this.props;
    let { calls, pages, total, list, open, filters } = this.state;

    const viewProps = {
      calls,
      users,
      codes,
      contracts,
      onFetchData: this.fetchCalls,
      filtered: filters,
      accounts
    };

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Time Entries" }]} />
          <Col md={5} xs={5} className="text-right">
            <button
              className="btn btn-success filter"
              type="button"
              onClick={this.handleOpen}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            {!isMobile && (
              <button
                className="btn btn-success call"
                type="button"
                onClick={this.onClickToggle}
              >
                <FontAwesomeIcon
                  icon={this.state.list ? faCalendarAlt : faListUl}
                  className="icon"
                />
              </button>
            )}
            <Link className="btn btn-success btn-add" to="/app/calls/add">
              <FontAwesomeIcon icon={faPlus} />
              ADD
            </Link>
          </Col>
        </Row>
        <FilterComponent
          {...this.props}
          users={users}
          entity="Call"
          open={open}
          filters={filters}
          onChangeFilters={this.handleChangeFilters}
          onChangeDate={this.handleChangeDate}
          focusedInput={this.state.focusedInput}
          resetFilters={this.resetFilters}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          toggleActive={this.toggleActiveUsers}
        />
        <Row>
          <Col md={12}>
            {list ? (
              <CallList
                {...viewProps}
                pages={pages}
                total={total}
                auth={auth}
                onClickView={id => this.props.history.push(`calls/${id}`)}
                onClickDelete={this.onClickDelete}
              />
            ) : (
              <CallCalendar
                {...viewProps}
                onSelectEvent={this.handleSelect}
                onMoveEvent={this.handleMove}
              />
            )}
          </Col>
        </Row>
        <ConfirmModal
          id="callDeleteModal"
          title="Delete Call"
          body="Are you sure you want to delete this call?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

CallPage.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  callToDelete: PropTypes.string,
  divisions: PropTypes.array,
  accounts: PropTypes.array,
  users: PropTypes.array,
  codes: PropTypes.array,
  contracts: PropTypes.array
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    modal: state.reducers.modal,
    callToDelete: state.reducers.callToDelete,
    loading: state.reducers.loadingCall,
    accounts: state.reducers.accounts,
    contracts: state.reducers.contracts,
    users: state.reducers.users,
    codes: state.reducers.codes,
    divisions: state.reducers.divisions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...callActions,
        ...accountActions,
        ...contractActions,
        ...userActions,
        ...divisionActions,
        ...codeActions
      },
      dispatch
    )
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CallPage);
