import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contractActions from "../../../actions/contractActions";
import * as callActions from "../../../actions/callActions";
import * as modalActions from "../../../actions/modalActions";

import AddButton from "../../common/AddButton";
import BreadCrumbs from "../../common/BreadCrumbs";
import Modal from "../../common/Modal";
import ConfirmModal from "../../common/ConfirmModal";
import ContractClientForm from "./ContractClientForm";
import ContractClientList from "./ContractClientList";
import { Row, Col } from "react-bootstrap";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

class ContractClientPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      client: {},
      adding: false,
      editing: true
    };
  }

  componentDidMount = () => {
    let { contract, actions } = this.props;
    if (isEmpty(contract)) actions.getContract(this.props.match.params.id);
  };

  onClickAdd = () => {
    this.setState({ client: {}, adding: true });
    this.props.actions.getContract(this.props.contract._id, true);
  };

  onClickEdit = clientId => {
    let client = find(this.props.contract.clients, {
      id: clientId
    });
    this.setState({ client, adding: false, editing: true });
    this.props.actions.getContract(this.props.contract._id, true);
  };

  onClickDelete = clientId => {
    this.props.actions.requestClientId(clientId);
  };

  handleAdd = clients => {
    clients.map(async client => {
      await this.props.actions.addClient(this.props.contract, client);
      await this.props.actions.getContract(this.props.match.params.id);
    });
    this.props.actions.hideModal();
  };

  handleSave = async client => {
    await this.props.actions.updateClient(this.props.contract, client);
    await this.props.actions.getContract(this.props.match.params.id);
    await this.props.actions.hideModal();
  };

  handleDelete = async () => {
    await this.props.actions.deleteClient(
      this.props.contract,
      this.props.clientToDelete
    );
    await this.props.actions.getContract(this.props.match.params.id);
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  render() {
    let { contract, codes, auth } = this.props;

    let clientForm = (
      <ContractClientForm
        client={this.state.client}
        adding={this.state.adding}
        editing={this.state.editing && auth.maxRole >= 6000}
        onAdd={this.handleAdd}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: `${contract.name}` }, { label: "Clients" }]}
          />
          <AddButton onClickAdd={this.onClickAdd} auth={auth} />
        </Row>
        <Row>
          <Col md={12}>
            <ContractClientList
              contract={contract}
              codes={codes}
              onClickEdit={this.onClickEdit}
              onClickDelete={this.onClickDelete}
            />
          </Col>
        </Row>
        <Modal
          id="contractDetailsModal"
          title={this.state.adding ? "Add Clients" : "Edit Client"}
          body={clientForm}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="contractDeleteModal"
          title="Delete Client"
          body="Are you sure you want to delete this client?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

ContractClientPage.propTypes = {
  actions: PropTypes.object.isRequired,
  contract: PropTypes.object,
  clientToDelete: PropTypes.string,
  calls: PropTypes.array,
  codes: PropTypes.array,
  client: PropTypes.object,
  modal: PropTypes.object,
  params: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contract: state.reducers.contract,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
    clientToDelete: state.reducers.clientToDelete
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contractActions,
        ...callActions,
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContractClientPage);
