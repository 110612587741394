import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import find from "lodash/find";

export const calls = (state = initialState.calls, action) => {
  switch (action.type) {
    case types.LOAD_CALL_SUCCESS:
      return action.calls;

    case types.CREATE_CALL_SUCCESS:
      return [...state, Object.assign({}, action.call)];

    case types.UPDATE_CALL_SUCCESS:
      return [
        ...state.filter(calls => calls._id !== action.call._id),
        Object.assign({}, action.call)
      ];

    case types.UPDATE_CALLS_SUCCESS:
      return [
        ...state.filter(calls => !find(action.calls, { _id: calls._id })),
        ...action.calls
          .filter(call => call.stage)
          .map(call => Object.assign({}, call))
      ];

    case types.DELETE_CALL_SUCCESS:
      return [...state.filter(call => call._id !== action.callId)];

    default:
      return state;
  }
};

export const callsByStage = (state = initialState.callsByStage, action) => {
  switch (action.type) {
    case types.LOAD_CALL_BY_STAGE_SUCCESS:
      return action.calls;
    default:
      return state;
  }
};

export const call = (state = initialState.call, action) => {
  switch (action.type) {
    case types.GET_CALL_SUCCESS:
      return action.call;

    default:
      return state;
  }
};

export const savingCall = (state = initialState.savingCall, action) => {
  switch (action.type) {
    case types.SAVING_CALL:
      return action.savingCall;

    default:
      return state;
  }
};

export const loadingCall = (state = initialState.loadingCall, action) => {
  switch (action.type) {
    case types.LOADING_CALL:
      return action.loadingCall;

    default:
      return state;
  }
};

export const callToDelete = (state = initialState.callToDelete, action) => {
  switch (action.type) {
    case types.REQUEST_CALL_ID:
      return action.callToDelete;

    default:
      return state;
  }
};

export const callsToApprove = (state = initialState.callsToApprove, action) => {
  switch (action.type) {
    case types.TOGGLE_CALL: {
      // start off with the existing state
      let callsToApprove = [...state];
      const keyIndex = callsToApprove.indexOf(action.id);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        callsToApprove = [
          ...callsToApprove.slice(0, keyIndex),
          ...callsToApprove.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        callsToApprove.push(action.id);
      }

      return [...callsToApprove];
    }

    case types.TOGGLE_ALL_CALLS: {
      let callsToApprove = [...state];
      action.calls.map(call => {
        const keyIndex = callsToApprove.indexOf(call);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // it does exist so we will remove it using destructing
          callsToApprove = [
            ...callsToApprove.slice(0, keyIndex),
            ...callsToApprove.slice(keyIndex + 1)
          ];
        } else {
          // it does not exist so add it
          callsToApprove.push(call);
        }
      });
      return [...callsToApprove];
    }

    case types.DESELECT_ALL_CALLS: {
      return [];
    }

    default:
      return state;
  }
};

export const callsToInvoice = (state = initialState.callsToInvoice, action) => {
  switch (action.type) {
    case types.TOGGLE_INVOICE_CALL: {
      // start off with the existing state
      let callsToInvoice = [...state];
      const keyIndex = callsToInvoice.indexOf(action.id);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        callsToInvoice = [
          ...callsToInvoice.slice(0, keyIndex),
          ...callsToInvoice.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        callsToInvoice.push(action.id);
      }

      return [...callsToInvoice];
    }

    case types.TOGGLE_ALL_INVOICE_CALLS: {
      let callsToInvoice = [...state];
      action.calls.map(call => {
        const keyIndex = callsToInvoice.indexOf(call);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // it does exist so we will remove it using destructing
          callsToInvoice = [
            ...callsToInvoice.slice(0, keyIndex),
            ...callsToInvoice.slice(keyIndex + 1)
          ];
        } else {
          // it does not exist so add it
          callsToInvoice.push(call);
        }
      });
      return [...callsToInvoice];
    }

    case types.DESELECT_ALL_CALLS: {
      return [];
    }

    default:
      return state;
  }
};
