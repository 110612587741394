import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";
import * as itemActions from "../../actions/itemActions";

import ProcedureForm from "./ProcedureForm";

class ProcedureAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  handleSave(item) {
    this.props.actions.createItem(item);
  }

  render() {
    return (
      <div className="col-md-4">
        <h1>Add Item</h1>
        <ProcedureForm
          onSave={this.handleSave}
          saving={this.props.savingItem}
          item={this.props.item}
        />
      </div>
    );
  }
}

ProcedureAddPage.propTypes = {
  actions: PropTypes.object.isRequired,

  savingItem: PropTypes.bool,
  item: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  let item = {
    id: 0,
    name: ""
  };

  return {
    state: state.reducers,

    savingItem: state.reducers.savingItem,
    item: item
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...itemActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ProcedureAddPage);
