import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as invoiceActions from "../../actions/invoiceActions";

import ReactTable from "react-table";
import Loading from "../common/Loading";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFileExport } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import isEmpty from "lodash/isEmpty";

class InvoiceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      selectAll: false,
      selected: {}
    };
  }

  componentDidMount = () => {
    this.handleFilters(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.filters !== this.props.filters ||
      nextProps.invoices !== this.props.invoices
    ) {
      this.handleFilters(nextProps);
    }
  };

  handleFilters = props => {
    let filters = props.filters;
    let filteredInvoices = props.invoices;
    let rows = [];

    // NUMBER
    if (filters.number !== this.props.filters.number) {
      filteredInvoices.map(invoices => {
        if (
          !filters.number.find(number => number.value === invoices._id) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.number && filters.number.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.number.find(number => number.value === invoice._id)
          ? invoice
          : false
      );
    }

    // ACCOUNT
    if (filters.parent_account !== this.props.filters.parent_account) {
      filteredInvoices.map(invoices => {
        if (
          !filters.parent_account.find(
            account => account.label === invoices.parent_account.name
          ) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.parent_account && filters.parent_account.length) {
      filteredInvoices = filteredInvoices.filter(invoices =>
        filters.parent_account.find(
          account => account.label === invoices.parent_account.name
        )
      );
    }

    // CONTRACT
    if (filters.contract !== this.props.filters.contract) {
      filteredInvoices.map(invoices => {
        if (
          !filters.contract.find(
            contract => contract.label === invoices.contract.name
          ) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.contract && filters.contract.length) {
      filteredInvoices = filteredInvoices.filter(invoices =>
        filters.contract.find(
          contract => contract.label === invoices.contract.name
        )
      );
    }

    // STAGE
    if (filters.stage !== this.props.filters.stage) {
      filteredInvoices.map(invoices => {
        if (
          !filters.stage.find(stage => stage.label === invoices.stage.label) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.stage && filters.stage.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.stage.find(stage => stage.value === invoice.stage._id)
          ? invoice
          : false
      );
    }

    // DIVISION
    if (filters.division !== this.props.filters.division) {
      filteredInvoices.map(invoices => {
        if (
          !filters.division.find(
            division => division.value === invoices.division._id
          ) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.division && filters.division.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.division.find(div => div.value === invoice.division._id)
          ? invoice
          : false
      );
    }

    // FORMAT
    if (filters.format !== this.props.filters.format) {
      filteredInvoices.map(invoices => {
        if (
          !filters.format.find(
            format => format.label === invoices.format.label
          ) &&
          this.state.selected[invoices.number]
        ) {
          rows.push(invoices.number);
        }
      });
      this.toggleRows(rows);
    }
    if (filters.format && filters.format.length) {
      filteredInvoices = filteredInvoices.filter(invoice =>
        filters.format.find(format => format.value === invoice.format._id)
          ? invoice
          : false
      );
    }

    // DATE
    if (filters.start_time && filters.end_time) {
      filteredInvoices.map(invoices => {
        if (
          moment.utc(filters.start_time).startOf("day") <=
            moment.utc(invoices.document_date).startOf("day") &&
          moment.utc(filters.end_time).startOf("day") >=
            moment.utc(invoices.document_date).startOf("day")
        ) {
          return invoices;
        } else {
          if (this.state.selected[invoices.number]) {
            rows.push(invoices.number);
          }
        }
      });
      this.toggleRows(rows);
    } else if (filters.start_time) {
      filteredInvoices.map(invoices => {
        if (
          moment
            .utc(filters.start_time)
            .startOf("day")
            .format("MM/DD/YYYY") ==
          moment
            .utc(invoices.document_date)
            .startOf("day")
            .format("MM/DD/YYYY")
        ) {
          return invoices;
        } else {
          if (this.state.selected[invoices.number]) {
            rows.push(invoices.number);
          }
        }
      });
      this.toggleRows(rows);
    }

    if (filters.start_time && filters.end_time) {
      filteredInvoices = filteredInvoices.filter(invoice => {
        if (
          moment.utc(filters.start_time).startOf("day") <=
            moment.utc(invoice.document_date).startOf("day") &&
          moment.utc(filters.end_time).startOf("day") >=
            moment.utc(invoice.document_date).startOf("day")
        ) {
          return invoice;
        }
      });
    } else if (filters.start_time) {
      filteredInvoices = filteredInvoices.filter(invoice => {
        if (
          moment
            .utc(filters.start_time)
            .startOf("day")
            .format("MM/DD/YYYY") ==
          moment
            .utc(invoice.document_date)
            .startOf("day")
            .format("MM/DD/YYYY")
        ) {
          return invoice;
        }
      });
    }

    if (
      isEmpty(filters.number) &&
      isEmpty(filters.account) &&
      isEmpty(filters.contract) &&
      isEmpty(filters.division) &&
      isEmpty(filters.staged) &&
      !filters.start_time &&
      !filters.end_time
    ) {
      this.props.onClearInvoices();
    }

    this.setState({ invoices: filteredInvoices });
  };

  toggleRow = (id, number) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[number] = !this.state.selected[number];
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
    this.props.onToggle(number);
  };

  toggleRows = rows => {
    const newSelected = Object.assign({}, this.state.selected);
    this.props.onToggleAll(rows);
    rows.map(id => (newSelected[id] = false));

    this.setState({ selected: newSelected, selectAll: 2 });
  };

  toggleSelectAll = () => {
    let newSelected = {};
    if (this.state.selectAll == 0) {
      this.state.invoices.map(invoice => {
        newSelected[invoice.number] = true;
        this.props.onToggle(invoice.number);
      });
    } else if (this.state.selectAll == 1) {
      this.state.invoices.map(invoice => {
        newSelected[invoice.number] = false;
        this.props.onToggle(invoice.number);
      });
    } else if (this.state.selectAll == 2) {
      Object.entries(this.state.selected).map(invoice => {
        // see if invoice is toggled true (invoice[i])
        if (invoice[1]) {
          this.props.onToggle(parseFloat(invoice[0]));
        }
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll == 0 ? 1 : 0
    });
  };

  render() {
    let { loadingInvoice, onClickVoid, onClickExport } = this.props;
    let { invoices, selectAll } = this.state;

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onClick={() => this.toggleSelectAll()}
            checked={selectAll == 1}
          />
        ),
        Cell: row => (
          <input
            type="checkbox"
            onClick={() => this.toggleRow(row.value, row.original.number)}
            checked={this.state.selected[row.original.number] === true}
          />
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center"
        }
      },
      {
        Header: "Number",
        accessor: "number",
        id: "invoice_number",
        maxWidth: 120
      },
      {
        Header: "Account",
        accessor: "parent_account.name"
      },
      {
        Header: "Contract",
        accessor: "contract.name"
      },
      {
        Header: "Date",
        accessor: "document_date",
        Cell: row => <span>{moment.utc(row.value).format("MM/DD/YYYY")}</span>
      },
      {
        Header: "Format",
        accessor: "format.label"
      },
      {
        Header: "Stage",
        accessor: "stage.label"
      },
      {
        Header: "",
        accessor: "_id",
        sortable: false,
        filterable: false,
        Cell: row => {
          let invoice =
            process.env.NODE_ENV == "development" ? "_id" : "number";
          return (
            <div className="text-center actions">
              <DropdownButton
                title="Actions"
                id="dropdown"
                disabled={
                  row.original.stage &&
                  row.original.stage.label === ("Created" && "Void")
                }
              >
                <MenuItem onClick={() => onClickExport(row.original[invoice])}>
                  <FontAwesomeIcon
                    icon={faFileExport}
                    className="brand-color"
                  />
                  Export
                </MenuItem>
                <MenuItem onClick={() => onClickVoid(row.value)}>
                  <FontAwesomeIcon icon={faBan} className="brand-color" />
                  Void
                </MenuItem>
              </DropdownButton>
            </div>
          );
        }
      }
    ];

    const checkboxProps = {
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const selected = this.state.selected[row.original.number] === true;
          return {
            style: {
              color: selected ? "#cf4a03" : "inherit",
              fontWeight: selected ? 500 : 400
            }
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (state, rowInfo, column) => {
        return {
          onClick: () => {
            if (rowInfo) {
              if (column.id !== "_id") {
                this.toggleRow(rowInfo.original._id, rowInfo.original.number);
              }
            }
          }
        };
      }
    };

    return (
      <ReactTable
        // manual
        className="-striped -highlight"
        data={invoices}
        LoadingComponent={() => <Loading active={loadingInvoice} />}
        columns={columns}
        defaultPageSize={10}
        {...checkboxProps}
      />
    );
  }
}

InvoiceList.propTypes = {
  invoices: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  filter: PropTypes.object,
  auth: PropTypes.object,
  onChange: PropTypes.func
};

function mapStatesToProps(state) {
  return {
    invoicesToPrint: state.reducers.invoicesToPrint,
    loadingInvoice: state.reducers.loadingInvoice
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...invoiceActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(InvoiceList);
