import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { googleAnalytics } from "../reactGAMiddlewares";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import throttle from "redux-throttle";

import reducers from "../reducers";

export default function configureStore(initialState, history) {
    const middlewareWithHistory = routerMiddleware(history);
    const logger = createLogger({
        predicate: (getState, action) =>
            action.type !== "@@router/LOCATION_CHANGE" &&
            action.type !== "@@router/CALL_HISTORY_METHOD" &&
            action.type !== "HIDE_ALERT_SUCCESS"
    });

    const defaultWait = 3000;
    const defaultThrottleOption = {
        leading: true,
        trailing: false
    };
    const throttleMiddleware = throttle(defaultWait, defaultThrottleOption);

    const reducer = combineReducers({ reducers, routing: routerReducer });

    const middleware = [
        throttleMiddleware,
        thunk,
        logger,
        middlewareWithHistory,
        googleAnalytics
    ];

    const store = createStore(
        reducer,
        initialState,
        compose(applyMiddleware(...middleware))
    );

    if (module.hot) {
        module.hot.accept("../reducers", () =>
            store.replaceReducer(require("../reducers").default)
        );
    }

    return store;
}